import { Box, Menu, MenuItem, Skeleton, Stack, StackProps, styled, useTheme } from '@mui/material';
import { Typography } from '@mui/material';
import { alpha } from '@mui/system';
import { CaretDown } from '@phosphor-icons/react';
import React, { Ref, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useCanHover } from '~/hooks/useCanHover';

import { ignoreProps } from '../../utils/styled';

import { FILTER_ITEM_COLLAPSED_HEIGHT_PX, FILTER_ITEM_EXPANDED_HEIGHT_PX, HTMLDivElementRef } from './shared';

export type FilterItemValue = string | number | null;
export type FilterItemOption<T extends FilterItemValue> = { value: T; label: string; shortLabel?: string };

type CollapsibleProps = {
    isExpanded: boolean;
};

type DisableableProps = {
    disabled: boolean;
};

const Container = styled(
    Stack,
    ignoreProps<CollapsibleProps>('isExpanded')
)<CollapsibleProps>(({ isExpanded }) => ({
    position: 'absolute',
    userSelect: 'none',
    transition: 'height 0.5s ease, transform 0.5s ease',
    ...(isExpanded
        ? {
              width: '100%',
              height: `${FILTER_ITEM_EXPANDED_HEIGHT_PX}px`,
          }
        : {
              width: 'auto',
              height: `${FILTER_ITEM_COLLAPSED_HEIGHT_PX}px`,
          }),
}));

const SelectorLabel = styled(Typography)({
    color: 'inherit',
    fontWeight: 'bold',
    transition: 'color 0.1s ease',
});

const SelectorCaret = ({ isExpanded }: CollapsibleProps) => (
    <CaretDown
        size="24px"
        style={{
            color: 'inherit',
            ...(isExpanded
                ? {
                      width: '24px',
                      height: '24px',
                      transition: 'color 0.1s ease, width 0.5s ease, height 0.5s ease',
                  }
                : {
                      width: 0,
                      height: 0,
                      transition: 'color 0.1s ease, height 0.5s ease', // Width can't be animated or we'll calculate the wrong position
                  }),
        }}
    />
);

const Selector = ({ isExpanded, disabled, sx, ...props }: CollapsibleProps & DisableableProps & StackProps) => {
    const { palette } = useTheme();
    const canHover = useCanHover();

    return (
        <Stack
            {...props}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
                color: !disabled ? palette.grey[900] : alpha(palette.grey[900], 0.5),
                transition: 'padding 0.5s ease',
                ...(isExpanded
                    ? {
                          p: '4px 0',
                          ...(!disabled && {
                              cursor: 'pointer',
                              '&:hover': canHover && { color: alpha(palette.grey[900], 0.7) },
                              '&:active': { color: alpha(palette.grey[900], 0.5) },
                          }),
                      }
                    : {
                          p: 0,
                      }),
                ...sx,
            }}
        />
    );
};

const SelectorUnderline = styled(
    Box,
    ignoreProps<CollapsibleProps>('isExpanded')
)<CollapsibleProps>(({ theme: { palette }, isExpanded }) => ({
    backgroundColor: alpha(palette.grey[900], 0.5),
    flexShrink: 0,
    width: '100%',
    height: '1px',
    transformOrigin: 'left',
    ...(isExpanded
        ? {
              margin: '6px 0',
              transform: 'scale(1)',
              transition: 'margin 0.5s ease, transform 0.5s ease',
          }
        : {
              margin: 0,
              transform: 'scale(0)',
              transition: 'margin 0.5s ease', // Scale doesn't look good when collapsing
          }),
}));

const SelectorCaption = styled(Typography)(({ theme: { palette } }) => ({
    color: alpha(palette.grey[900], 0.7),
}));

const FilterMenu = <T extends string | number | null>({
    isOpen,
    onClose,
    anchorEl,
    onChange,
    options,
}: {
    isOpen: boolean;
    onClose: () => void;
    anchorEl: HTMLDivElementRef;
    onChange: (value: T) => void;
    options: { value: T; label: string }[];
}) => {
    const handleClick = useCallback(
        (value: T) => {
            onChange(value);
            onClose();
        },
        [onChange]
    );

    return (
        <Menu
            open={isOpen}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            slotProps={{
                paper: {
                    sx: {
                        width: anchorEl?.clientWidth,
                        maxHeight: '240px',
                    },
                },
            }}
        >
            {options.map(({ value, label }) => (
                <MenuItem key={value} onClick={() => handleClick(value)}>
                    {label}
                </MenuItem>
            ))}
        </Menu>
    );
};

type FilterItemProps<T extends FilterItemValue> = {
    value: T;
    onChange: (value: T) => void;
    options: FilterItemOption<T>[];
    caption: string;
    isLoading?: boolean;
} & Partial<CollapsibleProps & DisableableProps>;

const FilterItem = forwardRef(function FilterItem(
    {
        value,
        onChange,
        options,
        caption,
        isLoading = true,
        isExpanded = false,
        disabled = false,
    }: FilterItemProps<FilterItemValue>,
    ref: Ref<HTMLDivElement>
) {
    const labelByValue = useMemo(() => new Map(options.map(({ value, label }) => [value, label])), [options]);
    const shortLabelByValue = useMemo(
        () => new Map(options.map(({ value, label, shortLabel }) => [value, shortLabel ?? label])),
        [options]
    );

    const menuAnchor = useRef<HTMLDivElementRef>(null);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!isExpanded || disabled) setIsOpen(false);
    }, [isExpanded, disabled]);

    return (
        <Container ref={ref} isExpanded={isExpanded}>
            {isLoading ? (
                <Skeleton width="100%" height={`${isExpanded ? 32 : 16.8}px`} />
            ) : (
                <Selector
                    onClick={() => isExpanded && !disabled && setIsOpen(true)}
                    isExpanded={isExpanded}
                    disabled={disabled}
                >
                    <SelectorLabel variant="body2">
                        {isExpanded ? labelByValue.get(value) : shortLabelByValue.get(value)}
                    </SelectorLabel>
                    <SelectorCaret isExpanded={isExpanded} />
                </Selector>
            )}
            <SelectorUnderline ref={menuAnchor} isExpanded={isExpanded} />
            <SelectorCaption variant="caption">{caption}</SelectorCaption>
            <FilterMenu
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                anchorEl={menuAnchor.current}
                onChange={onChange}
                options={options}
            />
        </Container>
    );
});

export default FilterItem;
