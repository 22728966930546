import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import { ResidentTasksResponse } from '~/types/flowSheet';
import { BaseResponse } from '~/types/shared';

type FlowSheetDataParams = {
    branchId: number;
    startDate: string;
    endDate: string;
    residentId?: number;
};

export const useFlowSheetDataQuery = (params: FlowSheetDataParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            params: {
                branch_id: params.branchId,
                start_date: params.startDate,
                end_date: params.endDate,
                resident_id: params.residentId,
            },
        };

        const { data } = await api.get<BaseResponse<ResidentTasksResponse>>(`/tasks/flow-sheet-data`, options);

        return data.response;
    }, [params, token]);

    return useErrorHandledQuery({
        queryKey: ['export-flow-sheet', params],
        queryFn,
        staleTime: 300000,
        enabled: !!params.branchId && !!params.startDate && !!params.endDate,
    });
};
