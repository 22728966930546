import { useCallback } from 'react';

import { api } from '~/api';
import { PermissionListResponse } from '~/types/permissions';

import { useErrorHandledQuery, useToken } from '../common';

export const usePermissionsQuery = () => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = { headers: { Authorization: token } };
        const { data } = await api.get<PermissionListResponse>(`/permissions`, options);

        return data.response;
    }, [token]);

    return useErrorHandledQuery({
        queryKey: ['permissions'],
        queryFn,
        enabled: !!token, // Prevent auto-fetching if logged out
        staleTime: 1000 * 60 * 60, // 1 hour
        retry: false,
    });
};
