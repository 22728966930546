import { Box, Button, Stack, alpha, styled } from '@mui/material';
import { ArrowLineDown, CaretLeft, MagnifyingGlass } from '@phosphor-icons/react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { ComponentProps, useCallback } from 'react';

import { DetailsPayload } from '@allie/operations-common/src/types/module';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useCanHover } from '~/hooks/useCanHover';
import { detailsPayloadAtom, isDetailsOpenAtom } from '~/pages/OperationsV2/atoms';
import {
    AnyDetailsDefinition,
    AnyDetailsGroupingDefinition,
    AnyDetailsGroupingDefinitionTextOverride,
    definitionsByLevel,
} from '~/pages/OperationsV2/modules';
import { getDetailsData } from '~/pages/OperationsV2/testData';

const DetailsContainer = styled(Stack)({
    height: '100%',
});

const DetailsHeaderContainer = styled(Stack)({
    gap: '16px',
    padding: '24px',
});

const ActionButtonsRow = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);
    const toggleDetails = useSetAtom(isDetailsOpenAtom);

    if (!detailsPayload) return null;

    const { level, moduleType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType];

    return (
        <Stack
            sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Button color="secondary" onClick={toggleDetails} startIcon={<CaretLeft />}>
                {moduleDefinition.label}
            </Button>
            <Button color="secondary" startIcon={<ArrowLineDown />}>
                Download
            </Button>
        </Stack>
    );
};

const SectionCaption = styled(SingleLineTypography)(({ theme: { palette } }) => ({
    color: alpha(palette.grey[900], 0.7),
}));

const LabelRow = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);
    const data = getDetailsData(detailsPayload as never); // TODO: Replace with API call

    const overrideText = useCallback(
        (override: AnyDetailsGroupingDefinitionTextOverride | undefined, fallback: string | undefined) => {
            // Try to override, fall back to default if no overrides are set
            // or if data is needed and hasn't been fetched yet
            switch (typeof override) {
                case 'function':
                    return data ? override(data) : fallback;
                case 'string':
                    return override;
                default:
                    return fallback;
            }
        },
        [data]
    );

    if (!detailsPayload) return null;

    const { level, moduleType, detailsType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType];

    const { label, caption, groupings } = moduleDefinition.details![detailsType] as AnyDetailsDefinition;
    const { overrideDetailsLabel, overrideDetailsCaption } = groupings[
        detailsPayload.detailsGroupingType
    ] as AnyDetailsGroupingDefinition;

    const overridenLabel = overrideText(overrideDetailsLabel, label);
    const overridenCaption = overrideText(overrideDetailsCaption, caption);

    return (
        <Stack>
            <SingleLineTypography variant="subtitle2" fontWeight={700}>
                {overridenLabel}
            </SingleLineTypography>
            {overridenCaption && <SectionCaption variant="caption">{overridenCaption}</SectionCaption>}
        </Stack>
    );
};

const GroupingButtonsContainer = styled(Stack)({
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    overflowX: 'auto',
});

const GroupingButton = ({
    groupingDefinition,
    sx,
    ...props
}: { groupingDefinition: AnyDetailsGroupingDefinition } & ComponentProps<typeof Button>) => {
    const [detailsPayload, setDetailsPayload] = useAtom(detailsPayloadAtom);

    if (!detailsPayload) return null;

    const { detailsGroupingType } = detailsPayload;
    const isSelected = groupingDefinition.type === detailsGroupingType;

    const handleClick = () => {
        setDetailsPayload(
            (prev) =>
                ({
                    ...prev,
                    detailsGroupingType: groupingDefinition.type,
                }) as DetailsPayload
        );
    };

    return (
        <Button
            {...props}
            variant={isSelected ? 'contained' : 'outlined'}
            color="grey"
            onClick={handleClick}
            sx={{
                flex: '1 0 auto',
                minWidth: 0,
                whiteSpace: 'nowrap',
                ...sx,
            }}
        >
            {groupingDefinition.label}
        </Button>
    );
};

const GroupingButtonsRow = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);

    if (!detailsPayload) return null;

    const { level, moduleType, detailsType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType];
    const detailsDefinition = moduleDefinition.details![detailsType] as AnyDetailsDefinition;
    const groupingDefinitions = Object.values(detailsDefinition.groupings) as AnyDetailsGroupingDefinition[];

    if (groupingDefinitions.length < 2) return null;

    return (
        <GroupingButtonsContainer>
            {groupingDefinitions.map((groupingDefinition) => (
                <GroupingButton key={groupingDefinition.type} groupingDefinition={groupingDefinition} />
            ))}
        </GroupingButtonsContainer>
    );
};

const SearchRow = () => {
    const canHover = useCanHover();

    const detailsPayload = useAtomValue(detailsPayloadAtom);

    if (!detailsPayload) return null;

    const { level, moduleType, detailsType, detailsGroupingType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType];
    const detailsDefinition = moduleDefinition.details![detailsType] as AnyDetailsDefinition;
    const groupingDefinition = detailsDefinition.groupings[detailsGroupingType] as AnyDetailsGroupingDefinition;

    if (!groupingDefinition.allowSearch) return null;

    return (
        // Mock implementation, demo only
        <Stack
            sx={({ palette }) => ({
                color: palette.grey[900],
                bgcolor: palette.grey[100],
                flexDirection: 'row',
                alignItems: 'center',
                gap: '8px',
                px: '12px',
                height: '48px',
                borderRadius: '24px',
                userSelect: 'none',
                transition: 'background-color 0.1s',
                '&:hover': canHover && { bgcolor: alpha(palette.grey[900], 0.15) },
                '&:active': { bgcolor: alpha(palette.grey[900], 0.2) },
            })}
        >
            <MagnifyingGlass size="24px" />
            <SingleLineTypography
                variant="body2"
                sx={({ palette }) => ({
                    color: palette.grey[500],
                    fontWeight: 400,
                    cursor: 'text',
                })}
            >
                {groupingDefinition.searchPlaceholder ?? 'Search'}
            </SingleLineTypography>
        </Stack>
    );
};

const DetailsBodyContainer = styled(Box)({
    flex: 1,
    overflow: 'hidden',
});

const DetailsBody = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);
    const data = getDetailsData(detailsPayload as never); // TODO: Replace with API call

    if (!detailsPayload || !data) return null;

    const { level, moduleType, detailsType, detailsGroupingType } = detailsPayload;

    const levelDefinition = definitionsByLevel[level];
    const moduleDefinition = levelDefinition.modules[moduleType];
    const detailsDefinition = moduleDefinition.details![detailsType] as AnyDetailsDefinition;
    const groupingDefinition = detailsDefinition.groupings[detailsGroupingType] as AnyDetailsGroupingDefinition;

    return groupingDefinition.renderData(data /* TODO: Improve type */);
};

const Details = () => {
    const detailsPayload = useAtomValue(detailsPayloadAtom);

    if (!detailsPayload) return null;

    return (
        <DetailsContainer>
            <DetailsHeaderContainer>
                <ActionButtonsRow />
                <LabelRow />
                <Stack spacing="12px">
                    <GroupingButtonsRow />
                    <SearchRow />
                </Stack>
            </DetailsHeaderContainer>
            <DetailsBodyContainer>
                <DetailsBody />
            </DetailsBodyContainer>
        </DetailsContainer>
    );
};

export default Details;
