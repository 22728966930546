import { atom } from 'jotai';

import { atomWithToggle } from '~/state/atomUtils';

import { StaffOptimizations } from './types';

export const budgetRecommendationOnHoverAtom = atom<number | null>(null);
export const isChangeBudgetDialogOpenAtom = atomWithToggle(false);
export const isSaveOptimizationModalOpenAtom = atomWithToggle(false);
export const slotToHaveBudgetChangedAtom = atom<StaffOptimizations.BudgetSlotToChange | null>(null);

export const budgetsToChangeAtom = atom<StaffOptimizations.BudgetToUpdate[]>([]);
export const scheduledBudgetAtom = atom(0);
