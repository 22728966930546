import {
    ArrowsLeftRight,
    CirclesFour,
    ForkKnife,
    HeadCircuit,
    Icon,
    Pill,
    Question,
    Scissors,
    ShieldPlus,
    Shower,
    SmileySad,
    TShirt,
    Toilet,
    WarningCircle,
    Wheelchair,
    XCircle,
} from '@phosphor-icons/react';

import { PRN_TASK_CATEGORY_CODES } from '@allie/utils/src/constants/prn-tasks.constants';

import HandServices from '~/components/Svg/HandServices';

import { PrnTaskCategories } from '../types/prnTaskCategories';

export default function withIcon(category: PrnTaskCategories.Category): PrnTaskCategories.Category & { icon: Icon } {
    const iconMap: Record<string, Icon> = {
        [PRN_TASK_CATEGORY_CODES.EMERGENCY]: WarningCircle,
        [PRN_TASK_CATEGORY_CODES.SHOWERING]: Shower,
        [PRN_TASK_CATEGORY_CODES.GROOMING]: Scissors,
        [PRN_TASK_CATEGORY_CODES.TRANSFER]: ArrowsLeftRight,
        [PRN_TASK_CATEGORY_CODES.MOBILITY]: Wheelchair,
        [PRN_TASK_CATEGORY_CODES.EATING]: ForkKnife,
        [PRN_TASK_CATEGORY_CODES.DRESSING]: TShirt,
        [PRN_TASK_CATEGORY_CODES.TOILETING]: Toilet,
        [PRN_TASK_CATEGORY_CODES.SAFETY]: ShieldPlus,
        [PRN_TASK_CATEGORY_CODES.SERVICES]: HandServices,
        [PRN_TASK_CATEGORY_CODES.HEALTH]: Pill,
        [PRN_TASK_CATEGORY_CODES.UNWELL]: SmileySad,
        [PRN_TASK_CATEGORY_CODES.QUESTION]: Question,
        [PRN_TASK_CATEGORY_CODES.ACCIDENTALLY_PRESSED]: XCircle,
        [PRN_TASK_CATEGORY_CODES.BEHAVIOR]: HeadCircuit,
    };

    return {
        ...category,
        icon: iconMap[category.code ?? category.label] || CirclesFour,
    };
}
