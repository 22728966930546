import { Box, Typography, useTheme } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import React from 'react';
import { FaChevronDown, FaChevronUp, FaCircle } from 'react-icons/fa';

import { UndocumentedCalls } from '~/api/queries/call/getUndocumentedCalls';
import { ResidentCall } from '~/types/call/call';

import { openedResidentIdAtom } from '../../atom';
import { TaskCounter } from '../../components/TaskCounter';
import { ResidentPhoto } from '../../taskListComponents/resident/ResidentPhoto';
import { documentationResidentDataAtom, startDocumentationFlowAtom } from '../atom';

import { UndocumentedCallBox } from './UndocumentedCallBox';

interface ResidentUndocumentedCallTaskCardProps {
    calls: UndocumentedCalls;
    resident: ResidentCall;
}

export const ResidentUndocumentedCallTaskCard = ({ calls, resident }: ResidentUndocumentedCallTaskCardProps) => {
    const { palette } = useTheme();

    const [openedResidentId, setOpenedResidentId] = useAtom(openedResidentIdAtom);
    const startDocumentationFlow = useSetAtom(startDocumentationFlowAtom);
    const setResidentData = useSetAtom(documentationResidentDataAtom);

    const handleClick = () => {
        // TODO - send callId to documentation flow
        setResidentData({ ...resident, residentId: resident.id });
        startDocumentationFlow();
    };

    const toggleShowTasks = () => {
        setOpenedResidentId(openedResidentId !== resident.id ? resident.id : null);
    };

    const showTasks = openedResidentId === resident.id;

    return (
        <Box display="flex" width="100%" flexDirection="column" borderBottom={`1px solid ${palette.grey[100]}`}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="12px"
                padding="16px"
                boxShadow={showTasks ? '0px 4px 14.1px 0px rgba(0, 0, 0, 0.10)' : 'none'}
                onClick={toggleShowTasks}
            >
                <FaCircle size={24} color={palette.grey[200]} />
                <ResidentPhoto
                    residentId={resident.id}
                    firstName={resident.firstName}
                    lastName={resident.lastName}
                    photo={resident.photo ?? ''}
                />
                <Box display="flex" flex={1} flexDirection="column">
                    <Typography variant="body1" color={palette.grey[900]} textAlign="left" fontWeight={700}>
                        {resident.firstName} {resident.lastName}
                    </Typography>
                    <Typography color={palette.secondary.main} fontWeight={700}>
                        Apt {resident.roomNumber || 'not set'}
                    </Typography>
                </Box>
                <TaskCounter count={calls.length} />
                {showTasks ? (
                    <FaChevronUp size={20} color={palette.grey[300]} />
                ) : (
                    <FaChevronDown size={20} color={palette.grey[300]} />
                )}
            </Box>

            {showTasks &&
                calls.map((call) => (
                    <Box margin="16px" key={call.id}>
                        <UndocumentedCallBox triggeredAt={call.triggeredAt} onClick={handleClick} />
                    </Box>
                ))}
        </Box>
    );
};
