import { Box, styled } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useEffect, useMemo } from 'react';

import { UnscheduledTaskDialogSteps } from '~/pages/Home/components/NewUnscheduledTaskDialog/types/unscheduledTaskDialogSteps';

import { documentationResidentDataAtom } from '../../eCallComponents/atom';
import CustomDrawer from '../CustomDrawer';

import Footer from './components/Footer';
import Header from './components/Header';
import useUnscheduledTaskDialog from './hooks/useUnscheduledTaskDialog';
import AdditionalCommentBoxStep from './steps/additionalCommentBox/AdditionalCommentBoxStep';
import SelectCategoryStep from './steps/category/SelectCategoryStep';
import ConfettiStep from './steps/confetti';
import SelectResidentStep from './steps/resident/SelectResidentStep';
import SelectTaskDetailsStep from './steps/taskDetails/SelectTaskDetailsStep';

type NewUnscheduledTaskDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    isECall?: boolean;
};

const PageContent = styled(Box)(() => ({
    padding: '16px',
    flexGrow: 1,
    overflowY: 'auto',
    height: '100%',
}));

function NewUnscheduledTaskDialog({ isOpen, isECall, onClose }: Readonly<NewUnscheduledTaskDialogProps>) {
    const [residentData, setResidentData] = useAtom(documentationResidentDataAtom);

    const {
        goToNextStep,
        goBackOneStep,
        step,
        shiftId,
        setShiftId,
        isValid,
        resetFlow,
        progress,
        isSubmitPending,
        onSubmit,
    } = useUnscheduledTaskDialog();

    useEffect(() => {
        if (isOpen) {
            resetFlow(residentData ?? undefined);
        }
    }, [isOpen, residentData]);

    const handleClose = () => {
        setResidentData(null);
        onClose();
    };

    const Content = useMemo(() => {
        switch (step) {
            case UnscheduledTaskDialogSteps.SELECT_RESIDENT:
                return <SelectResidentStep />;
            case UnscheduledTaskDialogSteps.SELECT_CATEGORY:
                return <SelectCategoryStep isECall={isECall} />;
            case UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS:
                return <SelectTaskDetailsStep />;
            case UnscheduledTaskDialogSteps.ADDITIONAL_COMMENT_BOX:
                return <AdditionalCommentBoxStep />;
            case UnscheduledTaskDialogSteps.CONFETTI:
                return <ConfettiStep />;
            default:
                return <p>New Unscheduled Task Modal</p>;
        }
    }, [step]);

    return (
        // preventing closing modal once documentation flow started
        <CustomDrawer isOpen={isOpen} onClose={isECall ? () => null : handleClose}>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Header
                    onClose={handleClose}
                    step={step}
                    shiftId={shiftId}
                    setShiftId={setShiftId}
                    progress={progress}
                    isECall={isECall}
                />
                <PageContent sx={step === UnscheduledTaskDialogSteps.SELECT_RESIDENT ? { padding: 0 } : {}}>
                    {Content}
                </PageContent>
                <Footer
                    step={step}
                    onNextStep={goToNextStep}
                    isDisabled={!isValid()}
                    isLoading={isSubmitPending}
                    onAddPRN={onSubmit}
                    onClose={handleClose}
                    onBack={goBackOneStep}
                />
            </Box>
        </CustomDrawer>
    );
}

export default NewUnscheduledTaskDialog;
