import { Box, Typography, styled, useTheme } from '@mui/material';
import { Clipboard } from '@phosphor-icons/react';
import { useAtomValue } from 'jotai';
import React from 'react';

import { useBranchShifts } from '~/api/queries/branch';
import SquareButton from '~/components/Shared/Button/SquareButton';

import { selectedDateAtom } from '../atom';
import { isShiftInDate } from '../helpers';

import CustomDrawer from './CustomDrawer';
import { ShiftOptions } from './HeaderV2/components/types';

type ShiftSelectorDialogProps = {
    isOpen: boolean;
    selectedShiftId?: number;
    selectedBranchId?: number;
    shiftOptions: ShiftOptions;
    onShiftChange: (newShiftId: number) => void;
    onClose: () => void;
};

const Content = styled(Box)(() => ({
    padding: '16px 24px',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
}));

export const ShiftSelectorDialog = ({
    isOpen,
    onClose,
    shiftOptions,
    selectedShiftId,
    selectedBranchId,
    onShiftChange,
}: ShiftSelectorDialogProps) => {
    const { palette } = useTheme();
    const selectedDate = useAtomValue(selectedDateAtom);

    const shifts = useBranchShifts(selectedBranchId);

    const [, currentShift] = shiftOptions;

    let dropdownOptions: { label: string; value: number }[] = [];
    if (selectedDate?.toISO() === currentShift.shiftDayDate) {
        dropdownOptions = shiftOptions.map(({ shift: { id, name } }) => ({
            label: name,
            value: +id,
        }));
    } else {
        // using the id order of the shifts when the date is different from today
        dropdownOptions = shifts.map(({ id, name }) => ({ label: name, value: +id }));
    }

    const getLabel = (id: number) => {
        const [previous, current, next] = shiftOptions;

        if (previous.shift.id === id) {
            return 'Previous';
        }

        if (current.shift.id === id) {
            return 'Current';
        }

        if (next.shift.id === id) {
            return 'Next';
        }

        return '';
    };

    return (
        <CustomDrawer
            isOpen={isOpen}
            onClose={onClose}
            sx={{ height: 'min-content' }}
            closeButton
            header={
                <>
                    <Box padding="4px">
                        <Clipboard size={24} weight="bold" color={palette.secondary[500] as string} />
                    </Box>
                    <Typography variant="h6" fontSize="16px" color={palette.grey[900]} letterSpacing="unset">
                        Shift
                    </Typography>
                </>
            }
        >
            <Content>
                {dropdownOptions.map(({ label, value: id }) => (
                    <SquareButton
                        key={id}
                        label={isShiftInDate(currentShift.shift, selectedDate) ? getLabel(id) : ''}
                        value={label}
                        onClick={() => onShiftChange(id)}
                        selected={selectedShiftId === id}
                    />
                ))}
            </Content>
        </CustomDrawer>
    );
};
