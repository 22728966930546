import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { DetailsPayload } from '@allie/operations-common/src/types/module';
import { Level } from '@allie/operations-common/src/types/shared';

import { atomWithStorage, guardAtom } from '~/state/atomUtils';

import { Period } from './shared';

export const selectedDateAtom = atom<DateTime>(DateTime.local().minus({ days: 1 }).startOf('day'));
export const selectedPeriodAtom = atom<Period>('day');

export const selectedRegionIdAtom = atomWithStorage<number | null>('operations_selectedRegionId', null);
export const selectedBranchIdAtom = atomWithStorage<number | null>('operations_selectedBranchId', null);
export const selectedCareTypeIdAtom = atomWithStorage<number | null>('operations_selectedCareType', null);

export const selectedLevelAtom = atom<Level>((get) => {
    const selectedRegion = get(selectedRegionIdAtom);
    if (!selectedRegion) return 'COMPANY';

    const selectedBranch = get(selectedBranchIdAtom);
    if (!selectedBranch) return 'REGION';

    return 'BRANCH';
});

export const detailsPayloadAtom = atom<DetailsPayload | null>(null);
export const isDetailsOpenAtom = guardAtom(detailsPayloadAtom);
