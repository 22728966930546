import { Box, Typography, useTheme } from '@mui/material';
import { useSetAtom } from 'jotai';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp, FaCircle } from 'react-icons/fa';

import { UndocumentedCalls } from '~/api/queries/call/getUndocumentedCalls';
import { DeviceLocation, PUBLIC_LOCATION_TYPE } from '~/types/call/call';

import { TaskCounter } from '../../components/TaskCounter';
import { startDocumentationFlowAtom } from '../atom';

import { UndocumentedCallBox } from './UndocumentedCallBox';
import { getPublicLocationCallIcon } from './mapCallIcon';

interface PublicLocationCallCardProps {
    type: PUBLIC_LOCATION_TYPE;
    deviceLocation: DeviceLocation;
    calls: UndocumentedCalls;
}

export const PublicLocationCallCard = (props: PublicLocationCallCardProps) => {
    const { palette } = useTheme();
    const [showDetails, setShowDetails] = useState(false);
    const toggleDocumentationFlow = useSetAtom(startDocumentationFlowAtom);

    const handleClick = () => {
        toggleDocumentationFlow();
    };

    return (
        <Box display="flex" width="100%" flexDirection="column" borderBottom={`1px solid ${palette.grey[100]}`}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="12px"
                padding="16px"
                boxShadow={showDetails ? '0px 4px 14.1px 0px rgba(0, 0, 0, 0.10)' : 'none'}
                onClick={() => setShowDetails((prev) => !prev)}
            >
                <FaCircle size={24} color={palette.grey[200]} />
                <Box bgcolor={palette.grey[25]} borderRadius="32px" padding="16px">
                    {getPublicLocationCallIcon(props.type)}
                </Box>
                <Box display="flex" flex={1} flexDirection="column">
                    <Box flex={1} display="flex" gap="4px" alignItems="center">
                        <Typography fontSize="16px" color={palette.grey[900]} textAlign="left" fontWeight={700}>
                            {props.deviceLocation.name}
                        </Typography>
                    </Box>
                    <Typography color={palette.secondary.main} fontWeight={700}>
                        {props.deviceLocation.roomNumber ?? props.deviceLocation.zoneName ?? props.deviceLocation.name}
                    </Typography>
                </Box>
                <TaskCounter count={props.calls.length} />
                {showDetails ? (
                    <FaChevronUp size={20} color={palette.grey[300]} />
                ) : (
                    <FaChevronDown size={20} color={palette.grey[300]} />
                )}
            </Box>

            {showDetails &&
                props.calls.map((call, index) => (
                    <Box margin="16px" marginBottom={index === props.calls.length - 1 ? '16px' : 0} key={call.id}>
                        <UndocumentedCallBox triggeredAt={call.triggeredAt} onClick={handleClick} />
                    </Box>
                ))}
        </Box>
    );
};
