import { Box, useTheme } from '@mui/material';
import React from 'react';
import { FaCheckCircle, FaMinusCircle, FaRegCircle } from 'react-icons/fa';

import { pxToRem } from '~/components/theme/typography';

const SelectorIcon = ({
    isSelected,
    areAllTasksSelected,
    isResidentSelector,
}: {
    isSelected: boolean;
    areAllTasksSelected?: boolean;
    isResidentSelector?: boolean;
}) => {
    const { palette } = useTheme();

    if (!isSelected) {
        return <FaRegCircle size={24} color={palette.grey[200]} />;
    }

    const showCheckIcon = !isResidentSelector || (isResidentSelector && areAllTasksSelected);

    return showCheckIcon ? <FaCheckCircle color="#30878f" size={24} /> : <FaMinusCircle color="#30878f" size={24} />;
};

type Props = {
    isSelected: boolean;
    onSelect: () => void;
    areAllTasksSelected?: boolean;
    isResidentSelector?: boolean;
    id?: string;
};

export const Selector = (props: Props) => {
    const { isSelected, onSelect, areAllTasksSelected, isResidentSelector, id } = props;

    return (
        <Box
            onClick={onSelect}
            sx={{
                width: pxToRem(24),
                height: pxToRem(24),
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                marginTop: isResidentSelector ? 0 : pxToRem(8),
            }}
            id={id || ''}
        >
            <SelectorIcon
                isSelected={isSelected}
                areAllTasksSelected={areAllTasksSelected}
                isResidentSelector={isResidentSelector}
            />
        </Box>
    );
};
