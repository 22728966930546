import All from './details/All';
import PotentialCareLevelIncrease from './sections/PotentialCareLevelIncrease';
import ResidentsWithNeedsChanges from './sections/ResidentsWithNeedsChanges';
import TotalCalls from './sections/TotalCalls';

export default {
    sections: {
        TotalCalls,
        ResidentsWithNeedsChanges,
        PotentialCareLevelIncrease,
    },
    details: {
        All,
    },
};
