import { Stack, styled } from '@mui/material';

const DataContainer = styled(Stack)({
    width: '100%',
    alignItems: 'center',
    padding: '0 24px 24px',
    gap: '24px',
});

export default DataContainer;
