import { PaletteColorOptions, PaletteOptions } from '@mui/material';
import { ColorPartial } from '@mui/material/styles/createPalette';

const palette = {
    // 'Primary' on Figma
    primary: {
        100: '#F5FCFD',
        300: '#CDE6E9',
        500: '#127880',
    },

    // 'Secondary' on Figma
    secondary: {
        100: '#F3F9FE',
        300: '#DEEEFB',
        500: '#BDDDF6',
        // MUI uses different shades for secondary, so we need fallback values:
        main: '#BDDDF6',
    } satisfies PaletteColorOptions,

    // 'Neutral' on Figma
    grey: {
        100: '#E9ECEE',
        500: '#92A1AC',
        700: '#51697A',
        900: '#254359',
        // For MUI not to break when applying this color to a Button:
        main: '#254359',
    } satisfies PaletteColorOptions as ColorPartial,

    // 'Feedback/Success' on Figma
    success: {
        100: '#F2F8F8',
        300: '#C2DBDE',
        500: '#00575F',
    },

    // 'Feedback/Warning' on Figma
    warning: {
        500: '#FFB800',
    },

    // 'Feedback/Negative' on Figma
    error: {
        25: '#FEFBFB',
        50: '#FEF5F5',
        100: '#FACECE',
        200: '#F7A7A7',
        300: '#F38080',
        400: '#F05959',
        500: '#EC3232',
        600: '#BF2828',
        700: '#A32020',
    },
} satisfies PaletteOptions;

export default palette;
