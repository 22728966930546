import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledMutation, useErrorHandledQuery, useToken } from '~/api/common';
import { DocumentationActionsParams, DocumentationActionsResponse } from '~/types/dailyTasks';
import { BaseResponse } from '~/types/shared';

export const useDocumentationActionsQuery = ({ branchId: residentBranchId, taskIds }: DocumentationActionsParams) => {
    const branchId = useBranchId();
    const token = useToken();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<DocumentationActionsResponse>>(`/tasks/documentation-actions`, {
            headers: { authorization: token },
            params: {
                branch_id: residentBranchId ?? branchId,
                task_ids: taskIds.join(','),
            },
        });

        return data.response;
    }, [branchId, token, taskIds, residentBranchId]);

    return useErrorHandledQuery<DocumentationActionsResponse>({
        queryKey: ['documentation-actions', taskIds, branchId, residentBranchId],
        queryFn,
        enabled: !!taskIds.length && !!(branchId || residentBranchId),
        staleTime: 300000,
    });
};

export const useAssistLevelChangeMutation = () => {
    const branchId = useBranchId();
    const token = useToken();

    const mutationFn = useCallback(
        async (params: { residentId: number; carePlanId: number }) => {
            const data = {
                resident_id: params.residentId,
                care_plan_id: params.carePlanId,
            };

            const options = {
                headers: { authorization: token },
                params: {
                    branch_id: branchId,
                },
            };

            await api.post(`/tasks/assist-level`, data, options);
        },
        [token, branchId]
    );

    return useErrorHandledMutation({
        mutationFn,
    });
};
