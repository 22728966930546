import { alpha, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from 'recharts';

import { Item, TickProps, TooltipProps } from '~/pages/OperationsV2/utils/recharts';
import { StringKeyOf } from '~/pages/OperationsV2/utils/types';

import DataBarChartTooltip from './DataBarChartTooltip';
import DataBarChartXAxisTick from './DataBarChartXAxisTick';

const BAR_WIDTH_PX = 12;

type DataBarChartProps<T extends Item, TX extends StringKeyOf<T>> = {
    width: number;
    height: number;
    items: T[];
    xAxisDataKey: TX;
    yAxisDataKeys: StringKeyOf<T>[];
    renderXAxisLabel: (item: T) => string;
    renderTooltipLabel: (item: T, dataKey: StringKeyOf<T>) => string | null;
    isSelected: (item: T) => boolean;
};

const DataBarChart = <T extends Item, TX extends StringKeyOf<T>>({
    width,
    height,
    items,
    xAxisDataKey,
    yAxisDataKeys,
    renderXAxisLabel,
    renderTooltipLabel,
    isSelected,
}: DataBarChartProps<T, TX>) => {
    const { palette } = useTheme();

    // Colors for each portion of the bar, top to bottom
    const colorByIndex: Record<'selected' | 'unselected', Record<number, string>> = useMemo(
        () => ({
            selected: {
                0: palette.primary[500] as string,
                1: palette.primary[300] as string,
            },
            unselected: {
                0: alpha(palette.grey[900], 0.2),
                1: alpha(palette.grey[900], 0.1),
            },
        }),
        [palette]
    );

    return (
        <BarChart width={width} height={height} data={items} reverseStackOrder style={{ userSelect: 'none' }}>
            {yAxisDataKeys.map((key, keyIndex) => (
                <Bar
                    key={key}
                    type="linear"
                    dataKey={key}
                    stackId="stack"
                    barSize={BAR_WIDTH_PX}
                    radius={
                        keyIndex === 0
                            ? [BAR_WIDTH_PX / 2, BAR_WIDTH_PX / 2, 0, 0] // Rounded top corners
                            : keyIndex === yAxisDataKeys.length - 1
                              ? [0, 0, BAR_WIDTH_PX / 2, BAR_WIDTH_PX / 2] // Rounded bottom corners
                              : [0, 0, 0, 0]
                    }
                >
                    {items.map((item, itemIndex) => (
                        <Cell
                            key={itemIndex}
                            fill={colorByIndex[isSelected(item) ? 'selected' : 'unselected'][keyIndex]}
                        />
                    ))}
                </Bar>
            ))}
            <Tooltip
                content={(props: TooltipProps<T>) => (
                    <DataBarChartTooltip {...props} renderLabel={renderTooltipLabel} />
                )}
                cursor={false}
                shared={false}
                isAnimationActive={false}
            />
            <XAxis
                dataKey={xAxisDataKey}
                interval={0} // Show all ticks
                padding={{ left: 8, right: 8 }}
                axisLine={false}
                tickLine={false}
                tick={(props: TickProps<T, typeof xAxisDataKey>) => (
                    <DataBarChartXAxisTick
                        {...props}
                        chartHeight={height}
                        items={items}
                        dataKey={xAxisDataKey}
                        isSelected={isSelected}
                        renderLabel={renderXAxisLabel}
                    />
                )}
            />
            <YAxis domain={[0, 'dataMax']} hide />
        </BarChart>
    );
};

export default DataBarChart;
