import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/material';
import { groupBy } from 'lodash';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useMemo } from 'react';

import { PRN_CATEGORY_SECTIONS } from '@allie/utils/src/constants/prn-tasks.constants';

import ButtonGrid from '~/components/Shared/Button/ButtonGrid';
import SquareIconButton from '~/components/Shared/Button/SquareIconButton';

import PrnFlowStep from '../../components/PrnFlowStep';

import useSelectCategoryStep from './hooks/useSelectCategoryStep';
import { PrnTaskCategories } from './types/prnTaskCategories';

export default function SelectCategoryStep({ isECall }: { isECall?: boolean }) {
    const { prnTaskCategories, isLoading, isError, setSelectedCategories, selectedCategories } =
        useSelectCategoryStep(isECall);
    const { palette } = useTheme();
    const isFraudConfirmed = useFeatureFlagEnabled('fraud-confirmed-staff');

    const handleSelectCategory = (category: PrnTaskCategories.Category) => {
        const isAlreadySelected = selectedCategories.some((c) => c.id === category.id);
        if (isAlreadySelected) {
            setSelectedCategories(selectedCategories.filter((c) => c.id !== category.id));
        } else if (isFraudConfirmed) {
            setSelectedCategories([category]);
        } else {
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    const selectedCategoriesIds = useMemo(
        () => selectedCategories.map((category) => category.id),
        [selectedCategories]
    );

    return (
        <PrnFlowStep isLoading={isLoading} isError={isError}>
            <Box display="flex" flexDirection="column" gap="4px">
                <Typography variant="body1" fontSize="16px" fontWeight={700} color={palette.grey[900]}>
                    Select all that apply
                </Typography>
                <Typography variant="body1" fontSize="14px" fontWeight={400} color={palette.grey[600]}>
                    {'Maximum selection is 4 categories'}
                </Typography>
            </Box>

            <Box height="16px" />

            {Object.entries(groupBy(prnTaskCategories, 'section')).map(
                ([section, categories]: [PRN_CATEGORY_SECTIONS, typeof prnTaskCategories]) => (
                    <React.Fragment key={section}>
                        {section !== PRN_CATEGORY_SECTIONS.ALL && (
                            <Typography
                                variant="body2"
                                mt={4}
                                mb={1}
                                fontSize="14px"
                                fontWeight={600}
                                textTransform="capitalize"
                            >
                                {section.toLowerCase()}
                            </Typography>
                        )}
                        <ButtonGrid>
                            {categories.map((category) => (
                                <SquareIconButton
                                    selected={selectedCategoriesIds.includes(category.id)}
                                    disabled={
                                        selectedCategoriesIds.length === 4 &&
                                        !selectedCategoriesIds.includes(category.id)
                                    }
                                    key={category.id}
                                    icon={
                                        <category.icon
                                            color={
                                                selectedCategoriesIds.includes(category.id)
                                                    ? (palette.primary[500] as string)
                                                    : selectedCategoriesIds.length === 4 &&
                                                        !selectedCategoriesIds.includes(category.id)
                                                      ? palette.grey[300]
                                                      : palette.grey[900]
                                            }
                                            size={24}
                                        />
                                    }
                                    label={category.label}
                                    onClick={() => handleSelectCategory(category)}
                                />
                            ))}
                        </ButtonGrid>
                    </React.Fragment>
                )
            )}
        </PrnFlowStep>
    );
}
