import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { UnscheduledTasks } from '~/types/unscheduledTasks';

export const useCreateUnscheduledTaskMutation = ({ residentId }: { residentId?: number }) => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async (params: UnscheduledTasks.CreateUnscheduledTaskParams[]) => {
            const options = { headers: { Authorization: token }, params: { branch_id: branchId } };

            const { data } = await api.post<UnscheduledTasks.CreateUnscheduledTaskResponse>(
                `/prn-tasks`,
                params,
                options
            );

            return data.response;
        },
        [branchId, token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['ongoing-calls', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['undocumented-calls', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['dailyTasks'] });
        await queryClient.invalidateQueries({ queryKey: ['residentDailyTasks', residentId] });
        await queryClient.invalidateQueries({ queryKey: ['rewards'] });
    }, [branchId, residentId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
