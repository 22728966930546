import { Box } from '@mui/material';
import { styled } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import React, { ReactNode, useEffect, useRef, useState } from 'react';

import { selectedBranchIdAtom, selectedCareTypeIdAtom, selectedRegionIdAtom } from '~/pages/OperationsV2/atoms';

import { useCompanyOptions } from '../../../hooks/useCompanyOptions';
import FilterItem from '../FilterItem';
import { isFilterListExpandedAtom } from '../shared';
import {
    FILTERS_HORIZONTAL_GAP_PX,
    FILTERS_VERTICAL_GAP_PX,
    FILTER_ITEM_COLLAPSED_HEIGHT_PX,
    FILTER_ITEM_EXPANDED_HEIGHT_PX,
    HTMLDivElementRef,
} from '../shared';

const Container = styled(Box)({
    width: '100%',
    padding: '8px 24px 20px 24px',
});

const CollapsibleList = ({
    isExpanded,
    itemRefs,
    children,
}: {
    isExpanded: boolean;
    itemRefs: HTMLDivElementRef[];
    children: ReactNode;
}) => {
    const selectedRegionId = useAtomValue(selectedRegionIdAtom);
    const selectedBranchId = useAtomValue(selectedBranchIdAtom);
    const selectedCareTypeId = useAtomValue(selectedCareTypeIdAtom);

    const companyOptions = useCompanyOptions();

    const [containerHeightPx, setContainerHeightPx] = useState(0);
    const containerRef = useRef<HTMLDivElementRef>(null);

    // Height is only updated after the transition ends, so I'm manually
    // calculating it + 1px for inaccuracies (e.g. font metrics, borders)
    const itemHeightPx = (isExpanded ? FILTER_ITEM_EXPANDED_HEIGHT_PX : FILTER_ITEM_COLLAPSED_HEIGHT_PX) + 1;

    const recalculateLayout = () => {
        if (!containerRef.current) return;

        let xOffsetPx = 0;
        let yOffsetPx = 0;
        let newContainerHeightPx = 0;

        itemRefs.forEach((item, index) => {
            if (!item) return;

            if (!isExpanded) {
                // Place items horizontally
                item.style.transform = `translateX(${xOffsetPx}px)`;

                xOffsetPx += item.offsetWidth;
                if (index !== itemRefs.length - 1) xOffsetPx += FILTERS_HORIZONTAL_GAP_PX;

                newContainerHeightPx = Math.max(newContainerHeightPx, itemHeightPx);
            } else {
                // Place items vertically
                item.style.transform = `translateY(${yOffsetPx}px)`;

                yOffsetPx += itemHeightPx;
                if (index !== itemRefs.length - 1) yOffsetPx += FILTERS_VERTICAL_GAP_PX;

                newContainerHeightPx = yOffsetPx;
            }
        });

        setContainerHeightPx(newContainerHeightPx || yOffsetPx);
    };

    useEffect(() => {
        recalculateLayout();
    }, [isExpanded, companyOptions, selectedRegionId, selectedBranchId, selectedCareTypeId]);

    return (
        <Box
            ref={containerRef}
            sx={{
                position: 'relative',
                height: `${containerHeightPx}px`,
                overflow: 'hidden',
                transition: 'height 0.5s ease',
            }}
        >
            {children}
        </Box>
    );
};

const FilterList = () => {
    const isFilterListExpanded = useAtomValue(isFilterListExpandedAtom);

    const [selectedRegionId, setSelectedRegionId] = useAtom(selectedRegionIdAtom);
    const [selectedBranchId, setSelectedBranchId] = useAtom(selectedBranchIdAtom);
    const [selectedCareTypeId, setSelectedCareTypeId] = useAtom(selectedCareTypeIdAtom);

    const { regionOptions, branchOptions, careTypeOptions, isLoading } = useCompanyOptions();

    const itemRefs = useRef<HTMLDivElementRef[]>([]);
    let itemRefsIndex = 0;

    const setItemRef = () => {
        const currentItemIndex = itemRefsIndex++;
        return (el: HTMLDivElementRef) => (itemRefs.current[currentItemIndex] = el);
    };

    return (
        <Container>
            <CollapsibleList isExpanded={isFilterListExpanded} itemRefs={itemRefs.current}>
                <FilterItem
                    value={selectedRegionId}
                    onChange={(value: number | null) => setSelectedRegionId(value)}
                    options={regionOptions}
                    caption="Region"
                    isLoading={isLoading}
                    isExpanded={isFilterListExpanded}
                    disabled={isLoading || regionOptions.length <= 2}
                    ref={setItemRef()}
                />
                <FilterItem
                    value={selectedBranchId}
                    onChange={(value: number | null) => setSelectedBranchId(value)}
                    options={branchOptions}
                    caption="Community"
                    isLoading={isLoading}
                    isExpanded={isFilterListExpanded}
                    disabled={isLoading || !selectedRegionId || branchOptions.length <= 2}
                    ref={setItemRef()}
                />
                <FilterItem
                    value={selectedCareTypeId}
                    onChange={(value: number | null) => setSelectedCareTypeId(value)}
                    options={careTypeOptions}
                    caption="Care Type"
                    isLoading={isLoading}
                    isExpanded={isFilterListExpanded}
                    disabled={isLoading || careTypeOptions.length <= 2}
                    ref={setItemRef()}
                />
            </CollapsibleList>
        </Container>
    );
};

export default FilterList;
