import { ThemeProvider } from '@mui/material';
import { DateTime } from 'luxon';
import momentTZ from 'moment-timezone';
import { PostHog, usePostHog } from 'posthog-js/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { APP_NAME } from '~/constants/app';
import { useAppDispatch } from '~/constants/redux';
import { showAlert } from '~/redux/actions/messages';
import { updateTimezone, validateSession } from '~/redux/actions/session';
import { router } from '~/router';
import theme from '~/theme';
import { AppDispatch, ReduxStore } from '~/types/redux';

type Props = {
    timezone: string;
    dispatchUpdateTimezone: (timezone: string) => void;
    dispatchValidateSession: (posthog: PostHog) => void;
};

const Root = (props: Props) => {
    const { timezone, dispatchUpdateTimezone, dispatchValidateSession } = props;
    const posthog = usePostHog();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const userTimezone = momentTZ.tz.guess();

        if (!timezone || timezone !== userTimezone) {
            dispatchUpdateTimezone(userTimezone);
        }

        dispatchValidateSession(posthog);
    }, []);

    useEffect(() => {
        const timezone = DateTime.now().zoneName;
        const endDateTime = DateTime.fromObject({ hour: 9, minute: 0 }, { zone: 'UTC' }).setZone(timezone);

        dispatch(
            showAlert({
                message: `We are currently performing a scheduled maintenance. During this time you will not be able to document. Come back at ${endDateTime.toFormat('h a')} to document your tasks.`,
                alertSeverity: 'warning',
                timer: Infinity,
                open: process.env.REACT_APP_IS_MAINTENANCE === 'true',
            })
        );
    });

    return (
        <>
            <Helmet defaultTitle={APP_NAME}>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover"
                />
                <meta name="robots" content="no-index" />
                <link rel="shortcut icon" href="/favicon.icon" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&display=swap"
                />
            </Helmet>

            {/* Everything must be wrapped in the RouterProvider,
            except theming, because we want pretty error pages */}
            <ThemeProvider theme={theme}>
                <RouterProvider router={router} />
            </ThemeProvider>
        </>
    );
};

const mapStateToProps = ({ session }: ReduxStore) => {
    const { timezone } = session;
    return { timezone };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchUpdateTimezone: (timezone: string) => dispatch(updateTimezone(timezone)),
    dispatchValidateSession: (posthog: PostHog) => dispatch(validateSession(posthog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
