import { atom } from 'jotai';

export type HTMLDivElementRef = HTMLDivElement | null;

export const FILTER_ITEM_EXPANDED_HEIGHT_PX = 60;
export const FILTER_ITEM_COLLAPSED_HEIGHT_PX = 32;

export const FILTERS_HORIZONTAL_GAP_PX = 20;
export const FILTERS_VERTICAL_GAP_PX = 12;

export const isFilterListExpandedAtom = atom(false);
