import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useBranchesByRegionQuery } from '~/api/queries/companyInfo';
import { BranchesByRegionResponseBranch, BranchesByRegionResponseRegion } from '~/types/companyInfo';
import { ReduxStore } from '~/types/redux';

export type CareType = {
    id: number;
    name: string;
    code: string;
};

export type Branch = {
    id: number;
    name: string;
    shortName: string;
    careTypes: CareType[];
    careTypeById: Map<number, CareType>;
};

export type Region = {
    id: number;
    name: string;
    shortName: string;
    branches: Branch[];
    branchById: Map<number, Branch>;
    careTypes: CareType[];
    careTypeById: Map<number, CareType>;
};

export type CompanyInfo = {
    regions: Region[];
    regionById: Map<number, Region>;
    branches: Branch[];
    branchById: Map<number, Branch>;
    careTypes: CareType[];
    careTypeById: Map<number, CareType>;
};

const remapBranch = (responseBranch: BranchesByRegionResponseBranch): Branch => ({
    id: responseBranch.branchId,
    name: responseBranch.branchName,
    shortName: responseBranch.branchShortName,
    careTypes: [],
    careTypeById: new Map(),
});

const remapRegion = (responseRegion: BranchesByRegionResponseRegion): Region => ({
    id: responseRegion.regionId,
    name: responseRegion.regionName,
    shortName: responseRegion.regionShortName,
    branches: [],
    branchById: new Map(),
    careTypes: [],
    careTypeById: new Map(),
});

export const useCompanyInfo = () => {
    const { companyId } = useSelector(({ session }: ReduxStore) => session.sessionData);
    const { data: branchesByRegionData, isLoading } = useBranchesByRegionQuery({ companyId });

    const companyInfo = useMemo<CompanyInfo | undefined>(() => {
        if (!branchesByRegionData) return undefined;

        // Company-level data
        const regionById: Map<number, Region> = new Map();
        const branchById: Map<number, Branch> = new Map();
        const careTypeById: Map<number, CareType> = new Map();

        // Discover and assign regions to company-level data
        branchesByRegionData.regions
            .filter((region) => region.hasAccess)
            .forEach((responseRegion) => {
                const region = remapRegion(responseRegion);
                regionById.set(region.id, region);
            });

        // Discover and assign branches to regional-level data and discover care types
        branchesByRegionData.branches
            .filter((branch) => branch.hasAccess)
            .map((responseBranch) => {
                const careTypes = branchesByRegionData.availableCareTypesByBranchId[responseBranch.branchId];
                careTypes.forEach((careType) => careTypeById.set(careType.id, careType));

                const branch = remapBranch(responseBranch);
                branchById.set(branch.id, branch);
                careTypes.forEach((careType) => branch.careTypeById.set(careType.id, careType));

                const region = regionById.get(responseBranch.regionId!)!;
                careTypes.forEach((careType) => region.careTypeById.set(careType.id, careType));
                region.branches.push(branch);
                region.branchById.set(branch.id, branch);
            });

        const regions = Array.from(regionById.values());
        const branches = Array.from(branchById.values());
        const careTypes = Array.from(careTypeById.values());

        // Assign care types to regional and branch-level data
        regions.forEach((region) => (region.careTypes = Array.from(region.careTypeById.values())));
        branches.forEach((branch) => (branch.careTypes = Array.from(branch.careTypeById.values())));

        return { regions, regionById, branches, branchById, careTypes, careTypeById };
    }, [branchesByRegionData]);

    return { ...companyInfo, isLoading };
};
