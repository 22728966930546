import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Typography } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';
import React from 'react';

type DatePickerProps = {
    selectedDate: DateTime<true> | null;
    handleDateChange: (date: DateTime<true> | null) => void;
    getFormattedDate?: (date: DateTime<true>) => string;
    minDate: DateTime<true> | undefined;
    maxDate: DateTime<true>;
    hideArrows?: boolean;
    enabled?: boolean;
};

// This specific value is due to the fact that when changing dates the arrow controls shift layout
// So we are setting the largest width possible to avoid this odd behavior
const DATE_PICKER_TITLE_MIN_WIDTH = '180px';

const defaultDateFormatter = (date: DateTime) => {
    return date.hasSame(DateTime.now(), 'day') ? `Today, ${date.toFormat('MMM. d')}` : date.toFormat('ccc, MMM. d');
};

export default function DatePicker({
    selectedDate,
    handleDateChange,
    getFormattedDate = defaultDateFormatter,
    minDate,
    maxDate,
    hideArrows = false,
    enabled = true,
}: DatePickerProps) {
    const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

    const handlePreviousDay = () => {
        if (selectedDate) {
            handleDateChange(selectedDate.minus({ days: 1 }));
        }
    };

    const handleNextDay = () => {
        if (selectedDate) {
            handleDateChange(selectedDate.plus({ days: 1 }));
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="en">
            <Box
                sx={{
                    cursor: enabled ? 'pointer' : 'default',
                    userSelect: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                {selectedDate && (
                    <>
                        <Typography
                            fontSize="24px"
                            fontWeight="700"
                            lineHeight="170%"
                            onClick={() => enabled && setIsDatePickerOpen(true)}
                            color={{ xs: 'common.white', sm: 'text.primary' }}
                            minWidth={DATE_PICKER_TITLE_MIN_WIDTH}
                        >
                            {getFormattedDate(selectedDate)}
                        </Typography>
                        {!hideArrows && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                <IconButton
                                    onClick={handlePreviousDay}
                                    disabled={selectedDate && minDate ? selectedDate <= minDate : false}
                                    sx={({ palette }) => ({
                                        color: {
                                            xs: 'common.white',
                                            sm: palette.grey[500],
                                        },
                                        '&:disabled': {
                                            color: { xs: palette.primary[200] as string, sm: palette.grey[200] },
                                        },
                                    })}
                                >
                                    <ChevronLeftIcon />
                                </IconButton>
                                <IconButton
                                    onClick={handleNextDay}
                                    disabled={selectedDate && maxDate ? selectedDate >= maxDate : false}
                                    sx={({ palette }) => ({
                                        color: {
                                            xs: 'common.white',
                                            sm: palette.grey[500],
                                        },
                                        '&:disabled': {
                                            color: { xs: palette.primary[200] as string, sm: palette.grey[200] },
                                        },
                                    })}
                                >
                                    <ChevronRightIcon />
                                </IconButton>
                            </Box>
                        )}
                    </>
                )}
                <MobileDatePicker
                    open={isDatePickerOpen}
                    onClose={() => setIsDatePickerOpen(false)}
                    label="Shift Date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    slotProps={{
                        textField: { sx: { display: 'none' } },
                    }}
                />
            </Box>
        </LocalizationProvider>
    );
}
