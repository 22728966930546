import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

export const TaskCounter = ({ count }: { count: number }) => {
    const { palette } = useTheme();

    return (
        <Box
            width="32px"
            height="32px"
            borderRadius="32px"
            bgcolor={palette.secondary[500] as string}
            color="white"
            display="flex"
            alignItems="center"
            textAlign="center"
        >
            <Typography margin="auto">{count}</Typography>
        </Box>
    );
};
