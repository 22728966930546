import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledMutation, useToken } from '~/api/common';
import { useAppDispatch } from '~/constants/redux';
import { signOut } from '~/redux/actions/session';

export const useRequestDeleteAccountMutation = () => {
    const token = useToken();

    const mutationFn = useCallback(async () => {
        await api.post('/delete-account/request', null, {
            headers: {
                Authorization: token,
            },
        });

        return null;
    }, [token]);

    return useErrorHandledMutation({ mutationFn });
};

export const useConfirmDeleteAccountMutation = () => {
    const token = useToken();
    const dispatch = useAppDispatch();

    const mutationFn = useCallback(
        async (code: string) => {
            const options = { headers: { Authorization: token } };

            await api.post('/delete-account/confirm', { code }, options);

            return null;
        },
        [token]
    );

    const onSuccess = useCallback(async () => {
        await dispatch(signOut());
    }, [dispatch]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
