import { Box, Button, CircularProgress, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { PiArrowsHorizontalFill } from 'react-icons/pi';
import { useSelector } from 'react-redux';

import { CALL_STATUS } from '@allie/utils/src/constants/ecall/call.constants';
import { DEVICE_LOCATION_TYPE } from '@allie/utils/src/constants/ecall/device.constants';

import { useClaimCallMutation } from '~/api/queries/call/claimCall';
import { OngoingCalls } from '~/api/queries/call/getOngoingCalls';
import { CustomAvatar } from '~/components/Custom';
import { useTimer } from '~/hooks/useTimer';
import { UnclaimedLevel } from '~/types/call/call';
import { ReduxStore } from '~/types/redux';

import { CallDetails } from './CallDetails';
import { getPublicLocationCallIcon } from './mapCallIcon';

const CallCardContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
        flexDirection: 'row',
    },
}));

const CallCardContentContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    borderBottom: 1,
    borderBottomColor: theme.palette.grey[100],
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
        gap: '16px',
        padding: '16px',
        alignItems: 'flex-start',
        flexDirection: 'row',
    },
}));

const CallCardContent = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '12px',

    [theme.breakpoints.up('sm')]: {
        flex: 1,
    },
}));

const UnclaimedButtonContainer = styled(Box)(({ theme }) => ({
    padding: '16px',
    borderRadius: '8px',
    gap: '12px',
    display: 'flex',
    flexDirection: 'column',
    margin: '16px',
    marginTop: 0,

    [theme.breakpoints.up('sm')]: {
        flex: 1,
        margin: 0,
        padding: 0,
        flexDirection: 'row',
    },
}));

interface UnclaimedButtonProps {
    calledAt: DateTime;
    unclaimedLevel: UnclaimedLevel;
    isLoading?: boolean;
    onClick: () => void;
}

const UnclaimedButton = ({ calledAt, unclaimedLevel, isLoading, onClick }: UnclaimedButtonProps) => {
    const timer = useTimer(calledAt);
    const { palette, breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    const styles =
        unclaimedLevel === UnclaimedLevel.Level3
            ? {
                  backgroundColor: palette.error[50] as string,
                  border: `1px solid ${palette.error[900] as string}`,
              }
            : {
                  backgroundColor: palette.warning[200] as string,
                  border: `1px solid ${palette.warning[500]}`,
              };

    return (
        <UnclaimedButtonContainer sx={styles}>
            <Box sx={isDesktop ? { flex: 1, padding: '16px' } : undefined}>
                <Typography
                    variant="body1"
                    fontWeight={700}
                    color={
                        unclaimedLevel === UnclaimedLevel.Level3 ? (palette.error[900] as string) : palette.grey[900]
                    }
                >
                    Call
                </Typography>
                <Typography
                    variant="body2"
                    color={
                        unclaimedLevel === UnclaimedLevel.Level3 ? (palette.error[900] as string) : palette.grey[900]
                    }
                >
                    Resident has been waiting for <span style={{ fontWeight: 700 }}>{timer}</span>
                </Typography>
            </Box>
            <Button
                sx={[
                    { minWidth: '150px' },
                    isDesktop ? { margin: '16px', maxHeight: '46px', marginBlock: 'auto' } : {},
                ]}
                variant={unclaimedLevel === UnclaimedLevel.Level3 ? 'containedError' : 'warning'}
                onClick={onClick}
            >
                {isLoading ? <CircularProgress size={20} thickness={4} sx={{ color: 'white' }} /> : 'Claim'}
            </Button>
        </UnclaimedButtonContainer>
    );
};

interface StatusBadgeProps {
    calledAt: DateTime;
    attendedAt?: DateTime;
    status: CALL_STATUS;
    claimedByUser: boolean;
}

const StatusBadge = ({ calledAt, status, attendedAt, claimedByUser }: StatusBadgeProps) => {
    const { palette, breakpoints } = useTheme();
    const timer = useTimer(attendedAt ?? calledAt);
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    return (
        <Box
            mt="8px"
            display="flex"
            padding="8px"
            borderRadius="8px"
            justifyContent="space-between"
            gap="16px"
            bgcolor={claimedByUser ? (palette.secondary[200] as string) : palette.grey[100]}
            width={isDesktop ? (claimedByUser ? 'fit-content' : '240px') : undefined}
        >
            <Typography variant="body1" color={palette.grey[900]} fontWeight={700} lineHeight="18px">
                {status === CALL_STATUS.CLAIMED ? 'Claimed' : 'In Progress'}
            </Typography>
            {!claimedByUser && (
                <Typography variant="body1" color={palette.grey[900]} fontWeight={400}>
                    {status === CALL_STATUS.CLAIMED ? 'Called' : 'Started'} {timer} ago
                </Typography>
            )}
        </Box>
    );
};

export const CallCard = (props: OngoingCalls[number]) => {
    const { mutateAsync: claimCall, isPending } = useClaimCallMutation();
    const [showDetails, setShowDetails] = useState(false);
    const { palette, breakpoints } = useTheme();
    const isDesktop = useMediaQuery(breakpoints.up('sm'));

    const { userId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const callOwner = props.claimedByUser ?? props.attendedByUser;
    const claimedByUser = callOwner?.id === userId;
    const isOpenCall = props.status === CALL_STATUS.OPEN;

    const handleClick = () => {
        if (!isOpenCall && claimedByUser) {
            setShowDetails((prev) => !prev);
        }
    };

    const handleClaimCall = async () => {
        await claimCall({ callId: props.id });
    };

    return (
        <CallCardContainer>
            <CallCardContentContainer bgcolor={!isOpenCall && !claimedByUser ? palette.grey[50] : undefined}>
                <CallCardContent
                    onClick={handleClick}
                    sx={{
                        padding: isDesktop ? undefined : '16px',
                        boxShadow: showDetails && !isDesktop ? '0px 4px 14.1px 0px rgba(0, 0, 0, 0.10)' : 'none',
                    }}
                >
                    {props.deviceLocation?.type === DEVICE_LOCATION_TYPE.PRIVATE_ROOM ? (
                        <CustomAvatar
                            firstName={props.resident?.firstName}
                            lastName={props.resident?.lastName}
                            photo={props.resident?.photo ?? ''}
                            size={64}
                        />
                    ) : (
                        <Box bgcolor={palette.grey[25]} borderRadius="32px" padding="16px">
                            {getPublicLocationCallIcon(props.deviceLocation?.type)}
                        </Box>
                    )}
                    <Box display="flex" flex={1} flexDirection="column">
                        <Box flex={1} display="flex" gap="4px" alignItems="center">
                            <Typography fontSize="16px" color={palette.grey[900]} textAlign="left" fontWeight={700}>
                                {props.deviceLocation?.type === DEVICE_LOCATION_TYPE.PRIVATE_ROOM
                                    ? `${props.resident?.firstName} ${isOpenCall ? props.resident?.lastName : `${props.resident?.lastName.charAt(0)}.`}`
                                    : props.deviceLocation?.name}
                            </Typography>
                            {!isOpenCall && (
                                <>
                                    <PiArrowsHorizontalFill size={16} color={palette.grey[900]} />
                                    <Typography color={palette.grey[900]} textAlign="left" fontWeight={400}>
                                        {claimedByUser
                                            ? 'Me'
                                            : `${callOwner?.firstName} ${callOwner?.lastName.charAt(0)}`}
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <Typography color={palette.secondary.main} fontWeight={700}>
                            {props.resident?.roomNumber
                                ? `Apt ${props.resident?.roomNumber}`
                                : (props.deviceLocation?.roomNumber ?? props.deviceLocation?.zoneName)}
                        </Typography>
                        {!isOpenCall && (!isDesktop || claimedByUser) && (
                            <StatusBadge
                                calledAt={props.triggeredAt}
                                status={props.status}
                                attendedAt={props.attendedAt ?? undefined}
                                claimedByUser={claimedByUser}
                            />
                        )}
                    </Box>
                    {!isOpenCall &&
                        claimedByUser &&
                        !isDesktop &&
                        (showDetails ? (
                            <FaChevronUp size={20} color={palette.grey[300]} />
                        ) : (
                            <FaChevronDown size={20} color={palette.grey[300]} />
                        ))}
                </CallCardContent>
                {isOpenCall && (
                    <UnclaimedButton
                        calledAt={props.triggeredAt}
                        unclaimedLevel={props.level}
                        onClick={handleClaimCall}
                        isLoading={isPending}
                    />
                )}

                {!isOpenCall && (showDetails || isDesktop) && !!props.deviceLocation?.type && (
                    <CallDetails
                        callId={props.id}
                        claimedByUser={claimedByUser}
                        status={props.status}
                        resident={props.resident}
                        suggestedLocations={undefined} // TODO - add suggested locations
                        calledAt={props.triggeredAt}
                        startedAt={props.attendedAt ?? undefined}
                        type={props.deviceLocation.type}
                        deviceLocation={props.deviceLocation}
                    />
                )}

                {!isOpenCall && isDesktop && !claimedByUser && (
                    <Box flex={1}>
                        <StatusBadge
                            calledAt={props.triggeredAt}
                            status={props.status}
                            attendedAt={props.attendedAt ?? undefined}
                            claimedByUser={false}
                        />
                    </Box>
                )}
            </CallCardContentContainer>
        </CallCardContainer>
    );
};
