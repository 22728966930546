import { AxiosError } from 'axios';

import { api } from '~/api';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import actionTypes from '~/redux/actionTypes';
import '~/redux/reducers/taskStatuses';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { BaseResponse } from '~/types/shared';
import { TaskStatusesReadParams, TaskStatusesResponse } from '~/types/taskStatuses';

/**
 * Fetches the list of Task Statuses.
 * @param jsonParams the JSON object with the params for fetching the Task Statuses list.
 */
export const readTaskStatuses =
    (jsonParams: TaskStatusesReadParams, actionType = actionTypes.TASK_STATUS_READ_LIST) =>
    async (dispatch: AppDispatch, getState: () => ReduxStore) => {
        try {
            // Get the token.
            const { token } = getState().session.sessionData;

            // Fetch the Residents List.
            const { data } = await api.get<BaseResponse<TaskStatusesResponse[]>>('/task-statuses', {
                headers: {
                    authorization: token,
                },
                params: {
                    branch_id: jsonParams.branchId,
                    starting_from_id: jsonParams.startingFromId,
                },
            });
            const taskStatusesList = data.response;

            dispatch({
                type: actionType,
                payload: taskStatusesList,
            });
        } catch (err) {
            const error = err as AxiosError;

            showToast({
                message: error.message,
                type: 'error',
            });
        }
    };
