import { useAtom } from 'jotai';

import { useGetCategoriesQuery } from '~/api/queries/unscheduledTasks/getCategories';

import { unscheduledTaskCategoriesAtom } from '../../../state/atom';
import withIcon from '../utils/withIcon';

export default function useSelectCategoryStep(isECall = false) {
    const [selectedCategories, setSelectedCategories] = useAtom(unscheduledTaskCategoriesAtom);

    const { data: prnTaskCategories, isLoading, isError } = useGetCategoriesQuery(isECall);

    return {
        prnTaskCategories: prnTaskCategories?.map(withIcon) ?? [],
        isLoading,
        isError,
        selectedCategories,
        setSelectedCategories,
    };
}
