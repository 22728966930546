import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { PiSmileyWink } from 'react-icons/pi';

import { useSaveChosenCareLocations } from '~/api/queries/assignments/saveChosenCareLocations';
import { useSignOut } from '~/api/queries/auth/signout';

import { isAssignmentsModalOpenAtom, isOnCurrentShiftAtom } from '../atom';
import { AssignmentsModal } from '../modal/AssignmentsModal';
import { MotivationMessageModal } from '../modal/MotivationMessageModal';
import { OnShiftConfirmationModal } from '../modal/OnShiftConfirmationModal';

import { useShiftVerification } from './useShiftVerification';

export const NotificationFlow = () => {
    const { isOnShiftConfirmModalOpen, toggleOnShiftConfirmModal, stopNotificationFlow, resetVerificationTime } =
        useShiftVerification();

    const [isOnCurrentShift, setIsOnCurrentShift] = useAtom(isOnCurrentShiftAtom);
    const [isAssignmentsModalOpen, toggleAssignmentsModal] = useAtom(isAssignmentsModalOpenAtom);
    const [isSuccessMessageModalOpen, toggleSuccessMessageModal] = useState(false);

    const { mutateAsync: saveChosenAssignments, isPending: isSavingChosenAssignments } = useSaveChosenCareLocations();
    const { mutateAsync: signOut, isPending: isSigningOut } = useSignOut();

    const handleConfirm = () => {
        toggleOnShiftConfirmModal();
        toggleAssignmentsModal();
        setIsOnCurrentShift(true);
    };

    const handleAssignmentSubmit = () => {
        toggleAssignmentsModal();
        toggleSuccessMessageModal(true);
    };

    const handleSignout = async () => {
        await saveChosenAssignments([]);
        setIsOnCurrentShift(false);

        // resiting to trigger the notification flow when the next user logs in
        resetVerificationTime();

        await signOut({});
        toggleOnShiftConfirmModal();
    };

    const handleClose = async () => {
        await saveChosenAssignments([]);
        setIsOnCurrentShift(false);
        stopNotificationFlow();
    };

    return (
        <>
            <OnShiftConfirmationModal
                isOpen={isOnShiftConfirmModalOpen}
                onClose={async () => {
                    await handleClose();
                    toggleOnShiftConfirmModal();
                }}
                onConfirm={handleConfirm}
                onLogout={handleSignout}
                endOfShift={isOnCurrentShift}
                isLoading={isSavingChosenAssignments}
                isSigningOut={isSigningOut}
            />
            <AssignmentsModal
                isOpen={isAssignmentsModalOpen}
                onClose={async () => {
                    await handleClose();
                    toggleAssignmentsModal();
                }}
                onSubmit={handleAssignmentSubmit}
            />
            <MotivationMessageModal
                isOpen={isSuccessMessageModalOpen}
                onContinue={() => {
                    toggleSuccessMessageModal(false);
                    stopNotificationFlow();
                }}
                title="You got this!"
                description="Your hard work and care makes all the difference."
                buttonText="I know"
                icon={PiSmileyWink}
            />
        </>
    );
};
