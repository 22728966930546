import { DateTime } from 'luxon';
import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';

type Item = SectionDataType<'BRANCH', 'CALL_BY_DETAIL', 'EMERGENCY_CALLS'>['items'][number];

const formatTimestamp = (value: string) => DateTime.fromISO(value, { setZone: true }).toFormat('h a');

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                    weight: 1.5,
                },
                timestamp: {
                    label: 'Timestamp',
                    alignment: 'right',
                    renderCell: (value) => (value ? formatTimestamp(value) : null),
                },
            }}
            rows={items}
            takeFirst={3}
            seeMore={() => {}}
            containerProps={{ width: '100%' }}
        />
    </DataContainer>
);

export default Data;
