import AvgResponseTimeBy from './details/AvgResponseTimeBy';
import LongestResponseTimeDetails from './details/LongestResponseTime';
import AvgResponseTime from './sections/AvgResponseTime';
import AvgResponseTimeByCareStaff from './sections/AvgResponseTimeByCareStaff';
import AvgResponseTimeByResident from './sections/AvgResponseTimeByResident';
import LongestResponseTimeSection from './sections/LongestResponseTime';

export default {
    sections: {
        AvgResponseTime,
        AvgResponseTimeByCareStaff,
        AvgResponseTimeByResident,
        LongestResponseTime: LongestResponseTimeSection,
    },
    details: {
        AvgResponseTimeBy,
        LongestResponseTime: LongestResponseTimeDetails,
    },
};
