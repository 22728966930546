import qs from 'qs';
import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useErrorHandledQuery, useToken } from '~/api/common';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import {
    ResidentShiftNotesCreateParams,
    ResidentShiftNotesDeleteParams,
    ResidentShiftNotesReadParams,
    ResidentShiftNotesResponse,
    ResidentShiftNotesUpdateParams,
} from '~/types/residentShiftNotes';
import { BaseResponse } from '~/types/shared';

export const useResidentShiftNotesQuery = (params: ResidentShiftNotesReadParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                branch_id: params.branchId,
                resident_id: params.residentId,
                date_shifts: qs.stringify(params.dateShifts),
            },
        };

        const { data } = await api.get<BaseResponse<ResidentShiftNotesResponse[]>>('/resident-shift-notes', options);

        return data.response;
    }, [params, token]);

    return useErrorHandledQuery({
        queryKey: ['residentShiftNotes', params.residentId],
        queryFn,
        enabled: !!params.residentId && !!params.branchId,
        staleTime: 300000,
    });
};

const invalidateQueries = ({ userId, residentId }: { userId: number; residentId: number }) =>
    Promise.all([
        queryClient.invalidateQueries({
            queryKey: ['residentShiftNotes', residentId],
            exact: false,
            refetchType: 'active',
        }),
        queryClient.invalidateQueries({
            queryKey: ['rewards', userId],
            exact: false,
            refetchType: 'active',
        }),
    ]);

export const useResidentShiftNotesCreateMutation = (userId: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async (jsonParams: ResidentShiftNotesCreateParams) => {
            const data = {
                resident_id: jsonParams.residentId,
                branch_id: branchId ?? jsonParams.branchId,
                user_id: jsonParams.userId,
                shift_note_category_id: jsonParams.shiftNoteCategoryId,
                report: jsonParams.report,
            };
            const options = {
                headers: { authorization: token },
                params: { branch_id: branchId ?? jsonParams.branchId }, // For permission checking
            };

            await api.post('/resident-shift-notes', data, options);
        },
        [token, branchId]
    );

    const onSuccess = useCallback(
        async (_response: void, { residentId }: ResidentShiftNotesCreateParams) => {
            await invalidateQueries({ userId, residentId });

            showToast({
                message: 'Note created successfully!',
                type: 'success',
            });
        },
        [userId, showToast]
    );

    return useErrorHandledMutation({ mutationFn, onSuccess });
};

export const useResidentShiftNotesUpdateMutation = (userId: number) => {
    const token = useToken();

    const mutationFn = useCallback(
        async (params: ResidentShiftNotesUpdateParams) => {
            const data = {
                resident_id: params.residentId,
                branch_id: params.branchId,
                user_id: params.userId,
                shift_note_category_id: params.shiftNoteCategoryId,
                report: params.report,
            };
            const options = {
                headers: { authorization: token },
                params: { branch_id: params.branchId }, // For permission checking
            };

            await api.put(`/resident-shift-notes/${params.id}`, data, options);
        },
        [token]
    );

    const onSuccess = useCallback(
        async (_response: void, { residentId }: ResidentShiftNotesUpdateParams) => {
            await invalidateQueries({ userId, residentId });

            showToast({
                message: 'Note updated successfully!',
                type: 'success',
            });
        },
        [userId, showToast]
    );

    return useErrorHandledMutation({ mutationFn, onSuccess });
};

export const useResidentShiftNotesDeleteMutation = (userId: number) => {
    const token = useToken();

    const mutationFn = useCallback(
        async (params: ResidentShiftNotesDeleteParams) => {
            const options = {
                headers: { authorization: token },
                params: { branch_id: params.branchId }, // For permission checking
            };

            await api.delete(`/resident-shift-notes/${params.id}`, options);
        },
        [token]
    );

    const onSuccess = useCallback(
        async (_response: void, { residentId }: ResidentShiftNotesDeleteParams) => {
            await invalidateQueries({ userId, residentId });

            showToast({
                message: 'Note deleted successfully!',
                type: 'success',
            });
        },
        [userId, showToast]
    );

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
