import { Box, Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { IconType } from 'react-icons';

interface PlaceholderProps {
    title: string;
    description?: string | React.ReactNode;
    icon: IconType;
    type: 'primary' | 'secondary' | 'error' | 'grey';
    size?: 'small' | 'large';
}

const IconContainer = styled(Box)(() => ({
    width: '100px',
    height: '100px',
    padding: '32px',
    borderRadius: '100px',
}));

export const Placeholder = (props: PlaceholderProps) => {
    const { palette } = useTheme();

    return (
        <Stack alignItems="center" height="100%" justifyContent="center">
            <IconContainer bgcolor={palette[props.type][50] as string}>
                <props.icon size={36} color={palette[props.type][500] as string} />
            </IconContainer>
            <Box height={props.size === 'small' ? '24px' : '32px'} />
            <Typography
                color={palette.grey[900]}
                variant="h5"
                letterSpacing="unset"
                fontSize={props.size === 'small' ? '20px' : '24px'}
            >
                {props.title}
            </Typography>
            <Typography
                color={palette.grey[500]}
                variant="body1"
                textAlign="center"
                sx={{ textWrap: 'balance' }}
                fontWeight={400}
                marginTop="8px"
            >
                {props.description}
            </Typography>
        </Stack>
    );
};
