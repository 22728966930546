import { orderBy } from 'lodash';
import { useMemo } from 'react';

import { useCompanyCarePlansListQuery } from '~/api/queries/companyCarePlans';
import { DailyTasksShiftDetail, ResidentTasks } from '~/types/dailyTasks.d';
import { SortBy } from '~/types/sort.d';

import { ECall } from './eCallComponents/types';

export const useSortBy = (branchId?: number) => {
    const { data: carePlanData } = useCompanyCarePlansListQuery(branchId);

    const carePlanIdToKeyService = useMemo(() => {
        const entries = carePlanData?.map(({ companyCarePlanId: id, keyService }) => [id, keyService]) ?? [];
        return Object.fromEntries(entries) as Record<number, string | undefined>;
    }, [carePlanData]);

    const hasTaskWithKeyService = (tasks: DailyTasksShiftDetail[], keyService: string): boolean =>
        tasks.some(({ companyCarePlanId }) => carePlanIdToKeyService[companyCarePlanId] === keyService);

    const sortByShowerAndLaundry = (residentsTasksList: ResidentTasks[], isAscending: boolean): ResidentTasks[] => {
        const sortedArray = orderBy(
            residentsTasksList,
            [
                (item) => hasTaskWithKeyService(item.tasks, 'SHOWERING'),
                (item) => hasTaskWithKeyService(item.tasks, 'LAUNDRY'),
            ],
            [isAscending ? 'desc' : 'asc', isAscending ? 'desc' : 'asc']
        );

        return sortedArray;
    };

    const applyDefaultOrder = (residentsTasksList: ResidentTasks[], sortOrder: 'asc' | 'desc') =>
        orderBy(residentsTasksList, (item) => Number(item.roomNumber), sortOrder === 'asc' ? 'asc' : 'desc');

    const handleSort = (
        residentsTasksList: ResidentTasks[],
        selectedSortBy?: ECall.SortingKey | SortBy.SortingKey,
        isAscending = true
    ): ResidentTasks[] => {
        if (!selectedSortBy) {
            return applyDefaultOrder(residentsTasksList, isAscending ? 'asc' : 'desc');
        }

        if (selectedSortBy === 'shower&Laundry') {
            return sortByShowerAndLaundry(residentsTasksList, isAscending);
        }

        if (selectedSortBy === 'roomNumber') {
            return orderBy(residentsTasksList, (item) => Number(item[selectedSortBy]), isAscending ? 'asc' : 'desc');
        }

        return orderBy(residentsTasksList, selectedSortBy, isAscending ? 'asc' : 'desc');
    };

    return { handleSort };
};
