import { Stack, useTheme } from '@mui/material';
import { Warning } from '@phosphor-icons/react';
import { DateTime } from 'luxon';
import React, { useCallback, useRef } from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import AddNotesButton from '~/pages/OperationsV2/components/Module/Details/Notes/AddNotesButton';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_DETAIL', 'ALL', 'NOT_FEELING_WELL'>[number];

const Data = ({ items }: { items: Item[] }) => {
    const { palette } = useTheme();

    const containerRef = useRef<HTMLDivElement | null>(null);

    const WarningIcon = useCallback(
        () => (
            <Warning
                color={palette.warning[500] as string}
                size="24px"
                weight="duotone"
                cursor="pointer"
                onClick={() => {
                    if (!containerRef.current) return;

                    containerRef.current.scrollTo({
                        left: containerRef.current.scrollWidth,
                        behavior: 'smooth',
                    });
                }}
            />
        ),
        []
    );

    return (
        <DataContainer ref={containerRef}>
            <DataTable
                columns={{
                    residentName: {
                        label: 'Resident',
                        renderCell: (value, { row }) => (
                            <Stack direction="row" alignItems="center" spacing="8px">
                                {!!row.potentialPattern && <WarningIcon />}
                                <SingleLineTypography>{value}</SingleLineTypography>
                            </Stack>
                        ),
                    },
                    staffName: {
                        label: 'Care Staff',
                    },
                    timestamp: {
                        label: 'Timestamp',
                        renderCell: (value) => (value ? DateTime.fromISO(value).toFormat('h:mm a, MMM d (EEE)') : null), // e.g. 1:23 PM, Jan 1 (Fri)
                    },
                    responseTimeMs: {
                        label: 'Response Time',
                        renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                    },
                    staffNotes: {
                        label: 'Staff Notes',
                        allowSort: false,
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '—'),
                    },
                    managerNotes: {
                        label: 'Manager Notes',
                        allowSort: false,
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : <AddNotesButton />),
                    },
                    potentialPattern: {
                        label: 'Potential Pattern',
                        allowSort: false,
                        renderHeaderStartIcon: (rows) => rows.some((row) => !!row.potentialPattern) && <WarningIcon />,
                        renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '—'),
                    },
                }}
                rows={items}
            />
        </DataContainer>
    );
};

export default Data;
