import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

import { useCallResidentRoutine } from '~/api/queries/call/callResidentRoutine';

export const ResidentsRoutine = ({ callId }: { callId: number }) => {
    const { palette } = useTheme();
    const { data: residentRoutine } = useCallResidentRoutine(callId);

    // TODO - uncomment after demo
    // if (!residentRoutine?.tasks.length) return null;

    // TODO - remove after demo and use api data directly
    const residentRoutineTasks =
        residentRoutine?.tasks && residentRoutine.tasks.length
            ? residentRoutine.tasks
            : ['Showering Assistance', 'Incontinence Assistance', 'Spot Checks'];

    return (
        <Box padding="16px">
            <Typography variant="body1" color={palette.grey[600]} fontWeight={500}>
                {residentRoutine?.shiftName} Routine
            </Typography>
            <ul style={{ paddingLeft: '16px' }}>
                {residentRoutineTasks.map((task, index) => (
                    <li key={index}>
                        <Typography variant="body1" color={palette.grey[600]} fontWeight={400}>
                            {task}
                        </Typography>
                    </li>
                ))}
            </ul>
        </Box>
    );
};
