import { Palette } from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export const fontStyle = {
    fontFamily: 'Manrope, sans-serif',
};

const typography = (palette: Palette) =>
    ({
        allVariants: {
            color: palette.grey[900],
            ...fontStyle,
        },

        // 'Extra Large/Regular' on Figma
        subtitle1: {
            fontSize: 24,
            lineHeight: '150%', // 36px
            letterSpacing: 0,
        },

        // 'Large/Regular' on Figma
        subtitle2: {
            fontSize: 20,
            lineHeight: '160%', // 32px
            letterSpacing: 0,
        },

        // 'Base/Regular' on Figma
        body1: {
            fontSize: 16,
            lineHeight: '140%', // 22.4px
            letterSpacing: 0,
        },

        // 'Small/Regular' on Figma
        body2: {
            fontSize: 14,
            lineHeight: '120%', // 16.8px
            letterSpacing: 0,
        },

        // 'Extra Small/Regular' on Figma
        caption: {
            fontSize: 12,
            lineHeight: '130%', // 15.6px
            letterSpacing: '0.48px',
        },

        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    }) satisfies TypographyOptions;

export default typography;
