import { Box } from '@mui/material';
import React from 'react';

import { useRewardsDetailsQuery } from '~/api/queries/caregiverRewards';
import { pxToRem } from '~/components/theme/typography';

import BadgeItem from './BadgeItem';

type Props = {
    userId: number;
};

const BadgesSection = ({ userId }: Props) => {
    const { isLoading: rewardsDetailsIsLoading, data: rewardsDetailsData } = useRewardsDetailsQuery(userId);

    if (rewardsDetailsIsLoading || !rewardsDetailsData) {
        return null;
    }

    if (rewardsDetailsData.shiftType === 'NEVER') {
        return null;
    }

    return (
        <Box>
            <Box
                sx={{
                    marginBottom: pxToRem(16),
                    marginTop: pxToRem(10),
                    color: '#2C2D3B',
                    fontWeight: 700,
                    fontSize: pxToRem(14),
                }}
            >
                Badges earned
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: pxToRem(28),
                    overflowX: 'auto',
                    overscrollBehaviorInline: 'contain',
                }}
            >
                {rewardsDetailsData.badgesEarned.map((badge) => (
                    <BadgeItem badgeType={badge} key={badge} />
                ))}
            </Box>
        </Box>
    );
};

export default BadgesSection;
