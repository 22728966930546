import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { OngoingCalls } from '~/api/queries/call/getOngoingCalls';
import { TabKey } from '~/types/dailyTasks.d';
import { ReduxStore } from '~/types/redux';

import { selectedTabAtom } from '../atom';

import { ecallSortAtom } from './atom';

export const useSortCalls = () => {
    const selectedTab = useAtomValue(selectedTabAtom);
    const userId = useSelector((state: ReduxStore) => state.session.sessionData.userId);
    const sortBy = useAtomValue(ecallSortAtom);

    const sortByRoomNumber = (first: OngoingCalls[number], second: OngoingCalls[number]) => {
        const firstRoomNumber = first.resident ? first.resident.roomNumber : null;
        const secondRoomNumber = second.resident ? second.resident.roomNumber : null;

        if (!firstRoomNumber || !secondRoomNumber) return 0;

        return firstRoomNumber.localeCompare(secondRoomNumber);
    };

    const sortByCallOrder = (first: OngoingCalls[number], second: OngoingCalls[number]) => {
        const firstTimestamp = first.triggeredAt.toMillis();
        const firstWasClaimed = !!first.claimedByUser;
        const firstClaimedByUser = first.claimedByUser && first.claimedByUser.id === userId;

        const secondTimestamp = second.triggeredAt.toMillis();
        const secondWasClaimed = !!second.claimedByUser;
        const secondClaimedByUser = second.claimedByUser && second.claimedByUser.id === userId;

        // #1 - My claimed calls
        if (firstClaimedByUser && !secondClaimedByUser) return -1;
        if (!firstClaimedByUser && secondClaimedByUser) return 1;

        // #2 - Calls from other caregivers
        if (firstWasClaimed && !firstClaimedByUser && !secondWasClaimed) return 1;
        if (!firstWasClaimed && secondWasClaimed && !secondClaimedByUser) return -1;

        // Now sorting by wait time within the groups:
        if (firstClaimedByUser && secondClaimedByUser) {
            // Sort by longest to shortest wait time for My claimed calls
            return secondTimestamp - firstTimestamp;
        }

        if (!firstClaimedByUser && !secondClaimedByUser) {
            // Sort by shortest to longest wait time for Unclaimed calls
            return firstTimestamp - secondTimestamp;
        }

        if (firstWasClaimed && !firstClaimedByUser && secondWasClaimed && !secondClaimedByUser) {
            // Sort by longest to shortest wait time for Other people claimed calls
            return secondTimestamp - firstTimestamp;
        }

        // Fallback case: if the groups are mixed, prioritize sorting by claimed status and wait times
        return 0;
    };

    const sortCalls = useCallback(
        (calls: OngoingCalls) => {
            // skip sorting when Live Calls tab is not selected
            if (selectedTab !== TabKey.LIVE_CALLS) return calls;

            const sortKey = sortBy;

            return calls.sort((a, b) => {
                if (sortKey === 'roomNumber') {
                    return sortByRoomNumber(a, b);
                }

                return sortByCallOrder(a, b);
            });
        },
        [selectedTab, sortBy]
    );

    return { sortCalls };
};
