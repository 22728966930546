import { Box, styled } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useCallback, useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import { selectedDateAtom, selectedPeriodAtom } from '~/pages/OperationsV2/atoms';
import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataLineChart from '~/pages/OperationsV2/components/Module/Section/recharts/DataLineChart';
import { formatDateXAxisLabel } from '~/pages/OperationsV2/components/Module/Section/recharts/shared';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'BRANCH', 'CALL_BY_RESPONSE_TIME', 'AVG_RESPONSE_TIME'>['items'][number];

const ChartContainer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    width: '100%',
    padding: '24px 0 12px',
    borderRadius: '16px',
}));

const Data = ({ items }: { items: Item[] }) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

    const selectedPeriod = useAtomValue(selectedPeriodAtom);
    const selectedDate = useAtomValue(selectedDateAtom);

    const renderLabel = useCallback(
        (item: Item) => (item.responseTimeMs ? formatMsToMinutes(item.responseTimeMs) : null),
        []
    );

    const isSelected = useCallback(
        (item: Item) => DateTime.fromISO(item.date).hasSame(selectedDate, 'day'),
        [selectedDate]
    );

    const renderXAxisLabel = useCallback(
        (item: Item) => {
            const date = DateTime.fromISO(item.date) as DateTime<true>;
            return formatDateXAxisLabel(selectedPeriod, date, isSelected(item));
        },
        [isSelected]
    );

    return (
        <DataContainer>
            <ChartContainer ref={setContainerRef}>
                <DataLineChart
                    width={containerRef?.clientWidth ?? 0}
                    height={200}
                    items={items}
                    xAxisDataKey="date"
                    yAxisDataKey="responseTimeMs"
                    renderXAxisLabel={renderXAxisLabel}
                    renderLineTooltipLabel={renderLabel}
                    renderSelectedTooltipLabel={renderLabel}
                    isSelected={isSelected}
                />
            </ChartContainer>
        </DataContainer>
    );
};

export default Data;
