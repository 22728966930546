import React, { ReactNode } from 'react';

import {
    AnyDetailsGroupingType,
    AnyDetailsType,
    AnyModuleType,
    AnySectionType,
    DetailsGroupingDataType,
    DetailsGroupingType,
    DetailsPayload,
    DetailsType,
    ModuleType,
    SectionDataType,
    SectionType,
} from '@allie/operations-common/src/types/module';
import { Level } from '@allie/operations-common/src/types/shared';

import { formatMsToMinutes } from '../utils/format';

import levels from './levels';

export type LevelDefinition<L extends Level> = Readonly<{
    level: L;
    modules: { [M in ModuleType<L>]: ModuleDefinition<L, M> };
}>;

export type ModuleDefinition<L extends Level, M extends ModuleType<L>> = Readonly<{
    type: M;
    label: string;
    sections: { [S in SectionType<L, M>]: SectionDefinition<L, M, S> };
    details?: { [D in DetailsType<L, M>]: DetailsDefinition<L, M, D> };
}>;

export type SectionDefinition<L extends Level, M extends ModuleType<L>, S extends SectionType<L, M>> = Readonly<{
    type: S;
    label: string;
    caption?: string;
    detailsPayload?: Extract<DetailsPayload, { moduleType: M }>;
    formatSummary?: (data: SectionDataType<L, M, S>) => string | number;
    renderData: (data: SectionDataType<L, M, S>) => ReactNode;
}>;

export type DetailsDefinition<L extends Level, M extends ModuleType<L>, D extends DetailsType<L, M>> = Readonly<{
    type: D;
    label: string;
    caption?: string;
    groupings: { [G in DetailsGroupingType<L, M, D>]: DetailsGroupingDefinition<L, M, D, G> };
}>;

export type DetailsGroupingDefinitionTextOverride<
    L extends Level,
    M extends ModuleType<L>,
    D extends DetailsType<L, M>,
    G extends DetailsGroupingType<L, M, D>,
> = string | ((data: DetailsGroupingDataType<L, M, D, G>) => string);

export type DetailsGroupingDefinition<
    L extends Level,
    M extends ModuleType<L>,
    D extends DetailsType<L, M>,
    G extends DetailsGroupingType<L, M, D>,
> = Readonly<{
    type: G;
    label: string;
    overrideDetailsLabel?: DetailsGroupingDefinitionTextOverride<L, M, D, G>;
    overrideDetailsCaption?: DetailsGroupingDefinitionTextOverride<L, M, D, G>;
    allowSearch?: boolean;
    searchPlaceholder?: string;
    renderData: (data: DetailsGroupingDataType<L, M, D, G>) => ReactNode;
}>;

// Helper types for when we really don't care about/cannot get the specific type
export type AnyModuleDefinition = ModuleDefinition<Level, AnyModuleType>;
export type AnySectionDefinition = SectionDefinition<Level, AnyModuleType, AnySectionType>;
export type AnyDetailsDefinition = DetailsDefinition<Level, AnyModuleType, AnyDetailsType>;
export type AnyDetailsGroupingDefinition = DetailsGroupingDefinition<
    Level,
    AnyModuleType,
    AnyDetailsType,
    AnyDetailsGroupingType
>;
export type AnyDetailsGroupingDefinitionTextOverride = DetailsGroupingDefinitionTextOverride<
    Level,
    AnyModuleType,
    AnyDetailsType,
    AnyDetailsGroupingType
>;

export const definitionsByLevel: { [L in Level]: LevelDefinition<L> } = {
    BRANCH: {
        level: 'BRANCH',
        modules: {
            CALL_BY_RESPONSE_TIME: {
                type: 'CALL_BY_RESPONSE_TIME',
                label: 'Call by Response Time',
                sections: {
                    AVG_RESPONSE_TIME: {
                        type: 'AVG_RESPONSE_TIME',
                        label: 'Avg. Response Time',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'AVG_RESPONSE_TIME_BY',
                            detailsGroupingType: 'SHIFT',
                        },
                        formatSummary: ({ responseTimeMs }) => formatMsToMinutes(responseTimeMs),
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByResponseTime.sections.AvgResponseTime items={items} />
                        ),
                    },
                    AVG_RESPONSE_TIME_BY_CARE_STAFF: {
                        type: 'AVG_RESPONSE_TIME_BY_CARE_STAFF',
                        label: 'Avg. Response Time by Care Staff',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'AVG_RESPONSE_TIME_BY',
                            detailsGroupingType: 'CARE_STAFF',
                        },
                        renderData: (items) => (
                            <levels.Branch.modules.CallByResponseTime.sections.AvgResponseTimeByCareStaff
                                items={items}
                            />
                        ),
                    },
                    AVG_RESPONSE_TIME_BY_RESIDENT: {
                        type: 'AVG_RESPONSE_TIME_BY_RESIDENT',
                        label: 'Avg. Response Time by Resident',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'AVG_RESPONSE_TIME_BY',
                            detailsGroupingType: 'RESIDENT',
                        },
                        renderData: (items) => (
                            <levels.Branch.modules.CallByResponseTime.sections.AvgResponseTimeByResident
                                items={items}
                            />
                        ),
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        label: 'Longest Response Time',
                        caption: 'Potential quality assurance',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESPONSE_TIME',
                            detailsType: 'LONGEST_RESPONSE_TIME',
                            detailsGroupingType: 'ALL',
                        },
                        renderData: (items) => (
                            <levels.Branch.modules.CallByResponseTime.sections.LongestResponseTime items={items} />
                        ),
                    },
                },
                details: {
                    AVG_RESPONSE_TIME_BY: {
                        type: 'AVG_RESPONSE_TIME_BY',
                        label: 'Avg. Response Time by',
                        groupings: {
                            SHIFT: {
                                type: 'SHIFT',
                                label: 'Shift',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.Shift
                                        items={items}
                                    />
                                ),
                            },
                            DAYS: {
                                type: 'DAYS',
                                label: 'Days',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.Days
                                        items={items}
                                    />
                                ),
                            },
                            CARE_STAFF: {
                                type: 'CARE_STAFF',
                                label: 'Care Staff',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.CareStaff
                                        items={items}
                                    />
                                ),
                            },
                            RESIDENT: {
                                type: 'RESIDENT',
                                label: 'Resident',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResponseTime.details.AvgResponseTimeBy.Resident
                                        items={items}
                                    />
                                ),
                            },
                        },
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        label: 'Longest Response Time',
                        caption: 'Potential quality assurance',
                        groupings: {
                            ALL: {
                                type: 'ALL',
                                label: 'All',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResponseTime.details.LongestResponseTime.All
                                        items={items}
                                    />
                                ),
                            },
                        },
                    },
                },
            },
            CALL_BY_DETAIL: {
                type: 'CALL_BY_DETAIL',
                label: 'Call by Detail',
                sections: {
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        label: 'Total Calls',
                        formatSummary: ({ totalCount }) => totalCount,
                        renderData: (data) => <levels.Branch.modules.CallByDetail.sections.TotalCalls data={data} />,
                    },
                    EMERGENCY_CALLS: {
                        type: 'EMERGENCY_CALLS',
                        label: 'Emergency Calls',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_DETAIL',
                            detailsType: 'ALL',
                            detailsGroupingType: 'EMERGENCY',
                        },
                        formatSummary: ({ count }) => count,
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByDetail.sections.EmergencyCalls items={items} />
                        ),
                    },
                    NOT_FEELING_WELL_CALLS: {
                        type: 'NOT_FEELING_WELL_CALLS',
                        label: 'Not Feeling Well',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_DETAIL',
                            detailsType: 'ALL',
                            detailsGroupingType: 'NOT_FEELING_WELL',
                        },
                        formatSummary: ({ count }) => count,
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByDetail.sections.NotFeelingWellCalls items={items} />
                        ),
                    },
                },
                details: {
                    ALL: {
                        type: 'ALL',
                        label: 'Loading...',
                        groupings: {
                            EMERGENCY: {
                                type: 'EMERGENCY',
                                label: 'Emergency',
                                overrideDetailsLabel: (data) => `${data.length} calls`,
                                allowSearch: true,
                                searchPlaceholder: 'e.g. Mary, 2 person assist, Bathing, AM shift',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByDetail.details.All.Emergency items={items} />
                                ),
                            },
                            NOT_FEELING_WELL: {
                                type: 'NOT_FEELING_WELL',
                                label: 'Not Feeling Well',
                                overrideDetailsLabel: (data) => `${data.length} calls`,
                                allowSearch: true,
                                searchPlaceholder: 'e.g. Mary, 2 person assist, Bathing, AM shift',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByDetail.details.All.NotFeelingWell items={items} />
                                ),
                            },
                            OTHER: {
                                type: 'OTHER',
                                label: 'Other',
                                overrideDetailsLabel: (data) => `${data.length} calls`,
                                allowSearch: true,
                                searchPlaceholder: 'e.g. Mary, 2 person assist, Bathing, AM shift',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByDetail.details.All.Other items={items} />
                                ),
                            },
                        },
                    },
                },
            },
            CALL_BY_RESIDENT: {
                type: 'CALL_BY_RESIDENT',
                label: 'Call by Resident',
                sections: {
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        label: 'Total Calls',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESIDENT',
                            detailsType: 'ALL',
                            detailsGroupingType: 'ALL',
                        },
                        formatSummary: ({ totalCount }) => totalCount,
                        renderData: (data) => <levels.Branch.modules.CallByResident.sections.TotalCalls data={data} />,
                    },
                    RESIDENTS_WITH_NEEDS_CHANGES: {
                        type: 'RESIDENTS_WITH_NEEDS_CHANGES',
                        label: 'Residents with Needs Changes',
                        caption: 'Changes in call counts',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESIDENT',
                            detailsType: 'ALL',
                            detailsGroupingType: 'ANOMALY',
                        },
                        formatSummary: ({ count }) => count,
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByResident.sections.ResidentsWithNeedsChanges items={items} />
                        ),
                    },
                    POTENTIAL_CARE_LEVEL_INCREASE: {
                        type: 'POTENTIAL_CARE_LEVEL_INCREASE',
                        label: 'Potential Care Level Increase',
                        caption: 'Heavy hitters vs. low care level',
                        detailsPayload: {
                            level: 'BRANCH',
                            moduleType: 'CALL_BY_RESIDENT',
                            detailsType: 'ALL',
                            detailsGroupingType: 'CARE_INCREASE',
                        },
                        formatSummary: ({ count }) => count,
                        renderData: ({ items }) => (
                            <levels.Branch.modules.CallByResident.sections.PotentialCareLevelIncrease items={items} />
                        ),
                    },
                },
                details: {
                    ALL: {
                        type: 'ALL',
                        label: 'Loading...',
                        caption: 'Loading...',
                        groupings: {
                            HEAVY_HITTER: {
                                type: 'HEAVY_HITTER',
                                label: 'Heavy Hitter',
                                overrideDetailsLabel: 'Top 10 Residents by Call Count',
                                overrideDetailsCaption: ({ totalCount }) =>
                                    `These Residents called a total of ${totalCount} times`,
                                renderData: ({ items }) => (
                                    <levels.Branch.modules.CallByResident.details.All.HeavyHitter items={items} />
                                ),
                            },
                            ANOMALY: {
                                type: 'ANOMALY',
                                label: 'Anomalies',
                                overrideDetailsLabel: 'Residents with Needs Changes',
                                overrideDetailsCaption: 'Changes in call counts',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResident.details.All.Anomaly items={items} />
                                ),
                            },
                            CARE_INCREASE: {
                                type: 'CARE_INCREASE',
                                label: 'Care Level Increase',
                                overrideDetailsLabel: 'Potential Care Level Increase',
                                overrideDetailsCaption: 'Heavy hitters vs. low care level',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResident.details.All.CareIncrease items={items} />
                                ),
                            },
                            ALL: {
                                type: 'ALL',
                                label: 'All Residents',
                                overrideDetailsLabel: 'All Residents',
                                overrideDetailsCaption: 'Sorted by call count',
                                renderData: (items) => (
                                    <levels.Branch.modules.CallByResident.details.All.All items={items} />
                                ),
                            },
                        },
                    },
                },
            },
        },
    },
    REGION: {
        level: 'REGION',
        modules: {},
    },
    COMPANY: {
        level: 'COMPANY',
        modules: {},
    },
};
