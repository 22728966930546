import { atom } from 'jotai';

import { atomWithStorage, atomWithToggle } from '~/state/atomUtils';
import { ResidentResponse } from '~/types/residents';

import { ECall } from './types';

export const startDocumentationFlowAtom = atomWithToggle(false);

export const documentationResidentDataAtom = atom<Pick<
    ResidentResponse,
    'residentId' | 'firstName' | 'lastName' | 'photo'
> | null>(null);

export const isAssignmentsModalOpenAtom = atomWithToggle(false);
export const isDocumentationFlowModalOpenAtom = atomWithToggle(false);
export const isDocumentationFlowFinalMessageOpenAtom = atomWithToggle(false);

export const isOnCurrentShiftAtom = atomWithStorage('ecall_isOnCurrentShift', false);
export const nextShiftVerificationTimeAtom = atomWithStorage<string | null>('nextShiftValidation', null);

export const ecallSortAtom = atomWithStorage<ECall.SortingKey>('ecall_sort', 'callOrder');
