import { useCallback } from 'react';

import { api } from '~/api';
import { CompanyCarePlanResponse } from '~/types/companyCarePlans';
import { BaseResponse } from '~/types/shared';

import { useErrorHandledQuery, useToken } from '../common';

export const useCompanyCarePlansListQuery = (branchId?: number) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<CompanyCarePlanResponse[]>>(`/company-care-plans`, {
            headers: { Authorization: token },
            params: { branch_id: branchId },
        });

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['company-care-plans-list'],
        queryFn,
        enabled: !!branchId,
        staleTime: 1800000,
    });
};
