import CareStaff from './CareStaff';
import Days from './Days';
import Resident from './Resident';
import Shift from './Shift';

export default {
    Shift,
    Days,
    CareStaff,
    Resident,
};
