import { atom } from 'jotai';
import { DateTime } from 'luxon';

import { SHIFT_SLOT_CHANGE_REASON } from '@allie/utils/src/constants/scheduling/shift-slot.constants';

import { atomWithStorage, atomWithToggle, guardAtom } from '~/state/atomUtils';

import { SELECTOR_ALL_ID } from '../../../../constants';

import { SchedulePeriod } from './types';

export const selectedCareTypeIdAtom = atomWithStorage('scheduling_Schedule_selectedCareTypeId', SELECTOR_ALL_ID);
export const selectedPeriodAtom = atomWithStorage<SchedulePeriod>('scheduling_Schedule_selectedPeriod', 'week');

export const addShiftSelectedDayAtom = atom(DateTime.now());
export const addShiftSelectedShiftIdAtom = atom<number | null>(null);
export const addShiftSelectedRoleIdAtom = atom<number | null>(null);
export const addShiftSelectedCareTypeIdAtom = atom<number | null>(null);
export const isAddShiftModalOpenAtom = atomWithToggle(false, (_, set) => {
    set(addShiftSelectedDayAtom, DateTime.now());
    set(addShiftSelectedShiftIdAtom, null);
    set(addShiftSelectedRoleIdAtom, null);
    set(addShiftSelectedCareTypeIdAtom, null);
});

export const addTraineeModalSlotIdAtom = atom<number | null>(null);
export const addTraineeIdAtom = atom<number | null>(null);
export const isAddTraineeModalOpenAtom = guardAtom(addTraineeModalSlotIdAtom, (set) => set(addTraineeIdAtom, null));

export const changeStaffModalSlotIdAtom = atom<number | null>(null);
export const changeStaffSelectedReasonAtom = atom<SHIFT_SLOT_CHANGE_REASON | null>(null);
export const isChangeStaffModalOpenAtom = guardAtom(changeStaffModalSlotIdAtom, (set) =>
    set(changeStaffSelectedReasonAtom, null)
);
