import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo } from 'react';

import { useGetCategoriesDetailsQuery } from '~/api/queries/unscheduledTasks/getCategoriesDetails';

import {
    unscheduledTaskCategoriesAtom,
    unscheduledTaskDetailsAtom,
    unscheduledTaskResidentAtom,
} from '../../../state/atom';
import withIcon from '../utils/withIcon';

export default function useTaskDetailsStep(prnCategoryId: number) {
    const categories = useAtomValue(unscheduledTaskCategoriesAtom);
    const [taskDetails, setTaskDetails] = useAtom(unscheduledTaskDetailsAtom);
    const residentData = useAtomValue(unscheduledTaskResidentAtom);

    const { data, isLoading, error } = useGetCategoriesDetailsQuery({
        categoriesIds: categories.map((category) => category.id),
        residentId: residentData?.residentId,
    });

    const categoryDetails = useMemo(() => {
        const category = data?.find((category) => category.id === prnCategoryId);

        if (!category) return;

        return {
            ...category,
            subcategories: category?.subcategories.map((subcategory) => ({
                ...subcategory,
                assistLevels: subcategory.assistLevels.map(withIcon),
            })),
        };
    }, [data, prnCategoryId]);

    const categoryTaskDetails = taskDetails.find((e) => e.categoryId === prnCategoryId);

    useEffect(() => {
        if (!categoryTaskDetails && categoryDetails) {
            setTaskDetails([
                ...taskDetails,
                {
                    categoryId: categoryDetails.id,
                    categoryCode: categories.find((category) => category.id === prnCategoryId)?.code,
                    assistLevelId: categoryDetails?.possibleCarePlan?.prnAssistLevelId,
                    activitiesIds: [],
                    subcategoryId: categoryDetails?.possibleCarePlan?.prnSubcategoryId,
                    activitiesRequired: categoryDetails?.subcategories
                        .find((e) => e.id === categoryDetails?.possibleCarePlan?.prnSubcategoryId)
                        ?.assistLevels.find((e) => e.id === categoryDetails?.possibleCarePlan?.prnAssistLevelId)
                        ?.activitiesRequired,
                },
            ]);
        }
    }, [categories, taskDetails]);

    return {
        data: categoryDetails,
        isLoading,
        error,
        taskDetails,
        setTaskDetails,
    };
}
