import { api } from '~/api';
import { BranchResponse } from '~/types/branches';

import { useErrorHandledQuery, useToken } from '../common';

export const useBranchQuery = (branchId?: number) => {
    const token = useToken();

    const queryFn = async () => {
        const { data } = await api.get<{ response: BranchResponse }>(`/branches/${branchId}`, {
            headers: {
                authorization: token,
            },
            params: { branch_id: branchId }, // For permission checking
        });

        return data.response;
    };

    return useErrorHandledQuery({
        queryKey: ['branch', branchId],
        queryFn,
        enabled: !!branchId,
        staleTime: 300000,
    });
};

export const useBranchShifts = (branchId?: number) => {
    const { data } = useBranchQuery(branchId);

    return data?.shifts ?? [];
};
