import { AxiosError } from 'axios';

import { api } from '~/api';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import actionTypes from '~/redux/actionTypes';
import '~/redux/reducers/zones';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { BaseResponse } from '~/types/shared';
import { ZoneResponse, ZonesListReadParams } from '~/types/zones';

/**
 * Fetches the list of Zones based on a Company Branch.
 * @param jsonParams the JSON object with the params for fetching the Zones list.
 */
export const readZones =
    ({ branchId }: ZonesListReadParams, actionType = actionTypes.ZONE_READ_LIST) =>
    async (dispatch: AppDispatch, getState: () => ReduxStore) => {
        try {
            // Get the token.
            const { token } = getState().session.sessionData;

            // Fetch the Zones List.
            const { data } = await api.get<BaseResponse<ZoneResponse[]>>('/zones', {
                headers: { authorization: token },
                params: { branch_id: branchId },
            });
            const zonesList = data.response;

            dispatch({
                type: actionType,
                payload: zonesList,
            });
        } catch (err) {
            const error = err as AxiosError;

            showToast({
                message: error.message,
                type: 'error',
            });
        }
    };
