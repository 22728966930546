import { Box, alpha } from '@mui/material';
import { Stack, styled, useTheme } from '@mui/material';
import React, { ComponentProps, useMemo } from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';

const ROW_PADDING_Y_PX = 20;

const Divider = styled(Box)(({ theme: { palette } }) => ({
    height: 1,
    backgroundColor: alpha(palette.grey[900], 0.5),
}));

type DataLegendProps = {
    rows: { label: string; value: string | number }[];
    containerProps?: ComponentProps<typeof Stack>;
};

const DataLegend = ({ rows, containerProps }: DataLegendProps) => {
    const { palette } = useTheme();

    const colorByIndex: Record<number, string> = useMemo(
        () => ({
            0: palette.primary[500] as string,
            1: palette.primary[300] as string,
        }),
        [palette]
    );

    return (
        <Stack {...containerProps} divider={<Divider />}>
            {rows.map(({ label, value }, rowIndex) => (
                <Stack
                    key={label}
                    sx={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%',
                        p:
                            rowIndex === 0
                                ? `0 0 ${ROW_PADDING_Y_PX}px`
                                : rowIndex === rows.length - 1
                                  ? `${ROW_PADDING_Y_PX}px 0 0`
                                  : `${ROW_PADDING_Y_PX}px 0`,
                        gap: '8px',
                    }}
                >
                    <Box
                        sx={{
                            bgcolor: colorByIndex[rowIndex],
                            width: '16px',
                            height: '16px',
                            borderRadius: '2px',
                        }}
                    />
                    <SingleLineTypography>{label}</SingleLineTypography>
                    <SingleLineTypography ml="auto">{value}</SingleLineTypography>
                </Stack>
            ))}
        </Stack>
    );
};

export default DataLegend;
