import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useErrorHandledMutation, useErrorHandledQuery, useToken } from '~/api/common';
import { SessionData } from '~/types/session';
import { BaseResponse } from '~/types/shared';

export const useLoginInitMutation = () => {
    const mutationFn = useCallback(async (params: { phoneNumber: string }) => {
        await api.post(`/sessions/otp/init`, params);

        return params.phoneNumber;
    }, []);

    return useErrorHandledMutation({ mutationFn });
};

export const useLoginVerifyMutation = () => {
    const mutationFn = useCallback(async (params: { phoneNumber: string; code: string }) => {
        const { data } = await api.post<BaseResponse<SessionData>>(`/sessions/otp/verify`, params);

        return data.response;
    }, []);

    return useErrorHandledMutation({ mutationFn });
};

type LoginUsernameMutationParams = {
    onSuccess?: () => void;
    onError?: () => void;
};

export const useLoginUsernameMutation = ({ onSuccess, onError }: LoginUsernameMutationParams) => {
    const mutationFn = useCallback(async (params: { username: string; password: string; phoneNumber: string }) => {
        const { data } = await api.post<BaseResponse<SessionData>>(`/sessions`, params);

        return data.response;
    }, []);

    return useErrorHandledMutation({
        mutationFn,
        onSuccess,
        onError,
    });
};

export const useCommunityCodeVerifyMutation = () => {
    const token = useToken();

    const mutationFn = useCallback(
        async (params: { setupCode: string; latitude: number; longitude: number }) => {
            await api.post(`/users/join-branch`, params, {
                headers: { authorization: token },
            });
        },
        [token]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['session'] });
        await queryClient.invalidateQueries({ queryKey: ['permissions'] });
    }, []);

    return useErrorHandledMutation({
        mutationFn,
        onSuccess,
    });
};

export const useSessionData = () => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<SessionData>>(`/sessions`, {
            headers: { authorization: token },
        });

        return data.response;
    }, [token]);

    return useErrorHandledQuery({ queryKey: ['session'], queryFn, staleTime: 300000 });
};
