import { Stack, Typography } from '@mui/material';
import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';
import NewResidentChip from '~/pages/OperationsV2/components/Module/Section/NewResidentChip';

type Item = SectionDataType<'BRANCH', 'CALL_BY_RESIDENT', 'POTENTIAL_CARE_LEVEL_INCREASE'>['items'][number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                    weight: 2,
                    renderCell: (value, { row }) => (
                        <Stack
                            sx={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: '8px',
                            }}
                        >
                            <Typography>{value}</Typography>
                            {row.isNewResident && <NewResidentChip />}
                        </Stack>
                    ),
                },
                callCount: {
                    label: 'Call Count',
                    alignment: 'right',
                },
                careLevel: {
                    label: 'Care Level',
                    alignment: 'right',
                },
            }}
            rows={items}
            takeFirst={3}
            seeMore={() => {}}
            containerProps={{ width: '100%' }}
        />
    </DataContainer>
);

export default Data;
