import { Box } from '@mui/material';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router';

import { CustomAvatar } from '~/components/Custom';

import { openedResidentIdAtom } from '../../atom';

const PHOTO_WIDTH = 64;

type Props = {
    photo: string;
    firstName: string;
    lastName: string;
    residentId: number;
};

export const ResidentPhoto = (props: Props) => {
    const { firstName, lastName, photo, residentId } = props;
    const setOpenedResidentId = useSetAtom(openedResidentIdAtom);
    const navigate = useNavigate();

    const goToResidentDetails = (e: React.MouseEvent) => {
        e.stopPropagation();
        setOpenedResidentId(null);
        navigate(`/residents/${residentId}`);
    };

    return (
        <Box onClick={goToResidentDetails} sx={{ width: PHOTO_WIDTH + 'px' }}>
            <CustomAvatar firstName={firstName} lastName={lastName} photo={photo} size={PHOTO_WIDTH} />
        </Box>
    );
};
