import { atom } from 'jotai';

import { guardAtom } from '~/state/atomUtils';

import { ChangeStaffReasonOption } from '../../../../shared/changeStaffReasonOptions';

export const reasonSheetSlotIdAtom = atom<number | null>(null);
export const reasonSheetSelectedReasonAtom = atom<ChangeStaffReasonOption | null>(null);
export const isReasonSheetOpenAtom = guardAtom(reasonSheetSlotIdAtom, (set) =>
    set(reasonSheetSelectedReasonAtom, null)
);

export const notesSheetSlotIdAtom = atom<number | null>(null);
export const notesSheetSelectedReasonAtom = atom<ChangeStaffReasonOption | null>(null);
export const notesSheetNotesAtom = atom<string | null>(null);
export const isNotesSheetOpenAtom = guardAtom(notesSheetSlotIdAtom, (set) => {
    set(notesSheetSelectedReasonAtom, null);
    set(notesSheetNotesAtom, null);
});
