import { useCallback } from 'react';

import { api } from '~/api';
import { DailyResidentTasksResponse, DailyTasksUpdateParams, TaskToUpdate } from '~/types/dailyTasks';
import { ResidentDailyTasksResponse, ResidentResponse, ResidentsListResponse } from '~/types/residents';
import { BaseResponse } from '~/types/shared';

import { queryClient, useBranchId, useErrorHandledMutation, useErrorHandledQuery, useToken } from '../common';

// Queries the list of Residents based on branch ID.
export const useResidentsQuery = (branchId?: number) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: { branch_id: branchId },
        };

        const { data } = await api.get<BaseResponse<ResidentsListResponse[]>>('/residents', options);

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['residents', branchId],
        queryFn,
        enabled: !!branchId,
        staleTime: 300000,
    });
};

// Queries a Resident based on resident ID.
export const useResidentQuery = (residentId: number, branchId?: number) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: { branch_id: branchId }, // For permission checking
        };

        const { data } = await api.get<BaseResponse<ResidentResponse>>(`/residents/${residentId}`, options);

        return data.response;
    }, [branchId, residentId, token]);

    return useErrorHandledQuery({
        queryKey: ['resident', residentId],
        queryFn,
        enabled: !!residentId && !!branchId,
        staleTime: 300000,
    });
};

const processResidentDailyTasks = (residentDailyTasks: ResidentDailyTasksResponse[]) => ({
    ...residentDailyTasks,
    pendingTaskRecords: residentDailyTasks.filter((task) => task.taskStatus === 'Undocumented'),
    confirmedTaskRecords: residentDailyTasks.filter((task) => task.taskStatus !== 'Undocumented'),
});

// Queries the list of Resident Daily Tasks based on resident ID.
export const useResidentDailyTasksQuery = (residentId?: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<DailyResidentTasksResponse>(`/tasks/resident/${residentId}`, {
            headers: { authorization: token },
            params: { branch_id: branchId }, // For permission checking
        });

        const { pendingTasks, response: residentDailyTasks, totalRecords: totalTasks } = data;

        return {
            pendingTasks,
            totalTasks,
            ...processResidentDailyTasks(residentDailyTasks),
        };
    }, [branchId, residentId, token]);

    return useErrorHandledQuery({
        queryKey: ['residentDailyTasks', residentId],
        queryFn,
        enabled: !!residentId && !!branchId,
        staleTime: 300000,
    });
};

export const useResidentDailyTaskMutation = () => {
    const token = useToken();

    const mutationFn = useCallback(
        async (params: {
            taskId: number;
            residentId: number;
            branchId: number;
            jsonParams: DailyTasksUpdateParams;
        }) => {
            const { taskId, jsonParams } = params;
            const task: TaskToUpdate = {
                taskId,
                taskStatusId: jsonParams.taskStatusId,
                caregiverNotes: jsonParams.caregiverNotes || null,
            };
            await api.put(`/tasks`, [task], {
                headers: { authorization: token },
                params: { branch_id: params.branchId },
            });
        },
        [token]
    );

    const onSuccess = useCallback(
        async (
            _data: never,
            variables: {
                taskId: number;
                residentId: number;
                branchId: number;
                jsonParams: DailyTasksUpdateParams;
            }
        ) => {
            const { residentId } = variables;

            await queryClient.invalidateQueries({
                queryKey: ['residentDailyTasks', residentId],
                exact: false,
                refetchType: 'active',
            });
            await queryClient.invalidateQueries({
                queryKey: ['resident', residentId],
                exact: false,
                refetchType: 'active',
            });
            await queryClient.invalidateQueries({
                queryKey: ['residents'],
                exact: false,
                refetchType: 'active',
            });
            await queryClient.invalidateQueries({
                queryKey: ['dailyTasks'],
                exact: false,
                refetchType: 'active',
            });
            await queryClient.invalidateQueries({
                queryKey: ['documentation-actions'],
                exact: false,
                refetchType: 'active',
            });
        },
        []
    );

    return useErrorHandledMutation({
        mutationFn,
        onSuccess,
        retry: false,
    });
};
