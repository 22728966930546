import { Box, Typography, styled } from '@mui/material';
import React from 'react';

import { CustomAvatar } from '~/components/Custom';
import { ResidentsListResponse } from '~/types/residents';

type Props = {
    resident: ResidentsListResponse;
    selected: boolean;
    handleSelect: (resident: ResidentsListResponse) => void;
};

const RowStyle = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    padding: '16px',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    width: '100%',
    gap: '8px',
}));

const Label = styled(Typography)(() => ({
    fontSize: '14px',
    textAlign: 'left',
    textWrap: 'balance',
}));

export default function ResidentRowItem({ resident, selected, handleSelect }: Readonly<Props>) {
    const residentName = `${resident.firstName} ${resident.lastName}`;

    return (
        <RowStyle
            onClick={() => handleSelect(resident)}
            key={resident.residentId}
            sx={{
                backgroundColor: ({ palette }) => (selected ? (palette.primary[50] as string) : 'white'),
            }}
        >
            <Box display="flex" gap="8px" flex={1} alignItems="center">
                <CustomAvatar photo={resident.photo} fullName={residentName} size={40} />
                <Label fontWeight="700">{residentName}</Label>
            </Box>
            <Label variant="body1" color={({ palette }) => palette.secondary[500] as string}>
                Apt {resident.roomNumber}
            </Label>
        </RowStyle>
    );
}
