import Module from '.';
import { Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import React from 'react';

import { selectedLevelAtom } from '../../atoms';
import { useOperations } from '../../hooks/useOperations';
import { AnyModuleDefinition, definitionsByLevel } from '../../modules';
import { AnyModuleData, AnySectionData } from '../../testData';

const Modules = () => {
    const level = useAtomValue(selectedLevelAtom);
    const { data } = useOperations({ level });

    if (!data) return null;

    const modules = Object.values(data.modules) as unknown as AnyModuleData[];
    const moduleDefinitions = definitionsByLevel[level].modules;

    return (
        <Stack sx={{ height: '100%', overflowY: 'auto' }}>
            {modules.map((module) => (
                <Module
                    key={module.type}
                    sectionsData={Object.values(module.sections) as unknown as AnySectionData[]}
                    moduleDefinition={moduleDefinitions[module.type] as AnyModuleDefinition}
                />
            ))}
        </Stack>
    );
};

export default Modules;
