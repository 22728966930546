import { useCallback } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledMutation, useToken } from '~/api/common';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { BaseResponse } from '~/types/shared';

export const useAudioTranscribeMutation = () => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async (form: FormData): Promise<string> => {
            const options = {
                params: { branch_id: branchId },
                headers: { authorization: token },
            };

            const { data } = await api.post<BaseResponse<{ text: string }>>(
                `/resident-shift-notes/transcribe-audio`,
                form,
                options
            );

            return data.response.text;
        },
        [branchId, token]
    );

    const onError = useCallback((error: unknown) => {
        showToast({
            type: 'error',
            message: 'There was an error while transcribing your message. Please try again',
        });

        // Rethrow the error for react-query to handle that state
        throw error;
    }, []);

    return useErrorHandledMutation({ mutationFn, onError, showToast: false });
};
