import { useCallback } from 'react';

import { api } from '~/api';

import { useErrorHandledMutation } from '../common';

type EmailParams = {
    userId: number;
    workflowName: string;
    emailId: string;
    token: string;
};

export const useEmailUnsubscribeMutation = () => {
    const mutationFn = useCallback(async (params: EmailParams) => {
        await api.post('/user-subscription/email-unsubscribe', params);
    }, []);

    return useErrorHandledMutation({ mutationFn });
};

export const useEmailSubscribeMutation = () => {
    const mutationFn = useCallback(async (params: EmailParams) => {
        await api.post('/user-subscription/email-subscribe', params);
    }, []);

    return useErrorHandledMutation({ mutationFn });
};
