import { Stack } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';

import PageStructure from '~/pages/PageStructure';
import { ReduxStore } from '~/types/redux';

import BadgesSection from './home/BadgesSection';
import LastShiftPointsSummary from './home/LastShiftPointsSummary';
import RedeemSection from './home/RedeemSection';
import ThanksSection from './home/ThanksSection';

type Props = {
    userId: number;
};

const MyRewards = ({ userId }: Props) => (
    <PageStructure sx={{ p: '16px' }}>
        <Stack spacing="16px">
            <RedeemSection userId={userId} />
            <LastShiftPointsSummary userId={userId} />
            <BadgesSection userId={userId} />
            <ThanksSection userId={userId} />
        </Stack>
    </PageStructure>
);

const mapStateToProps = ({ session }: ReduxStore) => {
    const {
        sessionData: { userId },
    } = session;

    return {
        userId,
    };
};

export default connect(mapStateToProps)(MyRewards);
