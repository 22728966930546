import All from './details/All';
import EmergencyCalls from './sections/EmergencyCalls';
import NotFeelingWellCalls from './sections/NotFeelingWellCalls';
import TotalCalls from './sections/TotalCalls';

export default {
    sections: {
        TotalCalls,
        EmergencyCalls,
        NotFeelingWellCalls,
    },
    details: {
        All,
    },
};
