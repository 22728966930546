import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { PiBellSlash, PiConfetti } from 'react-icons/pi';

import { useServerSentEvents } from '~/api/events/useEventSource';
import { Placeholder } from '~/components/Shared/Placeholder';

import { isDocumentationFlowModalOpenAtom, isOnCurrentShiftAtom } from './atom';
import { CallCard } from './call';
import { DocumentationFlow } from './documentation/DocumentationFlow';
import { NotificationFlow } from './notification/NotificationFlow';
import { useCalls } from './useCalls';

export const LiveCalls = () => {
    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);
    const [isDocumentationFlowModalOpen, toggle] = useAtom(isDocumentationFlowModalOpenAtom);

    const { calls } = useCalls();
    useServerSentEvents();

    return (
        <>
            {!isOnCurrentShift ? (
                <Placeholder
                    icon={PiBellSlash}
                    type="secondary"
                    title="Notifications are off"
                    description="Looks like you're off shift. We've silenced your live calls."
                />
            ) : (
                <React.Fragment>
                    {!calls.length && (
                        <Placeholder
                            icon={PiConfetti}
                            type="secondary"
                            title="No calls"
                            description="Everyone's taken care of, amazing! This team is so on top of things."
                        />
                    )}

                    {calls.map((call) => (
                        <CallCard key={call.id} {...call} />
                    ))}
                </React.Fragment>
            )}

            <DocumentationFlow isOpen={isDocumentationFlowModalOpen} onClose={toggle} />
            <NotificationFlow />
        </>
    );
};
