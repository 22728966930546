import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataTableNumberedCell from '~/pages/OperationsV2/components/Module/DataTableNumberedCell';
import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'BRANCH', 'CALL_BY_RESPONSE_TIME', 'AVG_RESPONSE_TIME_BY_RESIDENT'>[number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                    weight: 2,
                    renderCell: (value, { rowIndex }) => <DataTableNumberedCell index={rowIndex + 1} value={value} />,
                },
                responseTimeMs: {
                    label: 'Response Time',
                    alignment: 'right',
                    weight: 1.5,
                    renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                },
                callCount: {
                    label: 'Call Count',
                    alignment: 'right',
                },
            }}
            rows={items}
            takeFirst={3}
            takeLast={3}
            containerProps={{ width: '100%' }}
        />
    </DataContainer>
);

export default Data;
