import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';

const DataTableNumberedCell = ({ index, value }: { index: number; value: string }) => (
    <Stack direction="row" alignItems="center" spacing="8px">
        <Box
            sx={({ palette }) => ({
                bgcolor: palette.primary[500] as string,
                flexShrink: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '20px',
                height: '20px',
                borderRadius: '50%',
            })}
        >
            <Typography color="white" variant="caption" fontWeight={700}>
                {index}
            </Typography>
        </Box>
        <SingleLineTypography>{value}</SingleLineTypography>
    </Stack>
);

export default DataTableNumberedCell;
