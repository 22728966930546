export const formatMsToMinutes = (ms: number) => {
    const minutes = Math.floor(ms / 60000);
    return `${minutes} min`; // e.g. 300000 -> 5 min
};

export const formatPercentage = (
    value: number,
    options: { showSign?: 'always' | 'only-negative' | 'never'; signSeparator?: string } = {}
) => {
    const { showSign = 'only-negative', signSeparator = '' } = options;

    let sign: string;

    switch (showSign) {
        case 'always':
            sign = value >= 0 ? `+${signSeparator}` : `-${signSeparator}`;
            break;
        case 'only-negative':
            sign = value < 0 ? `-${signSeparator}` : '';
            break;
        case 'never':
            sign = '';
            break;
    }

    return `${sign}${(Math.abs(value) * 100).toFixed(0)}%`; // e.g. -0.1234 -> -12%
};
