import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';

import { PRN_CATEGORY_SECTIONS, PRN_TASK_CATEGORY_CODES } from '@allie/utils/src/constants/prn-tasks.constants';

import { unscheduledTaskCategoriesAtom } from '../state/atom';
import { UnscheduledTaskDialogSteps } from '../types/unscheduledTaskDialogSteps';

import { useDuplicateSteps } from './useDuplicateSteps';

export const useEmergencyFlow = () => {
    const { duplicateStepCounter } = useDuplicateSteps();

    const categories = useAtomValue(unscheduledTaskCategoriesAtom);

    const isEmergencyStep = useCallback(
        (step: UnscheduledTaskDialogSteps) => {
            if (step !== UnscheduledTaskDialogSteps.SELECT_TASK_DETAILS) return false;

            const currentCategory = categories.filter((category) => category.section !== PRN_CATEGORY_SECTIONS.OTHER)[
                duplicateStepCounter
            ];

            return currentCategory?.code === PRN_TASK_CATEGORY_CODES.EMERGENCY;
        },
        [duplicateStepCounter, categories]
    );

    const hasEmergencyCategory = useMemo(
        () => categories.some((category) => category.code === PRN_TASK_CATEGORY_CODES.EMERGENCY),
        [categories]
    );

    const isEmergencyFlow = useMemo(
        () => hasEmergencyCategory && categories.length === 1,
        [hasEmergencyCategory, categories]
    );

    return { isEmergencyStep, isEmergencyFlow, hasEmergencyCategory };
};
