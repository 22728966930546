import React, { useMemo, useState } from 'react';

import WithHeader from '~/components/Layout/WithHeader';
import { useWindowSize } from '~/hooks/useWindowSize';

import FilledSlotModal from './components/Modal/FilledSlotModal';
import MoveSlotModal from './components/Modal/MoveSlotModal';
import ReplaceSlotModal from './components/Modal/ReplaceSlotModal';
import WeekCalendarHeader from './components/WeekCalendarHeader';
import WeekGrid from './components/WeekGrid';

const Week = () => {
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);

    const [windowSize] = useWindowSize();

    const scrollBarWidth = useMemo(() => {
        if (!ref || !gridRef) return 0;
        return ref.clientWidth - gridRef.clientWidth;
    }, [ref, gridRef, windowSize]);

    return (
        <WithHeader ref={setRef} header={<WeekCalendarHeader scrollBarWidth={scrollBarWidth} />}>
            <WeekGrid ref={setGridRef} />

            <FilledSlotModal />
            <MoveSlotModal />
            <ReplaceSlotModal />
        </WithHeader>
    );
};

export default Week;
