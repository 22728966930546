/* eslint-disable react/jsx-key */
import { Icon, IconBase, IconWeight } from '@phosphor-icons/react';
import React from 'react';

const weights = new Map<IconWeight, React.ReactElement>([
    [
        'regular',
        <path d="M 230.33 141.06 a 24.43 24.43 0 0 0 -21.24 -4.23 l -41.84 9.62 A 28 28 0 0 0 140 112 H 89.94 a 31.82 31.82 0 0 0 -22.63 9.37 L 44.69 144 H 16 A 16 16 0 0 0 0 160 v 40 a 16 16 0 0 0 16 16 H 120 a 7.93 7.93 0 0 0 1.94 -0.24 l 64 -16 a 7.35 7.35 0 0 0 1.2 -0.4 L 226 182.82 l 0.44 -0.2 a 24.6 24.6 0 0 0 3.93 -41.56 Z M 40 200 H 16 V 160 H 40 Z m 179.43 -31.79 l -38 16.18 L 119 200 H 56 V 155.31 l 22.63 -22.62 A 15.86 15.86 0 0 1 89.94 128 H 140 a 12 12 0 0 1 0 24 H 112 a 8 8 0 0 0 0 16 h 32 a 8.32 8.32 0 0 0 1.79 -0.2 l 67 -15.41 l 0.31 -0.08 z" />,
    ],
]);

const HandServices: Icon = React.forwardRef((props, ref) => <IconBase ref={ref} {...props} weights={weights} />);

HandServices.displayName = 'HandServices';

export default HandServices;
