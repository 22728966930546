import { Button } from '@mui/material';
import { Plus } from '@phosphor-icons/react';
import React from 'react';

const AddNotesButton = () => (
    <Button
        color="secondary"
        startIcon={<Plus />}
        sx={{
            '& svg': {
                fontSize: '16px !important',
            },
        }}
    >
        <span style={{ color: 'black' }}>Add Notes</span>
    </Button>
);

export default AddNotesButton;
