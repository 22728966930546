import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import { ExportTaskRecordsCsvParams } from '~/types/dailyTasks';

export const useExportTaskRecordsCsvQuery = ({ type, ...params }: ExportTaskRecordsCsvParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<ArrayBuffer>(`/tasks/export-csv/${type}`, {
            headers: { Authorization: token },
            params: {
                branch_id: params.branchId,
                start_date: params.startDate,
                end_date: params.endDate,
                resident_id: params.residentId,
            },
            responseType: 'blob',
        });
        return data;
    }, [params, token, type]);

    return useErrorHandledQuery({
        queryKey: ['export-task-records-csv', params],
        queryFn,
        enabled: false,
    });
};
