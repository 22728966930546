import { AxiosError } from 'axios';

import { api } from '~/api';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import actionTypes from '~/redux/actionTypes';
import '~/redux/reducers/users';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { BaseResponse } from '~/types/shared';
import { UserResponse, UsersReadParams } from '~/types/users';

/**
 * Fetches the list of Users based on the provided params.
 * @param jsonParams the JSON object with the params for fetching the Users list.
 */
export const readUsers =
    (jsonParams?: UsersReadParams, actionType = actionTypes.USER_READ_LIST) =>
    async (dispatch: AppDispatch, getState: () => ReduxStore) => {
        try {
            // Get the token.
            const { token } = getState().session.sessionData;

            // Get the list of the Users that match with the provided params.
            const { data } = await api.get<BaseResponse<UserResponse[]>>('users/all', {
                headers: { authorization: token },
                params: jsonParams
                    ? {
                          company_id: jsonParams?.companyId,
                          branch_id: jsonParams?.branchId,
                      }
                    : undefined,
            });
            const users = data.response;

            // Updates the State in Redux.
            dispatch({
                type: actionType,
                payload: users,
            });
        } catch (err) {
            showToast({
                message: 'There was an error while performing this action. Please try again',
                type: 'error',
            });
        }
    };

/**
 * Fetches current user.
 */
export const readUser = () => async (dispatch: AppDispatch, getState: () => ReduxStore) => {
    try {
        // Get the token.
        const { token } = getState().session.sessionData;

        // Get the data for the requested user.
        const { data } = await api.get<BaseResponse<UserResponse>>('users', {
            headers: { authorization: token },
        });
        const user = data.response;

        // Updates the State in Redux.
        dispatch({
            type: actionTypes.USER_READ,
            payload: user,
        });
    } catch (err) {
        const error = err as AxiosError<{ code: number; short: string }>;
        const errorCode = error.response?.data.code;
        const errorShort = error.response?.data.short;
        const errorMessage =
            errorCode === 400 && errorShort === 'missing'
                ? 'You need to provide a User ID.'
                : 'The Account you provided does not exist. Please check and try again.';

        showToast({
            message: errorMessage,
            type: 'error',
        });
    }
};
