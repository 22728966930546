import React from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Section/DataTable';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = SectionDataType<'BRANCH', 'CALL_BY_RESPONSE_TIME', 'LONGEST_RESPONSE_TIME'>[number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                    weight: 1.5,
                },
                staffName: {
                    label: 'Care Staff',
                    weight: 1.5,
                },
                responseTimeMs: {
                    label: 'Response Time',
                    alignment: 'right',
                    renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                },
            }}
            rows={items}
            containerProps={{ width: '100%' }}
        />
    </DataContainer>
);

export default Data;
