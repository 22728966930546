import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import * as Sentry from '@sentry/react';
import posthog from 'posthog-js';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { api } from '~/api';
import { useErrorHandledMutation, useToken } from '~/api/common';
import { HOME_FILTERS, PROFILE, PUSH_NOTIFICATION_TOKEN_LAST_UPDATED } from '~/constants/localStorage';
import actionTypes from '~/redux/actionTypes';
import { SessionData, StoredSession } from '~/types/session';

export const useSignOut = () => {
    const token = useToken();
    const dispatch = useDispatch();

    const destroyLocalSession = useCallback(() => {
        const profile: StoredSession = {
            isSignedIn: false,
            sessionData: {} as SessionData,
        };

        localStorage.removeItem(PROFILE);
        localStorage.removeItem(HOME_FILTERS);
        localStorage.removeItem(PUSH_NOTIFICATION_TOKEN_LAST_UPDATED);

        dispatch({
            type: actionTypes.SESSION_SIGN_OUT,
            payload: profile,
        });

        Sentry.setUser(null);
        posthog.reset();
    }, [dispatch]);

    const mutationFn = useCallback(async () => {
        const identifier = Capacitor.isNativePlatform() ? (await Device.getId()).identifier : undefined;

        destroyLocalSession();

        await api.delete('sessions', {
            headers: {
                authorization: token,
                'device-id': identifier,
            },
        });
    }, [token]);

    return useErrorHandledMutation({ mutationFn });
};
