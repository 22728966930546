import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useSelector } from 'react-redux';

import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { useAppDispatch } from '~/constants/redux';
import { signOut } from '~/redux/actions/session';
import { ReduxStore } from '~/types/redux';

const useHandleError = () => {
    const { isSignedIn } = useSelector((state: ReduxStore) => state.session);
    const dispatch = useAppDispatch();

    return (_error: unknown) => {
        const error = _error as AxiosError<{ error: string }>;

        const message =
            error.response?.data?.error || // AxiosError<{ error: string }> with standardized error field
            error.message || // Known client/network Error (with message)
            'An internal error occurred'; // Other unexpected/unknown Error (without message)

        showToast({
            message,
            type: 'error',
        });

        // Avoid "could not load your permissions" and similar pages.
        // Login pages also throw 401s, so no need to sign out in that case
        if (isSignedIn && error.response?.status === 401) void dispatch(signOut());

        // Rethrow the error for react-query to handle that state
        throw error;
    };
};

export const useErrorHandledQuery = <T>({ queryFn: _queryFn, ...options }: UseQueryOptions<T>) => {
    const handleError = useHandleError();

    // Intercept the error and show an alert
    const queryFn = () => (_queryFn as () => Promise<T>)().catch(handleError);

    return useQuery({ queryFn, retry: false, ...options });
};

export const useErrorHandledMutation = <TVariables, TData>({
    onError: _onError,
    showToast,
    ...options
}: UseMutationOptions<TData, unknown, TVariables> & { showToast?: false }) => {
    const handleError = useHandleError();

    // Extend the error and show an alert
    const onError = (_error: unknown, variables: TVariables, context: unknown) => {
        _onError?.(_error, variables, context);

        if (showToast === false) return;

        handleError(_error);
    };

    return useMutation({ onError, retry: false, ...options });
};
