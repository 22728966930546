import { useCallback } from 'react';

import { api } from '~/api';

import { useErrorHandledQuery } from '../common';

export const useLiveUpdateTimeoutThresholdQuery = (enabled?: boolean) => {
    const queryFn = useCallback(async () => {
        const { data } = await api.get<{ response: { timeout: number } }>(`/live-update/timeout-threshold`);

        return data.response;
    }, []);

    return useErrorHandledQuery({
        queryKey: ['live-update'],
        queryFn,
        enabled,
        staleTime: 30000,
    });
};
