import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

import { isOnCurrentShiftAtom } from '~/pages/Home/eCallComponents/atom';

import { queryClient, useBranchId, useToken } from '../common';

export type EventType = {
    event: string;
    data: { timestamp: string };
};

const MILLISECONDS_TO_RETRY = 10000; // 10 seconds

export const useServerSentEvents = () => {
    const [error, setError] = useState<Error | null>(null);
    const [fails, setFails] = useState(0);
    const token = useToken();
    const branchId = useBranchId();
    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);

    useEffect(() => {
        void fetchEventSource(process.env.REACT_APP_SSE_BASE_URL + 'events', {
            headers: { authorization: token },
            openWhenHidden: true, // run in the background

            // eslint-disable-next-line @typescript-eslint/require-await
            onopen: async () => {
                setFails(0);
            },

            onmessage: (event) => {
                const data = JSON.parse(event.data) as EventType;

                void handleECallEvents(data);
            },

            onerror: (error) => {
                setError(error as Error);
                const shouldRetry = fails < 3;

                if (!shouldRetry) {
                    throw new Error('Failed to connect to SSE');
                }

                setFails((prev) => prev + 1);
                return MILLISECONDS_TO_RETRY;
            },
        });
    }, []);

    const handleECallEvents = async (event: EventType) => {
        if (event.event === 'ecall/refresh-calls' && isOnCurrentShift) {
            await queryClient.invalidateQueries({ queryKey: ['ongoing-calls', branchId] });
            await queryClient.invalidateQueries({ queryKey: ['undocumented-calls', branchId] });
        }
    };

    return { error };
};
