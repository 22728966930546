import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api//common';
import { BranchesByRegionParams, BranchesByRegionResponse } from '~/types/companyInfo';

export const useBranchesByRegionQuery = (jsonParams: BranchesByRegionParams, enabled = true) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                company_id: Number.isInteger(jsonParams.companyId) ? jsonParams.companyId : undefined,
                region_id: Number.isInteger(jsonParams.regionId) ? jsonParams.regionId : undefined,
                branch_id: Number.isInteger(jsonParams.branchId) ? jsonParams.branchId : undefined,
            },
        };

        const { data } = await api.get<{ response: BranchesByRegionResponse }>(
            '/company-info/branches-by-region',
            options
        );

        return data.response;
    }, [jsonParams, token]);

    return useErrorHandledQuery({
        queryKey: ['branches-by-region'],
        queryFn,
        enabled: enabled && (!!jsonParams.companyId || !!jsonParams.regionId || !!jsonParams.branchId),
        staleTime: 300000,
    });
};
