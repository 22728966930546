import range from 'lodash/range';
import { DateTime } from 'luxon';

import {
    AnyModuleType,
    AnySectionType,
    DetailsGroupingDataType,
    DetailsGroupingType,
    DetailsType,
    ModuleType,
    SectionDataType,
    SectionType,
} from '@allie/operations-common/src/types/module';
import { Level } from '@allie/operations-common/src/types/shared';

export type LevelData<L extends Level> = {
    type: L;
    modules: { [M in ModuleType<L>]?: ModuleData<L, M> };
};

export type ModuleData<L extends Level, M extends ModuleType<L>> = {
    type: M;
    sections: { [S in SectionType<L, M>]?: SectionData<L, M, S> };
};

export type SectionData<L extends Level, M extends ModuleType<L>, S extends SectionType<L, M>> = {
    type: S;
    data: SectionDataType<L, M, S>;
};

// Helper types for when we really don't care about/cannot get the specific type
export type AnyLevelData = LevelData<Level>;
export type AnyModuleData = ModuleData<Level, AnyModuleType>;
export type AnySectionData = SectionData<Level, AnyModuleType, AnySectionType>;

const residentNames = [
    'Shirley M.',
    'Mary L.',
    'James R.',
    'Helen T.',
    'William P.',
    'Barbara J.',
    'John H.',
    'Margaret S.',
    'Robert W.',
    'Patricia K.',
];

const staffNames = [
    'Michael J.',
    'Emily R.',
    'Daniel T.',
    'Sarah L.',
    'Matthew P.',
    'Anna K.',
    'Christopher H.',
    'Rachel S.',
    'David M.',
    'Laura W.',
];

export const testSectionsData: { [L in Level]?: LevelData<L> } = {
    BRANCH: {
        type: 'BRANCH',
        modules: {
            CALL_BY_RESPONSE_TIME: {
                type: 'CALL_BY_RESPONSE_TIME',
                sections: {
                    AVG_RESPONSE_TIME: {
                        type: 'AVG_RESPONSE_TIME',
                        data: {
                            responseTimeMs: 6 * 60 * 1000,
                            items: [
                                {
                                    date: DateTime.now().minus({ days: 4 }).toISODate(),
                                    responseTimeMs: 6 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().minus({ days: 3 }).toISODate(),
                                    responseTimeMs: 7 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().minus({ days: 2 }).toISODate(),
                                    responseTimeMs: 5 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().minus({ days: 1 }).toISODate(),
                                    responseTimeMs: 6 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().toISODate(),
                                    responseTimeMs: 5 * 60 * 1000,
                                },
                                {
                                    date: DateTime.now().plus({ days: 1 }).toISODate(),
                                    responseTimeMs: null,
                                },
                                {
                                    date: DateTime.now().plus({ days: 2 }).toISODate(),
                                    responseTimeMs: null,
                                },
                            ],
                        },
                    },
                    AVG_RESPONSE_TIME_BY_CARE_STAFF: {
                        type: 'AVG_RESPONSE_TIME_BY_CARE_STAFF',
                        data: [
                            {
                                staffName: staffNames[7],
                                responseTimeMs: 10 * 60 * 1000,
                                callCount: 10,
                            },
                            {
                                staffName: staffNames[4],
                                responseTimeMs: 8 * 60 * 1000,
                                callCount: 9,
                            },
                            {
                                staffName: staffNames[6],
                                responseTimeMs: 7 * 60 * 1000,
                                callCount: 13,
                            },
                            ...range(25 - 6).map(() => ({
                                staffName: '',
                                responseTimeMs: 0,
                                callCount: 0,
                            })),
                            {
                                staffName: staffNames[2],
                                responseTimeMs: 6 * 60 * 1000,
                                callCount: 7,
                            },
                            {
                                staffName: staffNames[1],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 10,
                            },
                            {
                                staffName: staffNames[3],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 15,
                            },
                        ],
                    },
                    AVG_RESPONSE_TIME_BY_RESIDENT: {
                        type: 'AVG_RESPONSE_TIME_BY_RESIDENT',
                        data: [
                            {
                                residentName: residentNames[3],
                                responseTimeMs: 10 * 60 * 1000,
                                callCount: 4,
                            },
                            {
                                residentName: residentNames[8],
                                responseTimeMs: 8 * 60 * 1000,
                                callCount: 2,
                            },
                            {
                                residentName: residentNames[5],
                                responseTimeMs: 7 * 60 * 1000,
                                callCount: 8,
                            },
                            ...range(75 - 6).map(() => ({
                                residentName: '',
                                responseTimeMs: 0,
                                callCount: 0,
                            })),
                            {
                                residentName: residentNames[6],
                                responseTimeMs: 6 * 60 * 1000,
                                callCount: 3,
                            },
                            {
                                residentName: residentNames[4],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 12,
                            },
                            {
                                residentName: residentNames[2],
                                responseTimeMs: 5 * 60 * 1000,
                                callCount: 5,
                            },
                        ],
                    },
                    LONGEST_RESPONSE_TIME: {
                        type: 'LONGEST_RESPONSE_TIME',
                        data: [
                            {
                                residentName: residentNames[4],
                                staffName: staffNames[4],
                                responseTimeMs: 55 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[5],
                                staffName: staffNames[1],
                                responseTimeMs: 30 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[1],
                                staffName: staffNames[0],
                                responseTimeMs: 25 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[2],
                                staffName: staffNames[8],
                                responseTimeMs: 21 * 60 * 1000,
                            },
                            {
                                residentName: residentNames[9],
                                staffName: staffNames[3],
                                responseTimeMs: 14 * 60 * 1000,
                            },
                        ],
                    },
                },
            },
            CALL_BY_DETAIL: {
                type: 'CALL_BY_DETAIL',
                sections: {
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        data: {
                            totalCount: 19 + 115,
                            emergencyCount: 19,
                            regularCount: 115,
                            items: [
                                {
                                    date: DateTime.now().minus({ days: 4 }).toISODate(),
                                    emergencyCount: 17,
                                    regularCount: 121,
                                },
                                {
                                    date: DateTime.now().minus({ days: 3 }).toISODate(),
                                    emergencyCount: 15,
                                    regularCount: 113,
                                },
                                {
                                    date: DateTime.now().minus({ days: 2 }).toISODate(),
                                    emergencyCount: 22,
                                    regularCount: 108,
                                },
                                {
                                    date: DateTime.now().minus({ days: 1 }).toISODate(),
                                    emergencyCount: 19,
                                    regularCount: 115,
                                },
                                {
                                    date: DateTime.now().toISODate(),
                                    emergencyCount: 20,
                                    regularCount: 116,
                                },
                                {
                                    date: DateTime.now().plus({ days: 1 }).toISODate(),
                                    emergencyCount: 0,
                                    regularCount: 0,
                                },
                                {
                                    date: DateTime.now().plus({ days: 2 }).toISODate(),
                                    emergencyCount: 0,
                                    regularCount: 0,
                                },
                            ],
                        },
                    },
                    EMERGENCY_CALLS: {
                        type: 'EMERGENCY_CALLS',
                        data: {
                            count: 19,
                            items: [
                                {
                                    residentName: residentNames[0],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T18:00:00`,
                                },
                                {
                                    residentName: residentNames[5],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T15:00:00`,
                                },
                                {
                                    residentName: residentNames[2],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T09:00:00`,
                                },
                            ],
                        },
                    },
                    NOT_FEELING_WELL_CALLS: {
                        type: 'NOT_FEELING_WELL_CALLS',
                        data: {
                            count: 115,
                            items: [
                                {
                                    residentName: residentNames[4],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T19:00:00`,
                                },
                                {
                                    residentName: residentNames[6],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T16:00:00`,
                                },
                                {
                                    residentName: residentNames[9],
                                    timestamp: `${DateTime.now().minus({ days: 4 }).toISODate()}T08:00:00`,
                                },
                            ],
                        },
                    },
                },
            },
            CALL_BY_RESIDENT: {
                type: 'CALL_BY_RESIDENT',
                sections: {
                    TOTAL_CALLS: {
                        type: 'TOTAL_CALLS',
                        data: {
                            totalCount: 19 + 115,
                            top10Percentage: 34 / (19 + 115),
                            otherPercentage: 100 / (19 + 115),
                            items: [
                                {
                                    date: DateTime.now().minus({ days: 4 }).toISODate(),
                                    top10Percentage: 33 / (17 + 121),
                                    otherPercentage: 105 / (17 + 121),
                                },
                                {
                                    date: DateTime.now().minus({ days: 3 }).toISODate(),
                                    top10Percentage: 35 / (15 + 113),
                                    otherPercentage: 93 / (15 + 113),
                                },
                                {
                                    date: DateTime.now().minus({ days: 2 }).toISODate(),
                                    top10Percentage: 31 / (22 + 108),
                                    otherPercentage: 99 / (22 + 108),
                                },
                                {
                                    date: DateTime.now().minus({ days: 1 }).toISODate(),
                                    top10Percentage: 34 / (19 + 115),
                                    otherPercentage: 100 / (19 + 115),
                                },
                                {
                                    date: DateTime.now().toISODate(),
                                    top10Percentage: 39 / (20 + 116),
                                    otherPercentage: 95 / (20 + 116),
                                },
                                {
                                    date: DateTime.now().plus({ days: 1 }).toISODate(),
                                    top10Percentage: 0,
                                    otherPercentage: 0,
                                },
                                {
                                    date: DateTime.now().plus({ days: 2 }).toISODate(),
                                    top10Percentage: 0,
                                    otherPercentage: 0,
                                },
                            ],
                        },
                    },
                    RESIDENTS_WITH_NEEDS_CHANGES: {
                        type: 'RESIDENTS_WITH_NEEDS_CHANGES',
                        data: {
                            count: 5,
                            items: [
                                {
                                    residentName: residentNames[8],
                                    isNewResident: false,
                                    callCount: 21,
                                    changePercentage: 0.75,
                                },
                                {
                                    residentName: residentNames[6],
                                    isNewResident: true,
                                    callCount: 18,
                                    changePercentage: 0.5,
                                },
                                {
                                    residentName: residentNames[0],
                                    isNewResident: false,
                                    callCount: 13,
                                    changePercentage: 0.3,
                                },
                            ],
                        },
                    },
                    POTENTIAL_CARE_LEVEL_INCREASE: {
                        type: 'POTENTIAL_CARE_LEVEL_INCREASE',
                        data: {
                            count: 6,
                            items: [
                                {
                                    residentName: residentNames[5],
                                    isNewResident: false,
                                    callCount: 21,
                                    careLevel: 2,
                                },
                                {
                                    residentName: residentNames[4],
                                    isNewResident: false,
                                    callCount: 18,
                                    careLevel: 1,
                                },
                                {
                                    residentName: residentNames[2],
                                    isNewResident: true,
                                    callCount: 17,
                                    careLevel: 2,
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
};

const testDetailsData: {
    [L in Level]?: {
        [M in ModuleType<L>]?: {
            [D in DetailsType<L, M>]?: {
                [G in DetailsGroupingType<L, M, D>]?: DetailsGroupingDataType<L, M, D, G>;
            };
        };
    };
} = {
    BRANCH: {
        CALL_BY_RESPONSE_TIME: {
            AVG_RESPONSE_TIME_BY: {
                SHIFT: [
                    {
                        shiftName: 'AM',
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 64,
                    },
                    {
                        shiftName: 'PM',
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 47,
                    },
                    {
                        shiftName: 'NOC',
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 23,
                    },
                ],
                DAYS: [
                    {
                        date: DateTime.now().minus({ days: 8 }).toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 20 + 116,
                    },
                    {
                        date: DateTime.now().minus({ days: 7 }).toISODate(),
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 18 + 119,
                    },
                    {
                        date: DateTime.now().minus({ days: 6 }).toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 13 + 122,
                    },
                    {
                        date: DateTime.now().minus({ days: 5 }).toISODate(),
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 16 + 118,
                    },
                    {
                        date: DateTime.now().minus({ days: 4 }).toISODate(),
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 17 + 121,
                    },
                    {
                        date: DateTime.now().minus({ days: 3 }).toISODate(),
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 15 + 113,
                    },
                    {
                        date: DateTime.now().minus({ days: 2 }).toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 22 + 108,
                    },
                    {
                        date: DateTime.now().minus({ days: 1 }).toISODate(),
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 19 + 115,
                    },
                    {
                        date: DateTime.now().toISODate(),
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 20 + 116,
                    },
                ],
                CARE_STAFF: [
                    {
                        staffName: staffNames[9],
                        responseTimeMs: 9 * 60 * 1000,
                        callCount: 15,
                    },
                    {
                        staffName: staffNames[8],
                        responseTimeMs: 9 * 60 * 1000,
                        callCount: 10,
                    },
                    {
                        staffName: staffNames[7],
                        responseTimeMs: 8 * 60 * 1000,
                        callCount: 9,
                    },
                    {
                        staffName: staffNames[6],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 15,
                    },
                    {
                        staffName: staffNames[5],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 17,
                    },
                    {
                        staffName: staffNames[4],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 12,
                    },
                    {
                        staffName: staffNames[3],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 15,
                    },
                    {
                        staffName: staffNames[2],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 18,
                    },
                    {
                        staffName: staffNames[1],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 11,
                    },
                    {
                        staffName: staffNames[0],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 12,
                    },
                ],
                RESIDENT: [
                    {
                        residentName: residentNames[9],
                        responseTimeMs: 8 * 60 * 1000,
                        callCount: 9,
                    },
                    {
                        residentName: residentNames[8],
                        responseTimeMs: 8 * 60 * 1000,
                        callCount: 11,
                    },
                    {
                        residentName: residentNames[7],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[6],
                        responseTimeMs: 7 * 60 * 1000,
                        callCount: 10,
                    },
                    {
                        residentName: residentNames[5],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[4],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 7,
                    },
                    {
                        residentName: residentNames[3],
                        responseTimeMs: 6 * 60 * 1000,
                        callCount: 13,
                    },
                    {
                        residentName: residentNames[2],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[1],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[0],
                        responseTimeMs: 5 * 60 * 1000,
                        callCount: 10,
                    },
                ],
            },
            LONGEST_RESPONSE_TIME: {
                ALL: [
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[4],
                        responseTimeMs: 55 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 2, minutes: 7 }).toISO(),
                        managerNotes:
                            'The resident accidentally pressed the e-call and then took a walk out of the building with her family.',
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[4],
                        responseTimeMs: 30 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        managerNotes:
                            'The new caregiver provided the service but forgot to click the e-call button to mark it complete.',
                    },
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[4],
                        responseTimeMs: 25 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[6],
                        responseTimeMs: 21 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[0],
                        responseTimeMs: 14 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 1, minutes: 45 }).toISO(),
                        managerNotes:
                            'We had an emergency in the building, and the staff was busy working with the officials on reporting.',
                    },
                    {
                        residentName: residentNames[3],
                        staffName: staffNames[2],
                        responseTimeMs: 12 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[2],
                        staffName: staffNames[9],
                        responseTimeMs: 11 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[1],
                        staffName: staffNames[7],
                        responseTimeMs: 10 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 16, minutes: 7 }).toISO(),
                        managerNotes:
                            'The resident left their walker in the hallway, and staff found it while making rounds.',
                    },
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[4],
                        responseTimeMs: 8 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 5, minutes: 23 }).toISO(),
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[3],
                        responseTimeMs: 8 * 60 * 1000,
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 10, minutes: 2 }).toISO(),
                        managerNotes: null,
                    },
                ],
            },
        },
        CALL_BY_DETAIL: {
            ALL: {
                EMERGENCY: [
                    {
                        residentName: residentNames[0],
                        staffName: staffNames[0],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        type: 'Fall',
                        responseTimeMs: 41 * 60 * 1000,
                        staffNotes:
                            'She refused to change into clean clothes after a shower, insisting her favorite dress was still clean, though it was visibly soiled. She later asked me to hang the dress back in her closet.',
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[2],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        type: '911',
                        responseTimeMs: 35 * 60 * 1000,
                        staffNotes: null,
                        managerNotes:
                            'The resident called the front desk by mistake while adjusting their phone settings.',
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[8],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        type: 'Fall',
                        responseTimeMs: 32 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[8],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        type: 'Health',
                        responseTimeMs: 25 * 60 * 1000,
                        staffNotes:
                            'The resident left their walker in the hallway, and I found it while making rounds.',
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[4],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 14, minutes: 45 }).toISO(),
                        type: 'Fall',
                        responseTimeMs: 20 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                    },
                    {
                        residentName: residentNames[3],
                        staffName: staffNames[1],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        type: 'Safety',
                        responseTimeMs: 18 * 60 * 1000,
                        staffNotes: null,
                        managerNotes:
                            'A routine fire drill was completed, and residents followed the process smoothly.',
                    },
                ],
                NOT_FEELING_WELL: [
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[9],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        responseTimeMs: 27 * 60 * 1000,
                        staffNotes:
                            'The resident seemed more confused than usual. She urinated a lot. She took off her wet underpants and threw them out in her living room. She called the staff in her room a few times to tell me that her daughter would be here soon.',
                        managerNotes: null,
                        potentialPattern: null,
                    },
                    {
                        residentName: residentNames[2],
                        staffName: staffNames[0],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        responseTimeMs: 32 * 60 * 1000,
                        staffNotes:
                            'Resident is in a lot of pain. The pain seems much worse than it has been. She just wanted to hold my hands for some comfort. She did not want vitals done but mentioned a PRN Percocet, which QMAP gave them.',
                        managerNotes: null,
                        potentialPattern: '4x last 7 days | 6x last 30 days | 4x on AM shift',
                    },
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        responseTimeMs: 18 * 60 * 1000,
                        staffNotes: 'The resident was moaning in pain when we tried to change them.',
                        managerNotes: null,
                        potentialPattern: '2x today | 3x last 7 days | 2x on PM shift',
                    },
                    {
                        residentName: residentNames[5],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        responseTimeMs: 12 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                    },
                    {
                        residentName: residentNames[4],
                        staffName: staffNames[4],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 14, minutes: 45 }).toISO(),
                        responseTimeMs: 15 * 60 * 1000,
                        staffNotes:
                            'Resident was finding it difficult to walk, they were complaining of pains in both legs.',
                        managerNotes: null,
                        potentialPattern: '2x last 7 days | 2x on NOC shift',
                    },
                    {
                        residentName: residentNames[7],
                        staffName: staffNames[6],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 16, minutes: 7 }).toISO(),
                        responseTimeMs: 12 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[7],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        responseTimeMs: 10 * 60 * 1000,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: '3rd time this month',
                    },
                ],
                OTHER: [
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 8, minutes: 43 }).toISO(),
                        responseTimeMs: 27 * 60 * 1000,
                        notableEvents: 'Toileting (1)',
                        staffNotes:
                            'The resident seemed more confused than usual. She urinated a lot. She took off her wet underpants and threw them out in her living room. She called the staff in her room a few times to tell me that her daughter would be here soon.',
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[7],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 18, minutes: 12 }).toISO(),
                        responseTimeMs: 15 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'Scheduled',
                    },
                    {
                        residentName: residentNames[3],
                        staffName: staffNames[0],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 9, minutes: 23 }).toISO(),
                        responseTimeMs: 20 * 60 * 1000,
                        notableEvents: 'Transfer (2 person assist)',
                        staffNotes:
                            'He insisted on getting up from the chair to take a walk - almost fell. We needed two people to assist to get him back into his chair.',
                        managerNotes: null,
                        potentialPattern: '2nd time this week',
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[9],
                        staffName: staffNames[5],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 13, minutes: 34 }).toISO(),
                        responseTimeMs: 12 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[7],
                        staffName: staffNames[4],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 14, minutes: 45 }).toISO(),
                        responseTimeMs: 17 * 60 * 1000,
                        notableEvents: 'Transfer (1), Housekeeping (1)',
                        staffNotes:
                            'He wanted me to transfer to the dinner hall for dinner but then asked me to find his wallet, fix one leg of his coffee table, pour out his urine, reorganize his clothes ,and help him figure out why his cellphone could not call his daughter.',
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'Scheduled',
                    },
                    {
                        residentName: residentNames[6],
                        staffName: staffNames[3],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 15, minutes: 56 }).toISO(),
                        responseTimeMs: 10 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: '3rd time this month',
                        type: 'PRN',
                    },
                    {
                        residentName: residentNames[8],
                        staffName: staffNames[2],
                        timestamp: DateTime.now().minus({ days: 1 }).plus({ hours: 16, minutes: 7 }).toISO(),
                        responseTimeMs: 8 * 60 * 1000,
                        notableEvents: null,
                        staffNotes: null,
                        managerNotes: null,
                        potentialPattern: null,
                        type: 'Scheduled',
                    },
                ],
            },
        },
        CALL_BY_RESIDENT: {
            ALL: {
                HEAVY_HITTER: {
                    totalCount: 15 + 12 + 8 + 13 + 7 + 12 + 10,
                    items: [
                        {
                            residentName: residentNames[2],
                            callCount: 15,
                            avgTimePerShiftMs: 15 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().minus({ days: 4 }).minus({ days: 10 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[0],
                            callCount: 12,
                            avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().minus({ days: 3 }).minus({ days: 13 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[5],
                            callCount: 8,
                            avgTimePerShiftMs: 8 * 5 * 60 * 1000,
                            careLevel: 3,
                            assessmentDate: DateTime.now().minus({ days: 2 }).minus({ days: 15 }).toISODate(),
                            allieCocScore: 3,
                        },
                        {
                            residentName: residentNames[8],
                            callCount: 13,
                            avgTimePerShiftMs: 13 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().minus({ days: 1 }).minus({ days: 12 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[7],
                            callCount: 7,
                            avgTimePerShiftMs: 7 * 5 * 60 * 1000,
                            careLevel: 3,
                            assessmentDate: DateTime.now().minus({ days: 14 }).toISODate(),
                            allieCocScore: 3,
                        },
                        {
                            residentName: residentNames[9],
                            callCount: 12,
                            avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().plus({ days: 1 }).minus({ days: 11 }).toISODate(),
                            allieCocScore: 2,
                        },
                        {
                            residentName: residentNames[6],
                            callCount: 10,
                            avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                            careLevel: 2,
                            assessmentDate: DateTime.now().plus({ days: 2 }).minus({ days: 16 }).toISODate(),
                            allieCocScore: 2,
                        },
                    ],
                },
                ANOMALY: [
                    {
                        residentName: residentNames[5],
                        changePercentage: +0.25,
                        callCount: 12 * 1.25,
                        prevCallCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 4 }).minus({ days: 10 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[2],
                        changePercentage: -(1 / 3),
                        callCount: 18 * (2 / 3),
                        prevCallCount: 18,
                        avgTimePerShiftMs: 18 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 3 }).minus({ days: 13 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: '2+ falls in last 30 days',
                    },
                    {
                        residentName: residentNames[3],
                        changePercentage: +0.1,
                        callCount: 10 * 1.1,
                        prevCallCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 2 }).minus({ days: 15 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[8],
                        changePercentage: -0.3,
                        callCount: 20 * 0.7,
                        prevCallCount: 20,
                        avgTimePerShiftMs: 20 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 1 }).minus({ days: 12 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[6],
                        changePercentage: +(1 / 3),
                        callCount: 12 * (4 / 3),
                        prevCallCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 14 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: '2+ incontinence in last 7 days',
                    },
                    {
                        residentName: residentNames[1],
                        changePercentage: -0.2,
                        callCount: 15 * 0.8,
                        prevCallCount: 15,
                        avgTimePerShiftMs: 15 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 1 }).minus({ days: 11 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[0],
                        changePercentage: +0.3,
                        callCount: 10 * 1.3,
                        prevCallCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 2 }).minus({ days: 16 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                ],
                CARE_INCREASE: [
                    {
                        residentName: residentNames[7],
                        callCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 4 }).minus({ days: 10 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[4],
                        callCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 3 }).minus({ days: 13 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: '2+ falls in last 30 days',
                    },
                    {
                        residentName: residentNames[3],
                        callCount: 8,
                        avgTimePerShiftMs: 8 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 2 }).minus({ days: 15 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[9],
                        callCount: 13,
                        avgTimePerShiftMs: 13 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().minus({ days: 1 }).minus({ days: 12 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: '2+ incontinence in last 7 days',
                    },
                    {
                        residentName: residentNames[2],
                        callCount: 7,
                        avgTimePerShiftMs: 7 * 5 * 60 * 1000,
                        careLevel: 3,
                        assessmentDate: DateTime.now().minus({ days: 14 }).toISODate(),
                        allieCocScore: 3,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[5],
                        callCount: 12,
                        avgTimePerShiftMs: 12 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 1 }).minus({ days: 11 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                    {
                        residentName: residentNames[0],
                        callCount: 10,
                        avgTimePerShiftMs: 10 * 5 * 60 * 1000,
                        careLevel: 2,
                        assessmentDate: DateTime.now().plus({ days: 2 }).minus({ days: 16 }).toISODate(),
                        allieCocScore: 2,
                        allieNotes: null,
                    },
                ],
                ALL: [
                    {
                        residentName: residentNames[3],
                        callCount: 13,
                    },
                    {
                        residentName: residentNames[5],
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[1],
                        callCount: 12,
                    },
                    {
                        residentName: residentNames[8],
                        callCount: 11,
                    },
                    {
                        residentName: residentNames[6],
                        callCount: 10,
                    },
                    {
                        residentName: residentNames[0],
                        callCount: 10,
                    },
                    {
                        residentName: residentNames[2],
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[7],
                        callCount: 8,
                    },
                    {
                        residentName: residentNames[4],
                        callCount: 7,
                    },
                    {
                        residentName: residentNames[9],
                        callCount: 9,
                    },
                ],
            },
        },
    },
};

export const getDetailsData = <
    L extends Level,
    M extends ModuleType<L>,
    D extends DetailsType<L, M>,
    G extends DetailsGroupingType<L, M, D>,
    T extends DetailsGroupingDataType<L, M, D, G>,
>(
    payload: {
        level: L;
        moduleType: M;
        detailsType: D;
        detailsGroupingType: G;
    } | null
) => {
    if (!payload) return null;

    const { level, moduleType, detailsType, detailsGroupingType } = payload;

    const levelData = testDetailsData[level]!;
    const moduleData = levelData[moduleType]!;
    const detailsData = moduleData[detailsType]!;
    const detailsGroupingData = detailsData[detailsGroupingType];

    return detailsGroupingData as T;
};
