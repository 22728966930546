import { useAtom } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';

import { selectedBranchIdAtom, selectedCareTypeIdAtom, selectedRegionIdAtom } from '~/pages/OperationsV2/atoms';

import { FilterItemOption, FilterItemValue } from '../components/Header/FilterItem';

import { CareType, useCompanyInfo } from './useCompanyInfo';

export const useCompanyOptions = () => {
    const [selectedRegionId, setSelectedRegionId] = useAtom(selectedRegionIdAtom);
    const [selectedBranchId, setSelectedBranchId] = useAtom(selectedBranchIdAtom);
    const [selectedCareTypeId, setSelectedCareTypeId] = useAtom(selectedCareTypeIdAtom);

    const { regions, regionById, branchById, careTypes, isLoading } = useCompanyInfo();

    const selectedRegion = (selectedRegionId && regionById?.get(selectedRegionId)) || null;
    const selectedBranch = (selectedBranchId && branchById?.get(selectedBranchId)) || null;

    const createOption = useCallback(
        <T extends FilterItemValue>(value: T, label: string, shortLabel?: string): FilterItemOption<T> => ({
            value,
            label,
            shortLabel: shortLabel ?? label,
        }),
        []
    );

    const allOption = useMemo(() => createOption(null, 'All'), []);

    // All available regions
    const regionOptions = useMemo(() => {
        if (!regions) return [allOption];

        return [allOption, ...regions.map(({ id, shortName }) => createOption(id, shortName))];
    }, [regions]);

    // All available branches based on selected region
    const branchOptions = useMemo(() => {
        const branches = selectedRegion?.branches;
        if (!branches) return [allOption];

        return [allOption, ...branches.map(({ id, shortName }) => createOption(id, shortName))];
    }, [selectedRegionId, regionById]);

    // Choose most specific care types based on selected region and branch
    const careTypeOptions = useMemo(() => {
        let filteredCareTypes: CareType[];

        if (!selectedRegion) {
            // Company-level
            filteredCareTypes = careTypes ?? [];
        } else if (!selectedBranch) {
            // Regional-level
            filteredCareTypes = selectedRegion.careTypes;
        } else {
            // Branch-level
            filteredCareTypes = selectedBranch.careTypes;
        }

        return [allOption, ...filteredCareTypes.map(({ id, name, code }) => createOption(id, name, code))];
    }, [selectedRegionId, selectedBranchId, regionById]);

    // Automatically select region if there's only one option
    useEffect(() => {
        if (isLoading) return;

        if (regionOptions.length === 2) setSelectedRegionId(regionOptions[1].value);
    }, [isLoading, regionOptions]);

    // Automatically select branch if there's only one option
    useEffect(() => {
        if (isLoading) return;

        if (branchOptions.length === 2) setSelectedBranchId(branchOptions[1].value);
    }, [isLoading, branchOptions]);

    // Automatically select care type if there's only one option
    useEffect(() => {
        if (isLoading) return;

        if (careTypeOptions.length === 2) setSelectedCareTypeId(careTypeOptions[1].value);
    }, [isLoading, careTypeOptions]);

    // Reset branch selection to 'All' when region changes, as there should be no intersection
    useEffect(() => {
        if (isLoading) return;

        setSelectedBranchId(null);
    }, [selectedRegionId]);

    // Reset care type selection to 'All' when options change and the selected one is no longer available
    useEffect(() => {
        if (isLoading || !selectedCareTypeId) return;

        const careTypeExists = careTypeOptions.some((option) => option.value === selectedCareTypeId);
        if (!careTypeExists) setSelectedCareTypeId(null);
    }, [isLoading, careTypeOptions]);

    return { regionOptions, branchOptions, careTypeOptions, isLoading };
};
