import { DateTime } from 'luxon';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_RESIDENT', 'ALL', 'CARE_INCREASE'>[number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                },
                callCount: {
                    label: 'Call Count\n(current)',
                },
                avgTimePerShiftMs: {
                    label: 'Avg. Time Spent\nPer Shift',
                    renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                },
                careLevel: {
                    label: 'Care Level/\nAssessment Points',
                },
                assessmentDate: {
                    label: 'Most Recent\nAssessment Date',
                    renderCell: (value) => (value ? DateTime.fromISO(value).toFormat('MMM d') : null),
                },
                allieCocScore: {
                    label: 'AllieHealth CoC\nScore/Alert',
                },
                allieNotes: {
                    label: 'AllieHealth Notes',
                    allowSort: false,
                    renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '—'),
                },
            }}
            rows={items}
        />
    </DataContainer>
);

export default Data;
