import { Box, Typography } from '@mui/material';
import React from 'react';

const NewResChip = () => (
    <Box
        sx={({ palette }) => ({
            backgroundColor: palette.primary[300] as string,
            display: 'flex',
            alignItems: 'center',
            height: '16px',
            padding: '0 4px',
            borderRadius: '8px',
        })}
    >
        <Typography
            variant="caption"
            sx={{
                fontSize: '10px',
                fontWeight: 700,
                lineHeight: '8px',
            }}
        >
            New Res.
        </Typography>
    </Box>
);

export default NewResChip;
