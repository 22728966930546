import { useFeatureFlagEnabled } from 'posthog-js/react';

import { usePermissions } from '~/permissions/utils';

export default function useHeaderV2() {
    const permissions = usePermissions();
    const flagEnabled = useFeatureFlagEnabled('header-v2');

    return (
        permissions('Community', 'ecall-caregiver') ||
        (!!flagEnabled && permissions('Community', 'undertake-resident-action'))
    );
}
