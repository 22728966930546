import { Box, Typography, useTheme } from '@mui/material';
import { Chat } from '@phosphor-icons/react';
import React from 'react';

import { PrnTaskCategories } from '../steps/category/types/prnTaskCategories';
import categoryWithIconAdapter from '../steps/category/utils/withIcon';

interface StepContentProps {
    children: React.ReactNode;
    category: PrnTaskCategories.Category;
}

export const StepContent = ({ children, category }: StepContentProps) => {
    const { palette } = useTheme();

    const getCategoryWithIcon = (category: PrnTaskCategories.Category) => {
        if (category?.id === 0)
            return {
                ...category,
                icon: Chat,
            };
        return categoryWithIconAdapter(category);
    };

    const categoryWithIcon = category ? getCategoryWithIcon(category) : null;

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            padding="16px"
            bgcolor={palette.grey[50]}
            borderRadius="8px"
            gap="24px"
        >
            <Box alignSelf="center" display="flex" alignItems="center" gap="8px" flexDirection="column">
                {categoryWithIcon && <categoryWithIcon.icon size={24} />}
                <Typography variant="body1" fontSize="16px" fontWeight={600} color={palette.grey[900]}>
                    {category?.label}
                </Typography>
            </Box>
            {children}
        </Box>
    );
};
