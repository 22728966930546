import React from 'react';
import { IconType } from 'react-icons';
import { PiToilet } from 'react-icons/pi';

import { DEVICE_LOCATION_TYPE } from '@allie/utils/src/constants/ecall/device.constants';

import palette from '~/theme/palette';
import { PUBLIC_LOCATION_TYPE } from '~/types/call/call';

export const getPublicLocationCallIcon = (type?: PUBLIC_LOCATION_TYPE) => {
    if (!type) return null;

    const mapCallTypeToIcon: Record<PUBLIC_LOCATION_TYPE, IconType> = {
        [DEVICE_LOCATION_TYPE.PUBLIC_RESTROOM]: PiToilet,
    };

    const Icon = mapCallTypeToIcon[type];

    return <Icon size={32} color={palette.secondary![500] as string} />;
};
