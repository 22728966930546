import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import { ExportShiftNotesCsvParams } from '~/types/residentShiftNotes';

export const useExportShiftNotesCsvQuery = (params: ExportShiftNotesCsvParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options: AxiosRequestConfig = {
            headers: { Authorization: token },
            params: {
                branch_id: params.branchId,
                start_date: params.startDate,
                end_date: params.endDate,
                resident_id: params.residentId,
            },
            responseType: 'blob',
        };

        const { data } = await api.get<ArrayBuffer>(`/resident-shift-notes/export-csv`, options);

        return data;
    }, [token]);

    return useErrorHandledQuery({
        queryKey: ['export-shift-notes-csv', params],
        queryFn,
        enabled: false,
    });
};
