import { Box, Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useState } from 'react';
import { PiArrowsDownUpBold } from 'react-icons/pi';

import { TabKey } from '~/types/dailyTasks.d';
import { SortBy } from '~/types/sort';

import { ecallSortAtom } from '../eCallComponents/atom';
import { ECall } from '../eCallComponents/types';

import CustomDrawer from './CustomDrawer';

const FooterContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px',
    borderTop: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    gap: '12px',
}));

interface SortDialogProps {
    isOpen: boolean;
    onClose: () => void;
    onSort: (sortBy?: SortBy.SortingKey, sortOrder?: SortBy.SortOrder) => void;
    sortBy?: SortBy.SortingKey;
    sortOrder?: SortBy.SortOrder;
    selectedTab: TabKey;
}

const SortButton = styled(Box)<{ selected?: boolean }>(({ theme, selected }) => ({
    padding: '12px',
    borderWidth: '1px',
    borderStyle: 'solid',
    flex: 1,
    borderColor: selected ? theme.palette.primary[500] : theme.palette.grey[100],
    backgroundColor: selected ? theme.palette.primary[50] : 'white',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 600,
    fontFamily: 'Inter, sans-serif',
    lineHeight: '16px',
    color: selected ? theme.palette.primary[500] : theme.palette.grey[900],
    textAlign: 'center',
    textWrap: 'nowrap',
}));

export const SortDialog = ({ isOpen, selectedTab, sortBy, sortOrder, onClose, onSort }: SortDialogProps) => {
    const { palette } = useTheme();
    const [ecallSort, setEcallSort] = useAtom(ecallSortAtom);
    const [commonSortOptions, setCommonSortOptions] = useState({ sortBy, sortOrder });

    const handleSort = (selectedSortBy: ECall.SortingKey | SortBy.SortingKey) => {
        if (selectedTab === TabKey.LIVE_CALLS) {
            setEcallSort(selectedSortBy as ECall.SortingKey);
        } else {
            setCommonSortOptions({ sortBy: selectedSortBy as SortBy.SortingKey, sortOrder });
        }
    };

    const handleOrder = (selectedSortOrder: SortBy.SortOrder) => {
        setCommonSortOptions({ sortBy: commonSortOptions.sortBy, sortOrder: selectedSortOrder });
    };

    const handleSubmit = () => {
        onSort(commonSortOptions.sortBy, commonSortOptions.sortOrder);
        onClose();
    };

    const getLiveCallsSortOptions = () => {
        return (
            <>
                <Typography variant="body1" color="common.text">
                    Type
                </Typography>
                <Box display="flex" gap="8px">
                    <SortButton selected={ecallSort === 'callOrder'} onClick={() => handleSort('callOrder')}>
                        Call Order
                    </SortButton>
                    <SortButton selected={ecallSort === 'roomNumber'} onClick={() => handleSort('roomNumber')}>
                        Apt
                    </SortButton>
                </Box>
            </>
        );
    };

    const getCommomSortOptions = () => {
        return (
            <>
                <Typography variant="body1" color="common.text">
                    Type
                </Typography>
                <Box display="flex" gap="8px">
                    <SortButton
                        selected={commonSortOptions.sortBy === 'roomNumber'}
                        onClick={() => handleSort('roomNumber')}
                    >
                        Apt
                    </SortButton>
                    <SortButton
                        selected={commonSortOptions.sortBy === 'firstName'}
                        onClick={() => handleSort('firstName')}
                    >
                        Name
                    </SortButton>
                    <SortButton
                        selected={commonSortOptions.sortBy === 'shower&Laundry'}
                        onClick={() => handleSort('shower&Laundry')}
                    >
                        Shower & Laundry
                    </SortButton>
                </Box>

                <Box height="8px" />

                <Typography variant="body1" color="common.text">
                    Order
                </Typography>
                <Box display="flex" gap="8px">
                    <SortButton selected={commonSortOptions.sortOrder === 'asc'} onClick={() => handleOrder('asc')}>
                        Ascending
                    </SortButton>
                    <SortButton selected={commonSortOptions.sortOrder === 'desc'} onClick={() => handleOrder('desc')}>
                        Descending
                    </SortButton>
                </Box>
            </>
        );
    };

    return (
        <CustomDrawer
            isOpen={isOpen}
            onClose={onClose}
            closeButton
            sx={{ height: 'min-content' }}
            header={
                <>
                    <Box padding="4px">
                        <PiArrowsDownUpBold size={24} color={palette.secondary[500] as string} />
                    </Box>
                    <Typography variant="h6" fontSize="16px" color={palette.grey[900]}>
                        Sort by
                    </Typography>
                </>
            }
        >
            <Stack gap="8px" padding="16px 24px">
                {selectedTab === TabKey.LIVE_CALLS ? getLiveCallsSortOptions() : getCommomSortOptions()}
            </Stack>
            <FooterContainer>
                <Button fullWidth size="large" variant="contained" onClick={handleSubmit}>
                    Save
                </Button>
            </FooterContainer>
        </CustomDrawer>
    );
};
