import actionTypes from '~/redux/actionTypes';
import '~/redux/reducers/messages';
import { MessageProps } from '~/types/messages';
import { AppDispatch, ReduxStore } from '~/types/redux';

// TODO - remove when maintenance mode is removed
// ps: do not forget to remove the MessageProps type
export const showAlert = (message: MessageProps) => (dispatch: AppDispatch, getState: () => ReduxStore) => {
    const { messages: messagesState } = getState();

    if (messagesState.timer) clearTimeout(messagesState.timer);
    if (!messagesState.open) {
        dispatch({
            type: actionTypes.MESSAGE_SHOW,
            payload: {
                ...message,
                timer: window.setTimeout(() => dispatch(hideAlert()), 10000),
            },
        });
    }
};

// TODO - remove when showAlert is removed
export const hideAlert = () => (dispatch: AppDispatch, getState: () => ReduxStore) => {
    const { messages: messagesState } = getState();

    if (messagesState.timer) clearTimeout(messagesState.timer);
    if (messagesState.open && process.env.REACT_APP_IS_MAINTENANCE !== 'true')
        dispatch({ type: actionTypes.MESSAGE_HIDE });
};
