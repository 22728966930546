import { useCallback, useMemo } from 'react';

import { api } from '~/api';
import { useBranchId, useErrorHandledQuery, useToken } from '~/api/common';
import { AssignmentListResponse } from '~/types/assignments';

type GetAssignmentsParams = {
    enabled?: boolean;
    shiftId?: number;
    shiftDay?: string;
    branchId?: number;
};

export const useGetAssignments = ({
    enabled = true,
    shiftId,
    shiftDay,
    branchId: selectedBranchId,
}: GetAssignmentsParams) => {
    const token = useToken();
    const storedBranchId = useBranchId();

    const branchId = useMemo(() => selectedBranchId || storedBranchId, [selectedBranchId, storedBranchId]);

    const queryFn = useCallback(async () => {
        const { data } = await api.get<AssignmentListResponse>(`/assignments`, {
            headers: { Authorization: token },
            params: { branch_id: branchId, shift_id: shiftId, shift_day: shiftDay },
        });

        return data.response ?? [];
    }, [branchId, token, shiftId, shiftDay]);

    return useErrorHandledQuery({
        queryKey: ['assignments', branchId, shiftId, shiftDay],
        queryFn,
        enabled: !!branchId && enabled,
        staleTime: 300000,
    });
};
