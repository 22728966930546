import { AxiosError } from 'axios';

import { api } from '~/api';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import actionTypes from '~/redux/actionTypes';
import '~/redux/reducers/shiftNotesCategories';
import { ShiftNotesCategoryResponse } from '~/types/ShiftNotesCategories';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { BaseResponse } from '~/types/shared';

/**
 * Fetches the list of Report Categories.
 * @param jsonParams the JSON object with the params for fetching the Report Categories list.
 */
export const readShiftNotesCategories =
    (branchId: number, actionType = actionTypes.SHIFT_NOTES_CATEGORY_READ_LIST) =>
    async (dispatch: AppDispatch, getState: () => ReduxStore) => {
        try {
            // Get the token.
            const { token } = getState().session.sessionData;

            // Fetch the Report Categories List.
            const { data } = await api.get<BaseResponse<ShiftNotesCategoryResponse[]>>('/shift-notes-category', {
                headers: {
                    authorization: token,
                },
                params: { branch_id: branchId }, // For permission checking
            });
            const shiftNotesCategoriesList = data.response;

            dispatch({
                type: actionType,
                payload: shiftNotesCategoriesList,
            });
        } catch (err) {
            const error = err as AxiosError;

            showToast({
                message: error.message,
                type: 'error',
            });
        }
    };
