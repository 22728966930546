import { Capacitor } from '@capacitor/core';
import { Box, Button, Theme, Typography, styled, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';

const Container = styled(Box)<{ selected: boolean }>(({ theme, selected }) => ({
    padding: '16px 12px',
    width: '100%',
    height: '45px',
    backgroundColor: selected ? theme.palette.primary[50] : 'white',
    border: `1px solid ${selected ? theme.palette.primary[500] : theme.palette.grey[50]}`,
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const OnlyButton = styled(Button)(({ theme }) => ({
    padding: '8px',
    color: theme.palette.primary[500] as string,
    border: 'none',
    borderRadius: '24px',
    fontWeight: 600,
    backgroundColor: 'transparent',
    fontSize: '14px',
    lineHeight: '18px',
    minWidth: 'unset',

    '&:hover': {
        backgroundColor: theme.palette.primary[100] as string,
    },
    '&:active': {
        color: 'white',
        backgroundColor: theme.palette.primary[600] as string,
    },
}));

interface LocationItemProps {
    label: string;
    selected: boolean;
    onClick: () => void;
    onOnlyClick: () => void;
}

export const LocationItem = ({ label, selected, onClick, onOnlyClick }: LocationItemProps) => {
    const [showOnlyButton, setShowOnlyButton] = useState(false);
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm')) || Capacitor.isNativePlatform();

    const handleOnlyClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        onOnlyClick();
    };

    return (
        <Container
            onMouseEnter={() => setShowOnlyButton(true)}
            onMouseLeave={() => setShowOnlyButton(false)}
            selected={selected}
            onClick={onClick}
        >
            <Typography
                variant="body1"
                color={({ palette }) => palette.grey[600]}
                sx={{ textWrap: 'balance' }}
                fontWeight={600}
            >
                {label}
            </Typography>
            {(isMobile ? selected : showOnlyButton) && <OnlyButton onClick={handleOnlyClick}>Only</OnlyButton>}
        </Container>
    );
};
