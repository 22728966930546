import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Box } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCommunityCodeVerifyMutation, useSessionData } from '~/api/queries/auth/login';
import AuthButton from '~/components/Auth/AuthButton';
import AuthIcon from '~/components/Auth/AuthIcon';
import AuthTitle from '~/components/Auth/AuthTitle';
import BackButton from '~/components/Auth/BackButton';
import CodeInput from '~/components/Auth/CodeInput';
import FormContainer from '~/components/Auth/FormContainer';
import MainContainer from '~/components/Auth/MainContainer';
import GeolocationInfo from '~/components/Geolocation/GeolocationInfo';
import Loading from '~/components/Shared/Loading';
import { pxToRem } from '~/components/theme/typography';
import { VERIFICATION_DIGITS_LENGTH } from '~/constants/shared';
import { useGeolocation } from '~/contexts/GeolocationContext';
import { signIn } from '~/lib/auth';
import PageStructure from '~/pages/PageStructure';
import { signOut } from '~/redux/actions/session';
import { AppDispatch } from '~/types/redux';

import ActivateGeolocationDialog from './ActivateGeolocationDialog';
import ActivateNotificationsDialog from './ActivateNotificationsDialog';

const CommunityCodeVerification = ({ dispatchSignOut }: { dispatchSignOut: () => void }) => {
    const posthog = usePostHog();
    const [communityCode, setCommunityCode] = useState('');
    const [isGeolocationDialogOpen, setIsGeolocationDialogOpen] = useState(false);
    const [isNotificationsDialogOpen, setIsNotificationsDialogOpen] = useState(false);
    const [showGeolocationInfo, setShowGeolocationInfo] = useState(false);
    const [isNotificationLoading, setIsNotificationLoading] = useState(false);

    const { mutate: communityCodeVerifyMutation, isPending } = useCommunityCodeVerifyMutation();

    const navigate = useNavigate();

    const { position, permissionStatus } = useGeolocation();
    const { latitude, longitude } = position?.coords || {};
    const isNativePlatform = Capacitor.isNativePlatform();

    const { data: sessionData, isLoading: sessionDataIsLoading, isError: sessionDataIsError } = useSessionData();

    const userBranchId = sessionData?.branchId;

    const checkPermissionStatus = (status: 'granted' | 'denied' | 'prompt'): boolean => {
        const isAndroid = Capacitor.getPlatform() === 'android';

        if (status === 'prompt') {
            return (permissionStatus?.location || '').includes('prompt');
        }

        return permissionStatus?.location === status || (isAndroid && permissionStatus?.coarseLocation === status);
    };

    const checkAndRequestNotificationsPermission = async () => {
        if (!isNativePlatform) {
            return;
        }
        const permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt' || permStatus.receive.includes('prompt')) {
            setIsNotificationsDialogOpen(true);
        }
    };

    const closeDialogs = () => {
        setIsGeolocationDialogOpen(false);
        setIsNotificationsDialogOpen(false);
    };

    useEffect(() => {
        if (permissionStatus && isNativePlatform) {
            setShowGeolocationInfo(false);
            closeDialogs();
            if (checkPermissionStatus('denied')) {
                setIsGeolocationDialogOpen(false);
                setShowGeolocationInfo(true);
            } else if (!checkPermissionStatus('granted')) {
                setIsGeolocationDialogOpen(true);
            } else {
                void checkAndRequestNotificationsPermission();
            }
        }
    }, [permissionStatus]);

    useEffect(() => {
        if (userBranchId) {
            signIn(sessionData, posthog);
            navigate('/', { replace: true });
        }
    }, [userBranchId]);

    if (sessionDataIsLoading) {
        return <Loading />;
    }

    if (sessionDataIsError || !sessionData) {
        return null;
    }

    const handleCodeChange = (code: string) => {
        setCommunityCode(code);
    };

    const handleVerifyCodeClick = () => {
        if (!communityCode) {
            return;
        }

        if ((!latitude || !longitude) && isNativePlatform) {
            const shouldShowPrompt = checkPermissionStatus('prompt');
            setIsGeolocationDialogOpen(shouldShowPrompt);
            setShowGeolocationInfo(!shouldShowPrompt);
            return;
        }

        communityCodeVerifyMutation({
            setupCode: communityCode,
            latitude: isNativePlatform ? latitude! : 0,
            longitude: isNativePlatform ? longitude! : 0,
        });
    };

    const toggleGeolocationDialog = () => {
        setIsGeolocationDialogOpen((prev) => !prev);
    };

    const toggleNotificationsDialog = () => {
        setIsNotificationsDialogOpen((prev) => !prev);
    };

    const handleGeolocationPermissionCancel = () => {
        toggleGeolocationDialog();
        setShowGeolocationInfo(true);
    };

    const handleActivateNotifications = async () => {
        setIsNotificationLoading(true);
        const permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive === 'granted') {
            toggleNotificationsDialog();
            return;
        }
        toggleNotificationsDialog();
        await PushNotifications.requestPermissions();
        setIsNotificationLoading(false);
    };

    const handleNotificationsPermissionCancel = () => {
        toggleNotificationsDialog();
    };

    if (showGeolocationInfo) {
        return <GeolocationInfo />;
    }

    return (
        <PageStructure>
            <MainContainer>
                <FormContainer>
                    <Box
                        sx={{
                            mb: {
                                lg: pxToRem(44),
                            },
                            pt: 'env(safe-area-inset-top)',
                        }}
                    >
                        <BackButton logout={dispatchSignOut} />
                        <AuthIcon icon={<FaUsers size={36} />} />
                        <Box
                            sx={{
                                mt: {
                                    xs: pxToRem(48),
                                },
                                mb: {
                                    xs: pxToRem(24),
                                },
                            }}
                        >
                            <AuthTitle
                                title="Unique Community Code"
                                subtitle="Hmm, you are at a new community location"
                                align="center"
                            />
                        </Box>
                        <CodeInput onCodeChange={handleCodeChange} code={communityCode} />
                    </Box>
                    <AuthButton
                        label="Verify"
                        onClick={handleVerifyCodeClick}
                        isDisabled={communityCode.length !== VERIFICATION_DIGITS_LENGTH}
                        isLoading={isPending}
                    />
                </FormContainer>
            </MainContainer>
            <ActivateGeolocationDialog
                isOpen={isGeolocationDialogOpen}
                onClose={toggleGeolocationDialog}
                onCancel={handleGeolocationPermissionCancel}
            />
            <ActivateNotificationsDialog
                isOpen={isNotificationsDialogOpen}
                onClose={toggleNotificationsDialog}
                onCancel={handleNotificationsPermissionCancel}
                handleActivateNotifications={handleActivateNotifications}
                isLoading={isNotificationLoading}
            />
        </PageStructure>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({ dispatchSignOut: () => dispatch(signOut()) });

export default connect(null, mapDispatchToProps)(CommunityCodeVerification);
