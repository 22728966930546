import { Box, Palette, Skeleton, Tooltip, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { PiArrowFatLinesUpFill, PiInfoFill } from 'react-icons/pi';

import {
    STANDARDIZED_BUDGET_STATUS_TYPES,
    STANDARDIZED_BUDGET_STATUS_TYPES_LABELS,
} from '@allie/utils/src/constants/scheduling/budget-stats.contants';

import { useBudgetedShifts } from '../../useBudgetedShifts';

const mapStatusToColors = (
    palette: Palette
): Record<STANDARDIZED_BUDGET_STATUS_TYPES, { color: string; borderColor: string; budgetColor: string }> => ({
    [STANDARDIZED_BUDGET_STATUS_TYPES.ON_BUDGET]: {
        color: palette.primary[500] as string,
        borderColor: palette.grey[100],
        budgetColor: palette.grey[300],
    },
    [STANDARDIZED_BUDGET_STATUS_TYPES.OVER]: {
        color: palette.error[600] as string,
        borderColor: palette.error[500] as string,
        budgetColor: palette.error[600] as string,
    },
    [STANDARDIZED_BUDGET_STATUS_TYPES.UNDER]: {
        color: palette.primary[500] as string,
        borderColor: palette.primary[500] as string,
        budgetColor: palette.primary[500] as string,
    },
});

const BudgetChangeTag = styled(Box)<{ increased: boolean }>(({ increased, theme }) => ({
    display: 'flex',
    width: 'fit-content',
    alignItems: 'center',
    padding: '6px 8px',
    gap: '4px',
    backgroundColor: increased ? theme.palette.primary[50] : '#FAEEE9',
    borderColor: increased ? theme.palette.primary[100] : theme.palette.secondary[200],
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '6px',
}));

const BudgetOverviewCard = () => {
    const { palette } = useTheme();
    const { budgetStatus, budgetOverviewStats } = useBudgetedShifts();

    const { scheduled, isLoading, budget, lastBudget } = budgetOverviewStats;

    if (isLoading) {
        return (
            <Box
                padding="20px 16px"
                borderRadius="12px"
                border={`2px solid ${mapStatusToColors(palette)[budgetStatus].borderColor}`}
                bgcolor="#FFF"
                position="relative"
            >
                <Box
                    width="100%"
                    borderBottom={`1px solid ${palette.grey[100]}`}
                    marginBottom="16px"
                    paddingBottom="16px"
                >
                    <Skeleton height="30px" width="100%" />
                </Box>
                <Skeleton height="24px" width="100%" />
            </Box>
        );
    }

    const hasBudgetIncreased = (lastBudget ?? 0) < (budget ?? 0);

    return (
        <Box
            padding="20px 16px"
            borderRadius="12px"
            border={`2px solid ${mapStatusToColors(palette)[budgetStatus].borderColor}`}
            bgcolor="#FFF"
            position="relative"
        >
            {!!lastBudget && !!budget && lastBudget !== budget && (
                <Box
                    display="flex"
                    gap="8px"
                    alignItems="center"
                    marginBottom="16px"
                    paddingBottom="16px"
                    borderBottom={`1px solid ${palette.grey[100]}`}
                >
                    <Typography variant="h6" color={palette.grey[900]}>
                        Your budget
                    </Typography>
                    <BudgetChangeTag increased={hasBudgetIncreased}>
                        <Typography
                            variant="body1"
                            fontSize="12px"
                            lineHeight="16px"
                            textTransform="uppercase"
                            fontWeight={700}
                            color={hasBudgetIncreased ? (palette.primary[400] as string) : '#D05529'}
                        >
                            {hasBudgetIncreased ? 'increased' : 'decreased'}
                        </Typography>
                        <PiArrowFatLinesUpFill
                            size={14}
                            color={hasBudgetIncreased ? (palette.primary[400] as string) : '#D05529'}
                            style={!hasBudgetIncreased ? { transform: 'rotate(180deg)' } : undefined}
                        />
                    </BudgetChangeTag>
                </Box>
            )}
            <Typography variant="h6" fontWeight={500} color={palette.grey[600]}>
                <span
                    style={{
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        color: mapStatusToColors(palette)[budgetStatus].color,
                    }}
                >
                    {STANDARDIZED_BUDGET_STATUS_TYPES_LABELS[budgetStatus]}
                </span>{' '}
                budget{' '}
                {budgetStatus !== STANDARDIZED_BUDGET_STATUS_TYPES.ON_BUDGET && (
                    <>
                        by{' '}
                        <span
                            style={{
                                fontWeight: 700,
                                color: mapStatusToColors(palette)[budgetStatus].budgetColor,
                            }}
                        >
                            {Math.abs((budget ?? 0) - scheduled)}hr
                        </span>{' '}
                        <Tooltip
                            title={
                                <Box display="flex" p="4px 8px" gap="16px">
                                    <Box>
                                        <Typography variant="body1" color={palette.grey[400]} fontSize="13px">
                                            Budget
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            display="inline-block"
                                            color={palette.grey[900]}
                                            fontSize="16px"
                                        >
                                            {budget ?? 0} hr
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body1" color={palette.grey[400]} fontSize="13px">
                                            Scheduled
                                        </Typography>
                                        <Typography variant="h6" color={palette.grey[900]} fontSize="16px">
                                            {scheduled} hr
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        >
                            <span>
                                <PiInfoFill size={16} color={palette.grey[400]} style={{ cursor: 'pointer' }} />
                            </span>
                        </Tooltip>
                    </>
                )}
            </Typography>
        </Box>
    );
};

export default BudgetOverviewCard;
