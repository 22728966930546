import { useCallback } from 'react';

import { api } from '~/api';
import { UserRewardsDetailsResponse, UserRewardsHistoryResponse, UserRewardsSummaryResponse } from '~/types/myRewards';
import { BaseResponse } from '~/types/shared';

import { queryClient, useBranchId, useErrorHandledMutation, useErrorHandledQuery, useToken } from '../common';

export const useRewardsSummaryQuery = (userId?: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<UserRewardsSummaryResponse>>('/rewards/user/summary', {
            headers: {
                authorization: token,
            },
            params: { branch_id: branchId }, // For permission checking
        });

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['rewards', userId, 'summary'],
        queryFn,
        enabled: !!userId && !!branchId,
        staleTime: 300000,
    });
};

export const useRewardsDetailsQuery = (userId?: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<UserRewardsDetailsResponse>>('/rewards/user/details', {
            headers: {
                authorization: token,
            },
            params: { branch_id: branchId }, // For permission checking
        });

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['rewards', userId, 'details'],
        queryFn,
        enabled: !!userId && !!branchId,
        staleTime: 300000,
    });
};

export const useRewardsHistoryQuery = (userId?: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<UserRewardsHistoryResponse>>('/rewards/user/history', {
            headers: {
                authorization: token,
            },
            params: { branch_id: branchId }, // For permission checking
        });

        return data.response;
    }, [branchId, token]);

    return useErrorHandledQuery({
        queryKey: ['rewards', userId, 'history'],
        queryFn,
        enabled: !!userId && !!branchId,
        staleTime: 300000,
    });
};

export const useClaimPointsMutation = (userId: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            params: { branch_id: branchId }, // For permission checking
        };

        await api.post('/rewards/user/claim-points', null, options);
    }, [branchId, token]);

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({
            queryKey: ['rewards', userId],
            exact: false,
            refetchType: 'active',
        });
    }, [userId]);

    return useErrorHandledMutation({
        mutationFn,
        onSuccess,
    });
};

export const useRedeemPointsMutation = (userId: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            params: { branch_id: branchId }, // For permission checking
        };

        await api.post('/rewards/user/redeem-reward', null, options);
    }, [branchId, token]);

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({
            queryKey: ['rewards', userId],
            exact: false,
            refetchType: 'active',
        });
    }, [userId]);

    return useErrorHandledMutation({
        mutationFn,
        onSuccess,
    });
};

export const useCheckInMutation = (userId: number) => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(async () => {
        const options = {
            headers: { Authorization: token },
            params: { branch_id: branchId }, // For permission checking
        };

        await api.post('/rewards/user/check-in', null, options);
    }, [branchId, token]);

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({
            queryKey: ['rewards', userId],
            exact: false,
            refetchType: 'active',
        });
    }, [userId]);

    return useErrorHandledMutation({
        mutationFn,
        onSuccess,
    });
};
