import { ThemeProvider } from '@mui/material';
import React from 'react';

import WithHeader from '~/components/Layout/WithHeader';
import WithNavigation from '~/components/Layout/WithNavigation';
import WithSafePadding from '~/components/Layout/WithSafePadding';

import Body from './components/Body';
import MobileHeader from './components/Header/MobileHeader';
import theme from './theme';

const OperationsV2 = () => (
    <WithSafePadding>
        <WithNavigation>
            <ThemeProvider theme={theme}>
                <WithHeader header={<MobileHeader />}>
                    <Body />
                </WithHeader>
            </ThemeProvider>
        </WithNavigation>
    </WithSafePadding>
);

export default OperationsV2;
