import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useBranchShifts } from '~/api/queries/branch';
import { ReduxStore } from '~/types/redux';

interface BranchShiftSelectorProps {
    selectedBranchId?: number;
    setSelectedBranchId: (branchId: number) => void;
}

export function WithBranchShiftSelector<T extends BranchShiftSelectorProps>(
    WrappedComponent: React.FC<T>
): (props: Omit<T, 'selectedBranchId' | 'setSelectedBranchId'>) => React.ReactNode {
    return function WithBranchShiftSelector(props: T) {
        const { branchId: defaultBranchId } = useSelector((state: ReduxStore) => state.session.sessionData);
        const [branchId, setBranchId] = useState<number | undefined>(defaultBranchId);

        const branchShifts = useBranchShifts(branchId);

        if (branchShifts.length === 0) {
            return null;
        }

        return <WrappedComponent {...props} setSelectedBranchId={setBranchId} selectedBranchId={branchId} />;
    };
}
