import { Box, Typography, useTheme } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React, { useMemo, useState } from 'react';

import { PRN_CATEGORY_SECTIONS } from '@allie/utils/src/constants/prn-tasks.constants';

import TranscriptionTextField from '~/components/Shared/TranscriptionTextField';

import { StepContent } from '../../components/StepContent';
import { useDuplicateSteps } from '../../hooks/useDuplicateSteps';
import { additionalCommentAtom, unscheduledTaskCategoriesAtom } from '../../state/atom';

import { generalComment } from './utils/generalComment';

const AdditionalCommentBoxStep = () => {
    const { palette } = useTheme();
    const { duplicateStepCounter } = useDuplicateSteps();
    const [showActivateMicConfirmation, setShowActivateMicConfirmation] = useState(false);
    const [additionalComments, setAdditionalComments] = useAtom(additionalCommentAtom);
    const isFraudConfirmed = useFeatureFlagEnabled('fraud-confirmed-staff');

    const categories = useAtomValue(unscheduledTaskCategoriesAtom);

    const categoriesFromSectionOther = useMemo(
        () => categories.filter((category) => category.section === PRN_CATEGORY_SECTIONS.OTHER),
        [categories]
    );

    const category = useMemo(
        () => (categoriesFromSectionOther.length ? categoriesFromSectionOther[duplicateStepCounter] : generalComment),
        [categoriesFromSectionOther, duplicateStepCounter]
    );

    const additionalComment = useMemo(
        () => additionalComments.find((comment) => comment.categoryId === category?.id)?.comment,
        [additionalComments, category]
    );

    const handleChange = (value: string) => {
        if (additionalComment != null) {
            setAdditionalComments(
                additionalComments.map((comment) =>
                    comment.categoryId === category?.id ? { ...comment, comment: value } : comment
                )
            );
        } else {
            setAdditionalComments([...additionalComments, { comment: value, categoryId: category?.id }]);
        }
    };

    return (
        <Box height="100%" display="flex" flexDirection="column">
            <Typography variant="body1" fontSize="16px" fontWeight={700} color={palette.grey[900]}>
                Fill out the details below
            </Typography>
            <Box height="24px" />
            <StepContent category={category}>
                <Box flex={1} display="flex" flexDirection="column" gap="8px">
                    <Typography variant="body1" color={palette.grey[900]}>
                        {categoriesFromSectionOther.length || isFraudConfirmed || categories.length > 1
                            ? 'Tell us what happened'
                            : 'Add an additional note (Optional)'}
                    </Typography>
                    <TranscriptionTextField
                        key={category?.id}
                        showActivateMicConfirmation={showActivateMicConfirmation}
                        toggleShowActivateMicConfirmation={setShowActivateMicConfirmation}
                        analyticsIdText="ecall-additional-comment-box"
                        onChange={handleChange}
                        placeholder="Type here or click on the microphone button and speak to add a note. Please review before you submit."
                        text={additionalComment ?? ''}
                        hideDescriptionMessage
                    />
                </Box>
            </StepContent>
        </Box>
    );
};

export default AdditionalCommentBoxStep;
