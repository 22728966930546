import { useTheme } from '@mui/material';
import React from 'react';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

import { DotProps, Item, TickProps, TooltipProps } from '~/pages/OperationsV2/utils/recharts';
import { StringKeyOf } from '~/pages/OperationsV2/utils/types';

import DataLineChartActiveDot from './DataLineChartActiveDot';
import DataLineChartDot from './DataLineChartDot';
import DataLineChartTooltip from './DataLineChartTooltip';
import DataLineChartXAxisTick, { TOOLTIP_HEIGHT_PX } from './DataLineChartXAxisTick';

type DataLineChartProps<T extends Item, TX extends StringKeyOf<T>, TY extends StringKeyOf<T>> = {
    width: number;
    height: number;
    items: T[];
    xAxisDataKey: TX;
    yAxisDataKey: TY;
    renderXAxisLabel: (item: T) => string;
    renderLineTooltipLabel: (item: T) => string | null;
    renderSelectedTooltipLabel: (item: T) => string | null;
    isSelected: (item: T) => boolean;
};

const DataLineChart = <T extends Item, TX extends StringKeyOf<T>, TY extends StringKeyOf<T>>({
    width,
    height,
    items,
    xAxisDataKey,
    yAxisDataKey,
    renderXAxisLabel,
    renderLineTooltipLabel,
    renderSelectedTooltipLabel,
    isSelected,
}: DataLineChartProps<T, TX, TY>) => {
    const { palette } = useTheme();

    return (
        <LineChart width={width} height={height} data={items} style={{ userSelect: 'none' }}>
            <Line
                type="linear"
                dataKey={yAxisDataKey}
                stroke={palette.primary[300] as string}
                strokeWidth={2}
                dot={(props: DotProps<T>) => (
                    <DataLineChartDot {...props} dataKey={yAxisDataKey} isSelected={isSelected} />
                )}
                activeDot={(props: DotProps<T>) => (
                    <DataLineChartActiveDot {...props} dataKey={yAxisDataKey} isSelected={isSelected} />
                )}
            />
            <Tooltip
                content={(props: TooltipProps<T>) => (
                    <DataLineChartTooltip {...props} renderLabel={renderLineTooltipLabel} isSelected={isSelected} />
                )}
                cursor={false}
                isAnimationActive={false}
            />
            <XAxis
                dataKey={xAxisDataKey}
                interval={0} // Show all ticks
                padding={{ left: 24, right: 24 }}
                axisLine={false}
                tickLine={false}
                tick={(props: TickProps<T, typeof xAxisDataKey>) => (
                    <DataLineChartXAxisTick
                        {...props}
                        chartHeight={height}
                        items={items}
                        dataKey={xAxisDataKey}
                        isSelected={isSelected}
                        renderLabel={renderXAxisLabel}
                        renderTooltipLabel={renderSelectedTooltipLabel}
                    />
                )}
            />
            <YAxis
                padding={{
                    top: TOOLTIP_HEIGHT_PX + 4, // Leave room for the filled dot
                    bottom: 24,
                }}
                domain={['dataMin', 'dataMax']}
                hide
            />
        </LineChart>
    );
};

export default DataLineChart;
