import { addMinutes, format, isWithinInterval, parse, set, sub } from 'date-fns';
import { capitalize } from 'lodash';

import { ShiftOption, getShiftAtDateTimeUtc } from '@allie/utils/src/shifts';

import { EARLY_IN_SHIFT_MINUTES } from '~/constants/home';
import { NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL } from '~/constants/localStorage';
import { getDateInUtc } from '~/lib/date';

export const getInitials = (firstName = ' ', lastName = ' ') =>
    `${capitalize(firstName.trim().charAt(0))}${capitalize(lastName.trim().charAt(0))}`;

export const getFormattedDateTimeMinusOneMinute = (date: string) => {
    const formatString = 'yyyy-MM-dd HH:mm:ss';

    const parsedDate = parse(date, formatString, new Date());

    const updatedDate = format(sub(parsedDate, { minutes: 1 }), formatString);

    return updatedDate;
};

export const getCurrentTimeAndShiftStartTime = (timezone: string, shiftOptions: ShiftOption[]) => {
    const currentTime = new Date();
    const {
        shift: { startTimeInclusive },
    } = getShiftAtDateTimeUtc(getDateInUtc(currentTime), timezone, shiftOptions);

    const [hours] = startTimeInclusive.split(':');

    const shiftStartTime = set(currentTime, {
        hours: Number(hours),
        minutes: 0,
        seconds: 0,
    });
    return { currentTime, shiftStartTime };
};

type SignInConfimationPayload = {
    expire: number;
};

export const checkIfEarlyInShift = (timezone: string, shiftOptions: ShiftOption[]) => {
    const noEarlyInShiftConfirmationUntil = JSON.parse(
        localStorage.getItem(NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL) ?? '{}'
    ) as SignInConfimationPayload | null;

    if (noEarlyInShiftConfirmationUntil && noEarlyInShiftConfirmationUntil.expire > new Date().getTime()) {
        return false;
    }

    const { currentTime, shiftStartTime: shiftIntervalStart } = getCurrentTimeAndShiftStartTime(timezone, shiftOptions);

    const shiftIntervalEnd = addMinutes(shiftIntervalStart, EARLY_IN_SHIFT_MINUTES);
    return isWithinInterval(currentTime, {
        start: shiftIntervalStart,
        end: shiftIntervalEnd,
    });
};

export const setEarlyInShiftConfirmationExpireTime = (time: number) => {
    const data = JSON.stringify({ expire: time });
    localStorage.setItem(NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL, data);
};

export const getEarlyInShiftConfirmationExpireTime = () => {
    const data = localStorage.getItem(NO_EARLY_IN_SHIFT_CONFIRMATION_UNTIL);
    return data ? (JSON.parse(data) as SignInConfimationPayload).expire : null;
};
