import { styled } from '@mui/material';
import React from 'react';

import { DotProps, Item } from '~/pages/OperationsV2/utils/recharts';
import { ignoreProps } from '~/pages/OperationsV2/utils/styled';

import { SelectableProps } from '../shared';

const Dot = styled(
    'circle',
    ignoreProps<SelectableProps>('isSelected')
)<SelectableProps>(({ theme: { palette }, isSelected }) => ({
    r: isSelected ? 6 : 4,
    fill: isSelected ? (palette.primary[500] as string) : (palette.primary[100] as string),
    stroke: isSelected ? (palette.primary[500] as string) : (palette.primary[300] as string),
    strokeWidth: '2px',
}));

type DataLineChartDotProps<T extends Item, TX extends keyof T> = {
    dataKey: TX;
    isSelected: (item: T) => boolean;
};

const DataLineChartDot = <T extends Item, TX extends keyof T>({
    cx,
    cy,
    payload: item,
    dataKey,
    isSelected: isSelectedFn,
}: DotProps<T> & DataLineChartDotProps<T, TX>) => {
    if (!item[dataKey]) return null;

    const isSelected = isSelectedFn(item);

    return <Dot cx={cx} cy={cy} isSelected={isSelected} />;
};

export default DataLineChartDot;
