import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useErrorHandledQuery, useToken } from '~/api/common';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import {
    DailyTaskCreateResponse,
    DailyTasksCreateParams,
    DailyTasksReadParams,
    DailyTasksResponse,
    UpdateDailyTasksParams,
} from '~/types/dailyTasks';
import { BaseResponse } from '~/types/shared';

function getTaskCreationMessage(created: boolean, response?: { isPrnExpected?: boolean }): string {
    if (created) {
        return 'The task has been Created successfully!';
    }
    if (response?.isPrnExpected) {
        return "This PRN task was not created because it is already part of the resident's service plan or schedule. Thanks for reporting it!";
    }
    return 'This PRN was not created! Please try again later.';
}

const getToastType = (created: boolean, isPrnExpected?: boolean) => {
    if (created) {
        return 'success';
    }
    if (isPrnExpected) {
        return 'info';
    }
    return 'warning';
};

export const useDailyTasks = (params: DailyTasksReadParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                branch_id: params.branchId,
                date: params.date,
            },
        };

        const { data } = await api.get<BaseResponse<DailyTasksResponse>>('/tasks', options);

        return data.response;
    }, [params, token]);

    return useErrorHandledQuery({
        queryKey: ['dailyTasks', params],
        queryFn,

        enabled: !!params.branchId && !!params.date,
        staleTime: 300000,
    });
};

export const useCreateDailyTask = () => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async (params: DailyTasksCreateParams) => {
            const options = {
                headers: { authorization: token },
                params: { branch_id: branchId },
            };

            const body = {
                resident_id: params.residentId,
                company_care_plan_id: params.companyCarePlanId,
                date: params.date,
                shift_id: params.shiftId,
            };

            const { data, status } = await api.post<BaseResponse<DailyTaskCreateResponse>>('/tasks', body, options);

            return { status, response: data.response };
        },
        [token, branchId]
    );

    const onSuccess = useCallback(
        async (data: { status: number; response: DailyTaskCreateResponse }, variables: DailyTasksCreateParams) => {
            const { status, response } = data;
            const created = status === 201;

            showToast({
                message: getTaskCreationMessage(created, response),
                type: getToastType(created, response?.isPrnExpected),
            });

            await queryClient.invalidateQueries({ queryKey: ['user-fraud-status'] });
            await queryClient.invalidateQueries({ queryKey: ['dailyTasks'] });
            await queryClient.invalidateQueries({ queryKey: ['residentDailyTasks', variables.residentId] });
            await queryClient.invalidateQueries({ queryKey: ['rewards'] });
        },
        []
    );

    return useErrorHandledMutation({ mutationFn, onSuccess });
};

export const useUpdateDailyTasks = () => {
    const token = useToken();

    const mutationFn = useCallback(
        async ({ tasks, branchId: selectedBranchId }: UpdateDailyTasksParams) => {
            const { status } = await api.put(`/tasks`, tasks, {
                headers: { authorization: token },
                params: { branch_id: selectedBranchId },
            });

            return { status };
        },
        [token]
    );

    const onSuccess = useCallback(async (data: { status: number }) => {
        const { status } = data;
        const success = status === 200;

        showToast({
            message: success ? 'The task has been Updated successfully!' : 'Error updating task.',
            type: success ? 'success' : 'error',
        });
        await Promise.all([
            queryClient.invalidateQueries({ queryKey: ['documentation-actions'] }),
            queryClient.invalidateQueries({ queryKey: ['dailyTasks'] }),
            // TODO: specify the residentId
            queryClient.invalidateQueries({ queryKey: ['residentDailyTasks'] }),
        ]);
    }, []);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
