import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import { CaregiverPerformanceResponse } from '~/types/caregiverPerformanceDashboard';
import { BaseResponse } from '~/types/shared';

type DashboardReadParams = {
    branchId: number;
    startPeriod?: string;
    endPeriod?: string;
};

export const useCaregiverPerformanceDetails = (params: DashboardReadParams) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const options = {
            headers: { authorization: token },
            params: {
                branch_id: params.branchId,
                start_period: params.startPeriod,
                end_period: params.endPeriod,
            },
        };

        const { data } = await api.get<BaseResponse<CaregiverPerformanceResponse>>(
            `/dashboard/performance-details`,
            options
        );

        return data.response;
    }, [token, params]);

    return useErrorHandledQuery({
        queryKey: ['caregiver-performance-details', params],
        queryFn,
        enabled: !!(params?.startPeriod || params?.endPeriod),
        staleTime: 300000,
    });
};
