import { Box, Button, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useSound from 'use-sound';

import { useResidentShiftNotesCreateMutation } from '~/api/queries/shiftNotes/shiftNotes';
import notificationSound from '~/assets/notification-sound.mp3';
import { GoldCoin } from '~/components/Svg/GoldCoin';
import { pxToRem } from '~/components/theme/typography';
import { useAppDispatch } from '~/constants/redux';
import useUnscheduledTaskDialog from '~/pages/Home/components/NewUnscheduledTaskDialog/hooks/useUnscheduledTaskDialog';
import useSelectResidentStep from '~/pages/Home/components/NewUnscheduledTaskDialog/steps/resident/hooks/useSelectResidentStep';
import UnscheduledTaskDialog from '~/pages/Home/components/UnscheduledTaskDialog';
import ShiftNoteDialog from '~/pages/Residents/Details/components/ShiftNoteDialog';
import { toggleResidentParty } from '~/redux/actions/residents';
import { ReduxStore } from '~/types/redux';

import useNewPrnFlow from '../../components/NewUnscheduledTaskDialog/hooks/useNewPrnFlow';

type Props = {
    residentId: number;
    shift: number;
};

// just define the onsubmits for each item

export const AddedInfoActions = (props: Props) => {
    const { residentId, shift } = props;

    const {
        isEnabled: isNewPrnFlowEnabled,
        setIsUnscheduledTaskDialogOpen: setShowNewUnscheduledTaskDialog,
        setResidentData,
    } = useNewPrnFlow();
    const [isUnscheduledTaskDialogOpen, setIsUnscheduledTaskDialogOpen] = useState<boolean>(false);
    const { residentsList } = useSelectResidentStep();
    const { setShiftId } = useUnscheduledTaskDialog();
    const toggleIsUnscheduledTaskDialogOpen = () => setIsUnscheduledTaskDialogOpen((currentValue) => !currentValue);

    const [isShiftNoteDialogOpen, setIsShiftNoteDialogOpen] = useState<boolean>(false);
    const toggleIsShiftNoteDialogOpen = () => setIsShiftNoteDialogOpen((currentValue) => !currentValue);

    const [playCoinSound] = useSound(notificationSound);

    const isMobile = useMediaQuery('(max-width:1024px)');

    const dispatch = useAppDispatch();
    const {
        sessionData: { userId: caregiverId },
        timezone,
    } = useSelector((state: ReduxStore) => state.session);

    const { mutateAsync: residentShiftNoteCreate } = useResidentShiftNotesCreateMutation(caregiverId);

    if (!timezone) {
        throw new Error('no timezone specified');
    }

    const handleUnscheduledTaskSubmit = () => {
        dispatch(toggleResidentParty());
        playCoinSound();
    };

    const handleShiftNoteSubmit = async (report: string, newShiftNoteCategory: number) => {
        await residentShiftNoteCreate({
            residentId,
            userId: caregiverId,
            shiftNoteCategoryId: newShiftNoteCategory,
            report,
        });

        dispatch(toggleResidentParty());
        playCoinSound();
    };

    const handleAddUnscheduledTask = () => {
        if (isNewPrnFlowEnabled) {
            const resident = residentsList.find((r) => r.residentId === residentId) ?? null;
            setResidentData(resident);
            setShiftId(shift);
            setShowNewUnscheduledTaskDialog(true);
            return;
        }

        toggleIsUnscheduledTaskDialogOpen();
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    gap: isMobile ? pxToRem(0) : pxToRem(8),
                }}
            >
                <Button
                    sx={{
                        marginBottom: pxToRem(8),
                        fontSize: pxToRem(14),
                    }}
                    onClick={handleAddUnscheduledTask}
                    fullWidth
                >
                    <Box
                        sx={{
                            marginRight: pxToRem(8),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    Add Unscheduled/PRN Task
                </Button>
                <Button
                    sx={{
                        marginBottom: pxToRem(8),
                        fontSize: pxToRem(14),
                    }}
                    onClick={toggleIsShiftNoteDialogOpen}
                    fullWidth
                >
                    <Box
                        sx={{
                            marginRight: pxToRem(8),
                            display: 'flex',
                            alignItems: 'center',
                            color: '#FCD7B1',
                            width: pxToRem(25),
                        }}
                    >
                        <GoldCoin size="100%" viewBox="0 0 20 20" />
                    </Box>
                    Add Resident Note
                </Button>
            </Box>
            <UnscheduledTaskDialog
                isOpen={isUnscheduledTaskDialogOpen}
                defaultResidentId={residentId}
                timezone={timezone}
                currentShift={shift}
                onSubmit={handleUnscheduledTaskSubmit}
                onClose={toggleIsUnscheduledTaskDialogOpen}
                canChangeResident={false}
            />
            <ShiftNoteDialog
                isOpen={isShiftNoteDialogOpen}
                onClose={toggleIsShiftNoteDialogOpen}
                onSubmit={handleShiftNoteSubmit}
            />
        </>
    );
};
