import CallByDetail from './CallByDetail';
import CallByResident from './CallByResident';
import CallByResponseTime from './CallByResponseTime';

export default {
    modules: {
        CallByResponseTime,
        CallByDetail,
        CallByResident,
    },
};
