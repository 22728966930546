import All from './All';
import Anomaly from './Anomaly';
import CareIncrease from './CareIncrease';
import HeavyHitter from './HeavyHitter';

export default {
    HeavyHitter,
    Anomaly,
    CareIncrease,
    All,
};
