import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSound from 'use-sound';

import { useBranchId } from '~/api/common';
import { useResidentDailyTasksQuery, useResidentQuery } from '~/api/queries/residents';
import notificationSound from '~/assets/notification-sound.mp3';
import yaySound from '~/assets/yay-sound.mp3';
import CustomConfetti from '~/components/Custom/CustomConfetti';
import WithHeader from '~/components/Layout/WithHeader';
import Loading from '~/components/Shared/Loading';
import PageStructure from '~/pages/PageStructure';
import ResidentChipsContainer from '~/pages/Residents/Details/ChipsContainer';
import ResidentPersonalInfoContainer from '~/pages/Residents/Details/PersonalInfoContainer';
import ShiftNotesContainer from '~/pages/Residents/Details/ShiftNotesContainer';
import ResidentTasksContainer from '~/pages/Residents/Details/TasksContainer';
import ResidentTopContainer from '~/pages/Residents/Details/TopContainer';

import { RecentEvents } from './RecentEvents';

const ResidentDetails = () => {
    const { residentId: strResidentId } = useParams() || {};
    const residentId = Number(strResidentId!);
    const [party, setParty] = useState<boolean>(false);
    const [, { stop }] = useSound(yaySound);
    const [playCoinSound] = useSound(notificationSound);
    const branchId = useBranchId();

    const { refetch: residentDailyTasksRefetch } = useResidentDailyTasksQuery(Number(residentId));

    const {
        data: residentData,
        isLoading: residentDataIsLoading,
        isError: residentDataIsError,
    } = useResidentQuery(residentId, branchId);

    const triggerResidentActions = () => {
        void residentDailyTasksRefetch();
    };

    useEffect(() => {
        if (residentId) {
            triggerResidentActions();
        }
    }, [residentId]);

    useEffect(() => stop(), []);

    if (residentDataIsLoading) {
        return <Loading />;
    }

    if (residentDataIsError || !residentData) {
        return null;
    }

    const residentName = `${residentData?.firstName} ${residentData?.lastName}`;

    return (
        <WithHeader
            header={
                <Box p="16px">
                    <ResidentTopContainer residentData={residentData} />
                    <ResidentChipsContainer
                        residentData={residentData}
                        displayPartyOnSubmit={() => {
                            playCoinSound();
                            setParty(true);
                        }}
                    />
                </Box>
            }
        >
            <PageStructure pt="0 !important">
                <ShiftNotesContainer residentId={residentId} branchId={residentData.branchId} />
                <ResidentPersonalInfoContainer personalInfo={residentData?.personalInfo} />
                <RecentEvents events={residentData?.recentEvents} />
                <ResidentTasksContainer
                    residentId={Number(residentId)}
                    residentName={residentName}
                    branchId={residentData.branchId}
                />
                <CustomConfetti activate={party} stop={() => setParty(false)} />
            </PageStructure>
        </WithHeader>
    );
};

export default ResidentDetails;
