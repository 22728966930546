import { Box, Typography, styled } from '@mui/material';
import React from 'react';

type Props = {
    icon: React.ReactNode;
    label: string;
    onClick: () => void;
    selected?: boolean;
    disabled?: boolean;
};

const Container = styled(Box)<{ selected?: boolean; disabled?: boolean }>(({ theme, selected, disabled }) => ({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${selected ? theme.palette.primary[500] : disabled ? theme.palette.grey[50] : theme.palette.grey[100]}`,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    gap: '8px',
    borderRadius: '8px',
    aspectRatio: 1,
    width: '100%',
    minWidth: 0,
    boxSizing: 'border-box',
    cursor: disabled ? 'default' : 'pointer',
    backgroundColor: selected ? theme.palette.primary[50] : disabled ? theme.palette.grey[50] : 'white',
}));

const Label = styled(Typography)<{ selected?: boolean; disabled?: boolean }>(({ theme, selected, disabled }) => ({
    wordBreak: 'break-word',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 600,
    color: selected ? theme.palette.primary[500] : disabled ? theme.palette.grey[300] : theme.palette.grey[900],
}));

export default function SquareIconButton({ icon, label, selected, onClick, disabled }: Readonly<Props>) {
    return (
        <Container onClick={!disabled ? onClick : undefined} selected={selected} disabled={disabled}>
            <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
                {icon}
            </Box>
            <Label noWrap={false} sx={{ flexShrink: 1, overflow: 'hidden' }} selected={selected} disabled={disabled}>
                {label}
            </Label>
        </Container>
    );
}
