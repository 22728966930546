import { useCallback } from 'react';

import { api } from '~/api';
import { useErrorHandledQuery, useToken } from '~/api/common';
import { BaseResponse } from '~/types/shared';

export const useResidentDashboardDetailsQuery = (id: number | null) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<ResidentDetailsResponse>>(`/dashboard/resident-details-v2/${id}`, {
            headers: { authorization: token },
        });

        return data.response;
    }, [id, token]);

    return useErrorHandledQuery({
        queryKey: ['resident-dashboard-details', id],
        queryFn,
        enabled: !!id,
        staleTime: 300000,
    });
};
