import { useCallback } from 'react';

import { api } from '~/api';
import { queryClient, useBranchId, useErrorHandledMutation, useToken } from '~/api/common';

export const useCompleteCall = () => {
    const token = useToken();
    const branchId = useBranchId();

    const mutationFn = useCallback(
        async (callId: number) => {
            const options = { headers: { Authorization: token } };

            await api.patch(`/e-call/${branchId}/call/${callId}/complete`, null, options);
        },
        [token, branchId]
    );

    const onSuccess = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['ongoing-calls', branchId] });
        await queryClient.invalidateQueries({ queryKey: ['undocumented-calls', branchId] });
    }, [branchId]);

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
