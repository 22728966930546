import { VisibilityOff as HidePasswordIcon, Visibility as ShowPasswordIcon } from '@mui/icons-material';
import { Box, IconButton, Stack } from '@mui/material';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { useLoginUsernameMutation } from '~/api/queries/auth/login';
import AuthButton from '~/components/Auth/AuthButton';
import AuthCtaLink from '~/components/Auth/AuthCtaLink';
import AuthInput from '~/components/Auth/AuthInput';
import AuthTitle from '~/components/Auth/AuthTitle';
import BackButton from '~/components/Auth/BackButton';
import FormContainer from '~/components/Auth/FormContainer';
import MainContainer from '~/components/Auth/MainContainer';
import { pxToRem } from '~/components/theme/typography';
import { signIn } from '~/lib/auth';
import PageStructure from '~/pages/PageStructure';
import { SessionData } from '~/types/session';

export type LoginFormValues = {
    username: string;
    password: string;
    lastFourPhoneNumber: string;
};

const defaultValues = {
    username: '',
    password: '',
    lastFourPhoneNumber: '',
};

const UsernameLogin = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [signInErrorCount, setSignInErrorCount] = useState(0);
    const [currentUsername, setCurrentUsername] = useState('');
    const { trackEvent, showNewMessage } = useIntercom();
    const navigate = useNavigate();
    const posthog = usePostHog();

    const { mutate: loginUsernameMutation, isPending } = useLoginUsernameMutation({
        onSuccess: () => {
            setSignInErrorCount(0);
        },
        onError: () => {
            setSignInErrorCount((prevCount) => prevCount + 1);
            handleSignInError();
        },
    });

    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
    } = useForm<LoginFormValues>({
        defaultValues,
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        delayError: 2000,
    });

    const togglePasswordView = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleSignInError = () => {
        if (signInErrorCount === 2) {
            trackEvent('failed-login', {
                guessed_username: currentUsername,
            });
        }
    };

    const handlePasswordReset = () => {
        trackEvent('reset-password', {
            guessed_username: currentUsername,
        });
        showNewMessage('Hello - I forgot my password');
    };

    const onSubmit = (data: LoginFormValues) => {
        const username = data.username.replace(/\s+/g, '').toLowerCase();
        setCurrentUsername(username);

        loginUsernameMutation(
            {
                username,
                password: data.password,
                phoneNumber: data.lastFourPhoneNumber,
            },
            {
                onSuccess: (sessionData: SessionData) => {
                    signIn(sessionData, posthog);

                    const redirect = new URLSearchParams(window.location.search).get('redirect');
                    if (redirect) navigate(redirect, { replace: true });
                },
            }
        );
    };

    return (
        <PageStructure>
            <MainContainer>
                <FormContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            sx={{
                                pt: 'env(safe-area-inset-top)',
                            }}
                        >
                            <BackButton returnToUrl={'/login'} />
                            <Box
                                sx={{
                                    mt: {
                                        xs: pxToRem(20),
                                        lg: pxToRem(12),
                                    },
                                    mb: {
                                        xs: pxToRem(36),
                                    },
                                }}
                            >
                                <AuthTitle
                                    title="Sign In with Username"
                                    subtitle="Sign in now to access your account"
                                />
                            </Box>
                            <Stack spacing={2} sx={{ mb: pxToRem(44) }}>
                                <AuthInput
                                    name="username"
                                    label="Username"
                                    control={control}
                                    placeholder="Enter Username"
                                    type="text"
                                    error={errors.username}
                                    rules={{
                                        required: 'Username is required',
                                        minLength: {
                                            value: 4,
                                            message: 'Username must be at least 4 characters',
                                        },
                                    }}
                                />
                                <AuthInput
                                    name="password"
                                    label="Password"
                                    control={control}
                                    placeholder="Enter Password"
                                    type={showPassword ? 'text' : 'password'}
                                    endIcon={
                                        <IconButton disableRipple onClick={togglePasswordView} tabIndex={-1}>
                                            {showPassword ? (
                                                <ShowPasswordIcon fontSize="small" />
                                            ) : (
                                                <HidePasswordIcon fontSize="small" />
                                            )}
                                        </IconButton>
                                    }
                                    error={errors.password}
                                    rules={{
                                        required: 'Password is required',
                                        minLength: {
                                            value: 4,
                                            message: 'Password must be at least 4 characters',
                                        },
                                    }}
                                />
                                <AuthInput
                                    name="lastFourPhoneNumber"
                                    label="Phone Number Last 4 Digits"
                                    control={control}
                                    placeholder="Enter last 4 digits of phone number"
                                    type="tel"
                                    error={errors.lastFourPhoneNumber}
                                    rules={{
                                        required: 'Phone number last 4 digits is required',
                                        pattern: {
                                            value: /^\d{4}$/,
                                            message: 'Enter exactly 4 digits',
                                        },
                                    }}
                                />
                                {signInErrorCount > 0 && (
                                    <AuthCtaLink
                                        question="Forgot your password?"
                                        linkLabel="Click here."
                                        onClick={handlePasswordReset}
                                    />
                                )}
                            </Stack>
                        </Box>
                        <AuthButton label="Sign in" isDisabled={!isValid} isLoading={isPending} isSubmit />
                    </form>
                </FormContainer>
            </MainContainer>
        </PageStructure>
    );
};

export default UsernameLogin;
