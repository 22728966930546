import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Box, CircularProgress, IconButton, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VoiceRecorder } from 'capacitor-voice-recorder';
import { isEmpty } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import React, { useEffect, useState } from 'react';
import { isChrome } from 'react-device-detect';
import { PiMicrophoneFill } from 'react-icons/pi';
import { connect } from 'react-redux';

import { useAudioTranscribeMutation } from '~/api/queries/audioTranscribe/useAudioTranscribe';
import audioWave from '~/assets/audio-wave2.gif';
import { CustomTextField } from '~/components/Custom';
import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { pxToRem } from '~/components/theme/typography';
import { readUser } from '~/redux/actions/users';
import { AppDispatch, ReduxStore } from '~/types/redux';
import { UserResponse } from '~/types/users';

import ActivateMicConfirmation from './ActivateMicConfirmation';

const useStyles = makeStyles((theme: Theme) => ({
    micIcon: {
        color: theme.palette.primary.main,
    },
}));

const RECORDING_LANGUAGE_MAP = {
    english: 'English',
    other: 'Other Language',
};

type MicButtonProps = {
    onClick?: () => void;
    analyticsId?: string;
    isRecording?: boolean;
    isLoading?: boolean;
    children: React.ReactNode;
};

const MicButton = ({ onClick, analyticsId, isRecording = false, isLoading = false, children }: MicButtonProps) => {
    return (
        <IconButton
            sx={{
                color: isRecording ? 'white' : '#6F6F79',
                borderRadius: '100px',
                display: 'flex',
                justifyContent: 'space-between',
                gap: '4px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'grey.100',
                padding: '8px 12px',
                bgcolor: isRecording ? 'primary.500' : 'white',
                '&:hover': {
                    bgcolor: isRecording ? 'primary.500' : isLoading ? 'white' : 'grey.100',
                },
            }}
            onClick={onClick}
            data-analytics-id={analyticsId}
        >
            {children}
        </IconButton>
    );
};

type TranscriptionSectionProps = {
    recordingLanguage: 'english' | 'other' | null;
    audioTranscriptionLoading: boolean;
    analyticsIdText: string;
    startRecording: (language: 'english' | 'other') => () => void;
    stopRecording: (props: StopRecordingProps) => void;
};

const TranscriptionSection = ({
    recordingLanguage,
    audioTranscriptionLoading,
    analyticsIdText,
    startRecording,
    stopRecording,
}: TranscriptionSectionProps) => {
    const classes = useStyles();

    const posthog = usePostHog();
    const isTranslationEnabled = posthog.isFeatureEnabled('transcription-translate-audio');

    return (
        <Stack direction="row" sx={{ justifyContent: 'flex-end', gap: '8px' }}>
            {!recordingLanguage && audioTranscriptionLoading && (
                <MicButton isLoading={true}>
                    <CircularProgress color="inherit" size={20} />
                    <Typography>Transcribing</Typography>
                </MicButton>
            )}
            {recordingLanguage && (
                <MicButton
                    onClick={() => stopRecording({ discard: false, language: recordingLanguage })}
                    analyticsId={`${analyticsIdText}-stop-recording`}
                    isRecording={true}
                >
                    <img src={audioWave} alt="transcribing" style={{ height: '20px', marginRight: '5px' }} />
                    <Typography>Recording {RECORDING_LANGUAGE_MAP[recordingLanguage]}</Typography>
                </MicButton>
            )}
            {!recordingLanguage && !audioTranscriptionLoading && (
                <>
                    <MicButton onClick={startRecording('english')} analyticsId={`${analyticsIdText}-start-recording`}>
                        <PiMicrophoneFill className={classes.micIcon} />
                        <Typography>{RECORDING_LANGUAGE_MAP['english']}</Typography>
                    </MicButton>
                    {isTranslationEnabled && (
                        <MicButton onClick={startRecording('other')} analyticsId={`${analyticsIdText}-start-recording`}>
                            <PiMicrophoneFill className={classes.micIcon} />
                            <Typography>{RECORDING_LANGUAGE_MAP['other']}</Typography>
                        </MicButton>
                    )}
                </>
            )}
        </Stack>
    );
};

interface StopRecordingProps {
    discard?: boolean;
    language?: 'english' | 'other';
}

type Props = {
    onboardingId?: string;
    label?: string;
    placeholder: string;
    showActivateMicConfirmation: boolean;
    text: string;
    analyticsIdText: string;
    user: UserResponse;
    onChange: (text: string) => void;
    toggleShowActivateMicConfirmation: (show: boolean) => void;
    dispatchReadUser: () => void;
    isRequired?: boolean;
    hideDescriptionMessage?: boolean;
};

const TranscriptionTextField = (props: Props) => {
    const {
        onboardingId,
        label,
        placeholder,
        showActivateMicConfirmation,
        text,
        analyticsIdText,
        user,
        onChange,
        toggleShowActivateMicConfirmation,
        dispatchReadUser,
        isRequired,
        hideDescriptionMessage,
    } = props;

    const displayTranscriptionButton = Capacitor.isNativePlatform() || isChrome;

    const [recordingLanguage, setRecordingLanguage] = useState<'english' | 'other' | null>(null);
    const [deviceLanguage, setDeviceLanguage] = useState<string>('en');

    const { mutateAsync: audioTranscribeMutation, isPending: audioTranscriptionLoading } = useAudioTranscribeMutation();

    useEffect(() => {
        // If there is no data for the current user, then fetch it.
        if (isEmpty(user)) {
            // Fetch the information from the user.
            dispatchReadUser();
        }

        void Device.getLanguageCode().then(({ value }) => {
            setDeviceLanguage(value);
        });
    }, []);

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        //
        return () => {
            void stopRecording({ discard: true });
        };
    }, []);

    useEffect(() => {
        if (recordingLanguage) {
            // Discard recording after 2 minutes to avoid accidental long recordings
            const timer = setTimeout(() => {
                void stopRecording({ discard: true });
            }, 120000);

            return () => clearTimeout(timer);
        }

        return undefined;
    }, [recordingLanguage]);

    const checkPermission = (language: 'english' | 'other') => async () => {
        const { value: hasPermission } = await VoiceRecorder.hasAudioRecordingPermission();

        setRecordingLanguage(language);

        if (hasPermission) {
            await startRecording();
        } else {
            toggleShowActivateMicConfirmation(true);
        }
    };

    const requestPermission = async () => {
        const { value: grantedPermission } = await VoiceRecorder.requestAudioRecordingPermission();

        if (grantedPermission) {
            await startRecording();
        } else {
            showToast({
                message: 'You need to allow microphone access to record your message.',
                type: 'error',
            });
        }
    };

    const startRecording = async () => {
        await VoiceRecorder.startRecording();
    };

    const stopRecording = async ({ language, discard = false }: StopRecordingProps) => {
        const { status } = await VoiceRecorder.getCurrentStatus();
        if (status === 'NONE') {
            return;
        }

        const { recordDataBase64, mimeType } = (await VoiceRecorder.stopRecording()).value;

        if (discard) return;

        // https://stackoverflow.com/a/36183085
        const blob = await fetch(`data:${mimeType};base64,${recordDataBase64}`).then((res) => res.blob());

        const form = new FormData();
        form.append('file', blob);
        form.append('language', deviceLanguage);
        // have to pass string since it's a blob
        form.append('shouldTranslate', language && language === 'other' ? 'true' : 'false');

        // reset recording language right before sending request so there is no UI stutter
        setRecordingLanguage(null);
        const data = await audioTranscribeMutation(form);

        const newText = text?.length > 0 ? `${text} ${data}` : data;

        onChange(newText);
    };

    const handleAllowMicClick = async () => {
        toggleShowActivateMicConfirmation(false);
        await requestPermission();
    };

    if (showActivateMicConfirmation) {
        return <ActivateMicConfirmation showConfirmation={showActivateMicConfirmation} onAllow={handleAllowMicClick} />;
    }

    // 12px is the padding for the text field on the left and right. We need to calculate the width of the text field based on it
    const textFieldSidePadding = 12;

    const textFieldInvalid = isRequired && !text;

    return (
        <Stack spacing="16px" flexGrow={1}>
            <Box sx={{ position: 'relative', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CustomTextField
                    sx={{ flex: 1 }}
                    InputProps={{
                        sx: {
                            flexGrow: 1,
                            alignItems: 'start',
                            overflow: 'hidden',
                            padding: '10px 12px !important',
                            flexDirection: 'column',
                            boxSizing: 'border-box',
                        },
                    }}
                    inputProps={{
                        style: {
                            height: 'unset',
                            flexGrow: 1,
                            marginBottom: '46px', // height of the transcription section
                            boxSizing: 'border-box',
                        },
                    }}
                    id={onboardingId}
                    label={label || ''}
                    placeholder={placeholder}
                    value={text}
                    fullWidth
                    multiline
                    rows={1}
                    onChange={onChange}
                    customErrorMessage={textFieldInvalid ? 'You need to provide a comment' : ''}
                />
                {displayTranscriptionButton && (
                    <Box
                        sx={{
                            position: 'absolute',
                            width: `calc(100% - ${textFieldSidePadding * 2}px)`,
                            left: `${textFieldSidePadding}px`,
                            // The validation error message is at the bottom of the text field
                            bottom: textFieldInvalid ? '32px' : '8px',
                            backgroundColor: 'white',
                        }}
                    >
                        <TranscriptionSection
                            recordingLanguage={recordingLanguage}
                            audioTranscriptionLoading={audioTranscriptionLoading}
                            analyticsIdText={analyticsIdText}
                            startRecording={checkPermission}
                            stopRecording={stopRecording}
                        />
                    </Box>
                )}
            </Box>
            {displayTranscriptionButton && !hideDescriptionMessage && (
                <Box
                    sx={{
                        bgcolor: (theme) => theme.palette.grey[50],
                        px: pxToRem(10),
                        py: pxToRem(6),
                        borderLeft: (theme) => `2px solid ${theme.palette.grey[100]}`,
                    }}
                >
                    <Typography
                        align="left"
                        sx={{
                            fontSize: `${pxToRem(11.5)} !important`,
                            lineHeight: 1.3,
                        }}
                    >
                        You can document your note by speaking to the phone!{' '}
                        <span style={{ fontWeight: 'bold' }}>Click the green microphone button to start.</span> Make
                        sure to <span style={{ fontWeight: 'bold' }}>double check</span> the text is correct before
                        submitting.
                    </Typography>
                </Box>
            )}
        </Stack>
    );
};

const mapStateToProps = ({ users }: ReduxStore) => {
    const { user } = users;
    return {
        user,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchReadUser: () => dispatch(readUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TranscriptionTextField);
