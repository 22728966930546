import { Box, Stack } from '@mui/material';
import React, { ComponentProps, useEffect, useState } from 'react';

import { useWindowSize } from '~/hooks/useWindowSize';

type Direction = 'top' | 'right' | 'bottom' | 'left';

const fadeDirectionByPlacement: Record<Direction, Direction> = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
};

const Fade = ({ placement, sx }: { placement: Direction } & ComponentProps<typeof Box>) => (
    <Box
        sx={{
            background: `linear-gradient(to ${fadeDirectionByPlacement[placement]}, rgba(255, 255, 255, 1.0), rgba(255, 255, 255, 0.0))`,
            position: 'absolute',
            pointerEvents: 'none',
            zIndex: 1,
            ...sx,
        }}
    />
);

const DataContainer = ({ children, sx, ...props }: ComponentProps<typeof Stack>) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
    const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);

    const [windowSize] = useWindowSize();

    const [horizontalScrollBarWidth, setHorizontalScrollBarWidth] = useState(0);
    const [verticalScrollBarWidth, setVerticalScrollBarWidth] = useState(0);

    // Won't use `scrollbarGutter: 'stable'` yet because it's too recent (dec/24) on macOS/iOS.
    // See https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter.

    useEffect(() => {
        if (!containerRef || !contentRef) return;

        setHorizontalScrollBarWidth(containerRef.clientHeight - contentRef.clientHeight);
        setVerticalScrollBarWidth(containerRef.clientWidth - contentRef.clientWidth);
    }, [containerRef, contentRef, windowSize]);

    return (
        <Box
            ref={setContainerRef}
            sx={{
                position: 'relative',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <Fade
                placement="left"
                sx={{
                    top: 0,
                    bottom: horizontalScrollBarWidth,
                    left: 0,
                    width: '24px',
                }}
            />
            <Fade
                placement="right"
                sx={{
                    top: 0,
                    bottom: horizontalScrollBarWidth,
                    right: verticalScrollBarWidth,
                    width: '24px',
                }}
            />
            <Fade
                placement="bottom"
                sx={{
                    bottom: horizontalScrollBarWidth,
                    left: 0,
                    right: verticalScrollBarWidth,
                    height: '24px',
                }}
            />
            <Box
                {...props}
                ref={setContentRef}
                sx={{
                    height: '100%',
                    padding: '0 24px 24px',
                    overflow: 'auto',
                    ...sx,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default DataContainer;
