import { useAtomValue } from 'jotai';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { DEVICE_LOCATION_TYPE } from '@allie/utils/src/constants/ecall/device.constants';

import { useGetChosenCareLocations } from '~/api/queries/assignments/getChoseCareLocations';
import { useGetOngoingCalls } from '~/api/queries/call/getOngoingCalls';
import { UndocumentedCalls, useGetUndocumentedCalls } from '~/api/queries/call/getUndocumentedCalls';
import { usePermissions } from '~/permissions/utils';

import { isOnCurrentShiftAtom } from './atom';
import { useSortCalls } from './useSortCalls';

export const useCalls = (params?: { shiftId: number; shiftDay: DateTime }) => {
    const isOnCurrentShift = useAtomValue(isOnCurrentShiftAtom);
    const hasPermission = usePermissions();
    const { sortCalls } = useSortCalls();

    const { data: careLocationsIds, isLoading: isGetCareLocationsLoading } = useGetChosenCareLocations(
        !!hasPermission('Community', 'ecall-caregiver')
    );
    const { data: calls } = useGetOngoingCalls({
        isOnCurrentShift,
        careLocationsIds,
        enabled: !!hasPermission('Community', 'ecall-caregiver'),
    });
    const { data: undocumentedCalls } = useGetUndocumentedCalls({
        enabled: !!hasPermission('Community', 'ecall-caregiver') && !isGetCareLocationsLoading,
        careLocationIds: careLocationsIds,
        shiftDay: params?.shiftDay,
        shiftId: params?.shiftId,
    });

    const undocumentedPublicLocationCalls = useMemo<Record<string, UndocumentedCalls>>(() => {
        const callsFromPublicLocations = undocumentedCalls?.filter(isPublicLocationCall);
        // to group calls from the same location and displays them under the same card
        // we need to group by device location ID.
        // Bringing `deviceLocationId` closer to the call object will make it easier to group
        return groupBy(callsFromPublicLocations, 'deviceLocation.id');
    }, [undocumentedCalls]);

    const undocumentedResidentsCalls = useMemo<Record<string, UndocumentedCalls>>(() => {
        const callsFromResidents = undocumentedCalls?.filter(isResidentCall);
        // doing the same thing as `undocumentedPublicLocationCalls` but grouping by resident
        // using the resident ID.
        return groupBy(callsFromResidents, 'resident.id');
    }, [undocumentedCalls]);

    return useMemo(
        () => ({
            calls: careLocationsIds?.length && isOnCurrentShift && calls ? sortCalls(calls) : [],
            undocumentedCalls: {
                calls: undocumentedCalls ?? [],
                byResidentIds: undocumentedResidentsCalls,
                byPublicLocationId: undocumentedPublicLocationCalls,
            },
        }),
        [
            calls,
            careLocationsIds,
            isOnCurrentShift,
            undocumentedCalls,
            undocumentedPublicLocationCalls,
            undocumentedResidentsCalls,
            sortCalls,
        ]
    );
};

const isPublicLocationCall = <T extends { deviceLocation?: { type: DEVICE_LOCATION_TYPE } }>(call: T): boolean => {
    return !!call.deviceLocation && call.deviceLocation.type !== DEVICE_LOCATION_TYPE.PRIVATE_ROOM;
};

const isResidentCall = <T extends { resident?: { id: number } }>(call: T): boolean => {
    return !!call.resident?.id;
};
