import { Box, styled } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { ReactNode, useEffect, useRef } from 'react';

import { isDetailsOpenAtom } from '../atoms';

import { isFilterListExpandedAtom } from './Header/shared';
import Details from './Module/Details';
import Modules from './Module/Modules';

const BodyContainer = ({ children }: { children: ReactNode }) => {
    const setIsFilterListExpandedAtom = useSetAtom(isFilterListExpandedAtom);

    const contentRef = useRef<HTMLDivElement | null>(null);
    const prevContentScrollY = useRef(0);
    const prevContentClientHeight = useRef(0);

    useEffect(() => {
        if (!contentRef.current) return;

        prevContentScrollY.current = contentRef.current.scrollTop;
        prevContentClientHeight.current = contentRef.current.clientHeight;

        const handleContentScroll = () => {
            if (!contentRef.current) return;

            const currContentScrollY = contentRef.current.scrollTop;
            const currContentClientHeight = contentRef.current.clientHeight;

            if (
                // When expanding, the filter list might move the content around and change its height
                // (e.g. if at the bottom of the page), so we don't want accidental false positives
                currContentClientHeight === prevContentClientHeight.current &&
                currContentScrollY > prevContentScrollY.current
            ) {
                setIsFilterListExpandedAtom(false);
            }

            prevContentScrollY.current = currContentScrollY;
            prevContentClientHeight.current = currContentClientHeight;
        };

        contentRef.current.addEventListener('scroll', handleContentScroll);
        return () => contentRef.current?.removeEventListener('scroll', handleContentScroll);
    }, []);

    return (
        <Box
            ref={contentRef}
            sx={{
                position: 'relative',
                height: '100%',
                overflowY: 'hidden',
            }}
        >
            {children}
        </Box>
    );
};

const DetailsContainer = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflowY: 'hidden',
    backgroundColor: 'white',
    zIndex: 1,
});

const Body = () => {
    const isDetailsOpen = useAtomValue(isDetailsOpenAtom);

    return (
        <BodyContainer>
            {/* Keep modules rendered in the background for states and scroll not to be reset */}
            <Modules />
            {isDetailsOpen && (
                <DetailsContainer>
                    <Details />
                </DetailsContainer>
            )}
        </BodyContainer>
    );
};

export default Body;
