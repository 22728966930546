import React from 'react';
import { Outlet, createBrowserRouter, useMatches } from 'react-router-dom';

import DesktopHeader, { DesktopHeaderActionsRow } from '~/components/Layout/DesktopHeader';
import MobileHeader from '~/components/Layout/MobileHeader';
import MyRewardsAction from '~/components/Layout/MyRewardsAction';
import WithHeader from '~/components/Layout/WithHeader';
import WithNavigation from '~/components/Layout/WithNavigation';
import WithSafePadding from '~/components/Layout/WithSafePadding';
import ExportData from '~/pages/ExportData';
import CareDocumentation from '~/pages/Home';
import LiveCallsNotificationBell from '~/pages/Home/components/HeaderV2/components/filters/notifications/LiveCallsNotificationBell';
import Login from '~/pages/Login';
import CodeVerificationStep from '~/pages/Login/CodeVerificationStep';
import UsernameLoginPage from '~/pages/Login/UsernameLoginPage';
import { ManageSubscriptionPage as EmailSubscription } from '~/pages/ManageSubscription';
import MyRewards from '~/pages/MyRewards';
import MyRewardsHistory from '~/pages/MyRewards/HistoryPage';
import Operations from '~/pages/OperationsDashboard';
import OperationsV2 from '~/pages/OperationsV2';
import Performance from '~/pages/PerformanceDashboard';
import PrivacyPolicy from '~/pages/PrivacyPolicy';
import ResidentDeepDive from '~/pages/ResidentDashboard';
import Residents from '~/pages/Residents';
import Resident from '~/pages/Residents/Details';
import Signup from '~/pages/Signup';
import CommunityCodeVerificationStep from '~/pages/Signup/CommunityCodeVerificationStep';
import TermsOfService from '~/pages/TermsOfService';
import Profile from '~/pages/Users/Profile';
import DeleteAccount from '~/pages/Users/Profile/DeleteAccount';
import DeleteAccountVerifyStep from '~/pages/Users/Profile/DeleteAccount/DeleteAccountVerifyStep';
import App from '~/root/App';
import schedulingRouter from '~/scheduling/router';

import AccessControl from './components/AccessControl';
import NavigateError from './components/NavigateError';
import NavigateHome from './components/NavigateHome';

export type RouteHandle = {
    title?: string;
    backOne?: true;
    backTo?: string;
};

export const useRouteHandle = () => (useMatches().at(-1)?.handle ?? {}) as RouteHandle;

// See https://reactrouter.com/en/main/routers/create-browser-router
export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <NavigateError />, // Catch-all for any errors that occur in the app
        children: [
            // Root, decides what 'home' means and redirects to it, if possible
            {
                index: true,
                element: (
                    <AccessControl requiresAuthentication requiresAffiliation requiresGeofencing>
                        <NavigateHome />
                    </AccessControl>
                ),
            },

            // Open routes
            {
                path: 'terms-of-service',
                handle: {
                    title: 'Terms of Service',
                },
                element: <TermsOfService />,
            },
            {
                path: 'privacy-policy',
                handle: {
                    title: 'Privacy Policy',
                },
                element: <PrivacyPolicy />,
            },
            {
                path: 'profile/email-subscription',
                handle: {
                    title: 'Manage E-mail Subscription',
                },
                element: <EmailSubscription />,
            },

            // Unauthenticated routes
            {
                element: <AccessControl requiresAuthentication={false} />,
                children: [
                    {
                        path: 'login',
                        children: [
                            {
                                index: true,
                                handle: {
                                    title: 'Login',
                                },
                                element: <Login />,
                            },
                            {
                                path: 'verify',
                                handle: {
                                    title: 'Verify Phone Number',
                                },
                                element: <CodeVerificationStep />,
                            },
                            {
                                path: 'username',
                                handle: {
                                    title: 'Login',
                                },
                                element: <UsernameLoginPage />,
                            },
                        ],
                    },
                    {
                        path: 'signup',
                        children: [
                            {
                                index: true,
                                handle: {
                                    title: 'Sign Up',
                                },
                                element: <Signup />,
                            },
                            {
                                path: 'verify',
                                handle: {
                                    title: 'Verify Phone Number',
                                },
                                element: <CodeVerificationStep />,
                            },
                        ],
                    },
                ],
            },

            // Authenticated routes
            {
                element: <AccessControl requiresAuthentication={true} />,
                children: [
                    {
                        path: 'join-community',
                        handle: {
                            title: 'Join Community',
                        },
                        element: <CommunityCodeVerificationStep />,
                    },

                    // Routes that require the user to be part of a company/region/community
                    // and be geofenced, if applicable to that user/community
                    {
                        element: <AccessControl requiresAffiliation requiresGeofencing />,
                        children: [
                            // Routes that should have a side/bottom navigation bar
                            {
                                element: (
                                    <WithSafePadding>
                                        <WithNavigation>
                                            <WithHeader
                                                desktopHeader={
                                                    <DesktopHeader
                                                        actions={
                                                            <DesktopHeaderActionsRow>
                                                                <LiveCallsNotificationBell />
                                                                <MyRewardsAction />
                                                            </DesktopHeaderActionsRow>
                                                        }
                                                    />
                                                }
                                                mobileHeader={
                                                    <MobileHeader
                                                        actions={
                                                            <DesktopHeaderActionsRow>
                                                                <LiveCallsNotificationBell />
                                                                <MyRewardsAction />
                                                            </DesktopHeaderActionsRow>
                                                        }
                                                    />
                                                }
                                            >
                                                <Outlet />
                                            </WithHeader>
                                        </WithNavigation>
                                    </WithSafePadding>
                                ),
                                children: [
                                    {
                                        path: 'home',
                                        handle: {
                                            title: 'Home',
                                        },
                                        element: (
                                            <AccessControl permission={['Community', 'undertake-resident-action']}>
                                                <CareDocumentation />
                                            </AccessControl>
                                        ),
                                    },
                                    {
                                        path: 'care-documentation',
                                        handle: {
                                            title: 'Care Documentation',
                                        },
                                        element: (
                                            <AccessControl permission={['Community', 'read-all-resident-actions']}>
                                                <CareDocumentation />
                                            </AccessControl>
                                        ),
                                    },
                                    {
                                        path: 'residents',
                                        element: <AccessControl permission={['Community', 'read-resident']} />,
                                        children: [
                                            {
                                                index: true,
                                                handle: {
                                                    title: 'Residents',
                                                },
                                                element: <Residents />,
                                            },
                                            {
                                                path: ':residentId',
                                                children: [
                                                    {
                                                        index: true,
                                                        handle: {
                                                            title: 'Resident',
                                                            backOne: true,
                                                        },
                                                        element: <Resident />,
                                                    },
                                                    {
                                                        path: 'deep-dive',
                                                        handle: {
                                                            title: 'Resident Deep Dive',
                                                            backOne: true,
                                                        },
                                                        element: (
                                                            <AccessControl
                                                                permission={['Community', 'read-resident-deep-dive']}
                                                            >
                                                                <ResidentDeepDive />
                                                            </AccessControl>
                                                        ),
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        path: 'rewards',
                                        element: <AccessControl permission={['Community', 'read-reward']} />,
                                        children: [
                                            {
                                                index: true,
                                                handle: {
                                                    title: 'My Rewards',
                                                },
                                                element: <MyRewards />,
                                            },
                                            {
                                                path: 'history',
                                                handle: {
                                                    title: 'Points History',
                                                    backOne: true,
                                                },
                                                element: <MyRewardsHistory />,
                                            },
                                        ],
                                    },
                                    {
                                        path: 'operations',
                                        handle: {
                                            title: 'Operations & Risks',
                                        },
                                        element: (
                                            <AccessControl permission={['Community', 'read-operations-dashboard']}>
                                                <Operations />
                                            </AccessControl>
                                        ),
                                    },
                                    {
                                        path: 'performance',
                                        handle: {
                                            title: 'Performance',
                                        },
                                        element: (
                                            <AccessControl permission={['Community', 'read-performance-dashboard']}>
                                                <Performance />
                                            </AccessControl>
                                        ),
                                    },
                                    {
                                        path: 'profile',
                                        children: [
                                            {
                                                index: true,
                                                handle: {
                                                    title: 'Profile',
                                                },
                                                element: <Profile />,
                                            },
                                            {
                                                path: 'delete-account',
                                                children: [
                                                    {
                                                        index: true,
                                                        handle: {
                                                            title: 'Delete Account',
                                                            backOne: true,
                                                        },
                                                        element: <DeleteAccount />,
                                                    },
                                                    {
                                                        path: 'verify',
                                                        handle: {
                                                            title: 'Delete Account',
                                                            backTo: '/profile',
                                                        },
                                                        element: <DeleteAccountVerifyStep />,
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        path: 'export-data',
                                        handle: {
                                            title: 'Export Data',
                                        },
                                        element: (
                                            <AccessControl permission={['Community', 'read-all-resident-actions']}>
                                                <ExportData />
                                            </AccessControl>
                                        ),
                                    },
                                ],
                            },

                            // TODO: Move to proper location
                            {
                                path: 'operations/v2',
                                handle: {
                                    title: 'Operations & Risks',
                                },
                                element: (
                                    <AccessControl permission={['Community', 'read-operations-dashboard']}>
                                        <OperationsV2 />
                                    </AccessControl>
                                ),
                            },
                        ],
                    },

                    // Scheduling has its own, separate router
                    {
                        path: 'scheduling',
                        ...schedulingRouter,
                    },
                ],
            },
        ],
    },
]);
