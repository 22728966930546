import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { PostHog } from 'posthog-js';

import { showToast } from '~/components/Shared/Alerting/Toast/utils/showToast';
import { PROFILE } from '~/constants/localStorage';
import store from '~/redux/Store';
import actionTypes from '~/redux/actionTypes';
import { SessionData } from '~/types/session';

const setSentryUser = (id: string, username: string) => {
    Sentry.setUser({
        id,
        username,
    });
};

type Profile = {
    isSignedIn: boolean;
    sessionData: SessionData;
};

export const registerSession = (profile: Profile) => {
    // the session needs to be stored in local storage and in redux state.
    // Local storage persists the session across tabs - redux is for accessing the state across the app
    localStorage.setItem(PROFILE, JSON.stringify(profile));

    store.dispatch({
        type: actionTypes.SESSION_SIGN_IN,
        payload: profile,
    });
};

const getErrorMessage = (errorCode: number, errorShort: string) => {
    let errorMessage = 'You need to provide a Username and Password!';

    if (errorCode === 401 && errorShort === 'mismatch') {
        errorMessage = 'The credentials do not match! Please try again.';
    } else if (errorCode === 401 && errorShort === 'inactive') {
        errorMessage = 'Sorry, but your account has been deactivated. Please contact your manager.';
    } else if (errorCode === 404) {
        errorMessage = 'The Account you provided does not exist. Please check and try again.';
    }
    return errorMessage;
};

const handleErrors = (error: AxiosError<{ code: number; short: string }>) => {
    if (!error?.response?.data) return;

    const errorMessage = getErrorMessage(error.response.data.code, error.response.data.short);

    showToast({
        message: errorMessage,
        type: 'error',
    });
};

export const signIn = (data: SessionData, posthog: PostHog) => {
    try {
        const { userId, username } = data;

        setSentryUser(userId.toString(), username);

        posthog.identify(userId.toString(), { username });
        posthog.setPersonPropertiesForFlags({ username });

        const profile: Profile = {
            isSignedIn: true,
            sessionData: data,
        };

        registerSession(profile);

        const redirect = new URLSearchParams(window.location.search).get('redirect');
        // decode the encoded path (modules/app-frontend/src/router/components/AccessControl.tsx)
        if (redirect) window.location.replace(atob(redirect));
    } catch (err) {
        const error = err as AxiosError<{ code: number; short: string }>;
        handleErrors(error);
    }
};
