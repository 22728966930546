import { Box, Button, Typography, useTheme } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

interface UndocumentedCallBox {
    triggeredAt: DateTime;
    onClick: () => void;
}

export const UndocumentedCallBox = (props: UndocumentedCallBox) => {
    const { palette } = useTheme();

    return (
        <Box bgcolor={palette.grey[50]} border={`1px solid ${palette.grey[200]}`} padding="16px" borderRadius="8px">
            <Typography variant="body1" color={palette.grey[900]} fontWeight={700} lineHeight="24px" fontSize="16px">
                Fill in request documentation
            </Typography>
            <Typography variant="body1" color={palette.grey[900]} fontWeight={400} lineHeight="24px">
                Call time {props.triggeredAt.toLocaleString(DateTime.TIME_SIMPLE)}
            </Typography>
            <Box height="16px" />
            <Button fullWidth onClick={props.onClick}>
                Start
            </Button>
        </Box>
    );
};
