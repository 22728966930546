import * as Sentry from '@sentry/react';
import { useCallback } from 'react';

import { FRAUD_STATUS } from '@allie/utils/src/constants/users.constants';

import { api } from '~/api';
import { BaseResponse } from '~/types/shared';

import { useErrorHandledMutation, useErrorHandledQuery, useToken } from '../common';

type UserFraudStatusResponse = { status: keyof typeof FRAUD_STATUS | null };

export const useUserDeviceMetadataMutation = () => {
    const userToken = useToken();

    const mutationFn = useCallback(
        async ({ token, deviceId }: { token: string; deviceId: string }) => {
            await api.post(
                '/users/device-metadata',
                { pushNotificationToken: token, deviceId },
                {
                    headers: {
                        authorization: userToken,
                    },
                }
            );
        },
        [userToken]
    );

    const onError = useCallback((error: unknown) => {
        Sentry.captureException(error);
    }, []);

    return useErrorHandledMutation({ mutationFn, onError });
};

export const useUserFraudStatusQuery = (branchId?: number, shiftId?: number) => {
    const token = useToken();

    const queryFn = useCallback(async () => {
        const { data } = await api.get<BaseResponse<UserFraudStatusResponse>>(`/users/fraud-status`, {
            headers: { authorization: token },
            params: { branch_id: branchId, shift_id: shiftId },
        });

        return data.response;
    }, [branchId, shiftId, token]);

    return useErrorHandledQuery({
        queryKey: ['user-fraud-status', branchId, shiftId],
        queryFn,
        enabled: !!branchId && !!shiftId,
    });
};
