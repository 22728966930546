import { Box, styled } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import { ArrowDown, ArrowUp, ArrowsDownUp } from '@phosphor-icons/react';
import { useAtom } from 'jotai';
import { ComponentProps, ReactNode } from 'react';
import React from 'react';

import { SingleLineTypography } from '~/components/Shared/SingleLineTypography';
import { useCanHover } from '~/hooks/useCanHover';
import { detailsPayloadAtom } from '~/pages/OperationsV2/atoms';
import { StringKeyOf } from '~/pages/OperationsV2/utils/types';

export type Item = Record<string, string | number | boolean | null>;

type DataTableColumnDefinition<T extends Item, K extends StringKeyOf<T>> = {
    label: string;
    alignment?: 'left' | 'center' | 'right';
    weight?: number;
    allowSort?: boolean;
    renderHeaderStartIcon?: ReactNode | ((rows: T[]) => ReactNode);
    renderCell?: (value: T[K], { row, rowIndex }: { row: T; rowIndex: number }) => ReactNode;
};

const DataTableContainer = styled(Stack)({
    flexDirection: 'row',
    width: 'max-content',
    overflow: 'hidden',
    // Instead of `gap: '16px'` to prevent row separator clipping
    // TODO: Very brittle, find a better solution
    '& > div:not(:first-child) > div': { paddingLeft: '16px' },
});

const DataTableHeaderCell = <T extends Item, K extends StringKeyOf<T>>({
    columnKey,
    columnDefinition: { label, alignment = 'left', allowSort = true, renderHeaderStartIcon },
    rows,
}: {
    columnKey: K;
    columnDefinition: DataTableColumnDefinition<T, K>;
    rows: T[];
}) => {
    const canHover = useCanHover();

    const [detailsPayload, setDetailsPayload] = useAtom(detailsPayloadAtom);

    if (!detailsPayload) return null;

    const { sortBy } = detailsPayload;

    const isSorted = sortBy?.key === columnKey;
    const SortingIcon = isSorted ? (sortBy.direction === 'asc' ? ArrowUp : ArrowDown) : ArrowsDownUp;

    const onSort = () => {
        if (!allowSort) return;

        setDetailsPayload({
            ...detailsPayload,
            // Handle sort cycling: ascending -> descending -> none (undefined)
            sortBy: isSorted
                ? sortBy.direction === 'asc'
                    ? { key: columnKey, direction: 'desc' }
                    : undefined
                : { key: columnKey, direction: 'asc' },
        });
    };

    const labelLines = label.split('\n');

    return (
        <Stack
            onClick={onSort}
            sx={({ palette }) => ({
                color: isSorted ? palette.grey[900] : palette.grey[500],
                flexDirection: 'row',
                justifyContent: alignment,
                alignItems: 'center',
                gap: '8px',
                height: '32px', // TODO: Revert to 24px after figuring out how to reflect multiple lines header height on every column
                cursor: 'pointer',
                userSelect: 'none',
                '&:hover': canHover && {
                    color: palette.grey[700],
                    '& p': { textDecoration: 'underline' },
                },
                '&:active': allowSort && {
                    color: palette.grey[900],
                    '& p': { textDecoration: 'underline' },
                },
                '& > *': { transition: 'color 0.1s ease' },
            })}
        >
            {typeof renderHeaderStartIcon === 'function' ? renderHeaderStartIcon(rows) : renderHeaderStartIcon}
            <Stack>
                {labelLines.map((line, index) => (
                    <Typography key={index} variant="body2" color="inherit" fontWeight={700}>
                        {line}
                    </Typography>
                ))}
            </Stack>
            {allowSort && <SortingIcon size="20px" />}
        </Stack>
    );
};

const DataTableColumn = <T extends Item, K extends StringKeyOf<T>>({
    columnKey,
    columnDefinition,
    rows,
}: {
    columnKey: K;
    columnDefinition: DataTableColumnDefinition<T, K>;
    rows: T[];
}) => {
    const { alignment = 'left', weight = undefined, renderCell = (value) => value } = columnDefinition;

    return (
        <Stack flexGrow={weight} flexShrink={0}>
            <DataTableHeaderCell columnKey={columnKey} columnDefinition={columnDefinition} rows={rows} />

            {rows.map((row, rowIndex) => {
                const cell = renderCell(row[columnKey], { row, rowIndex });
                return (
                    <Box
                        key={rowIndex}
                        sx={({ palette }) => ({
                            display: 'flex',
                            justifyContent: alignment,
                            alignItems: 'center',
                            height: '64px',
                            borderBottom: '1px solid',
                            borderColor: palette.primary[300] as string,
                        })}
                    >
                        {typeof cell !== 'object' ? (
                            <SingleLineTypography sx={{ textAlign: alignment }}>{cell}</SingleLineTypography>
                        ) : (
                            cell
                        )}
                    </Box>
                );
            })}
        </Stack>
    );
};

type DataTableProps<T extends Item> = {
    columns: { [K in StringKeyOf<T>]?: DataTableColumnDefinition<T, K> };
    rows: T[];
    containerProps?: ComponentProps<typeof Stack>;
};

const DataTable = <T extends Item>({ columns, rows, containerProps }: DataTableProps<T>) => {
    const columnEntries = Object.entries(columns) as [StringKeyOf<T>, DataTableColumnDefinition<T, StringKeyOf<T>>][];

    return (
        <DataTableContainer {...containerProps}>
            {columnEntries.map(([key, column]) => (
                <DataTableColumn key={key} columnKey={key} columnDefinition={column} rows={rows} />
            ))}
        </DataTableContainer>
    );
};

export default DataTable;
