import { Theme, useMediaQuery } from '@mui/material';
import React from 'react';
import Confetti from 'react-confetti';

import { useWindowSize } from '~/hooks/useWindowSize';

interface CustomConfettiProps {
    activate: boolean;
    stop: () => void;
    width?: number; // px
    height?: number; // px
}

const CustomConfetti = ({ activate, stop, width, height }: CustomConfettiProps) => {
    const [windowWidth, windowHeight] = useWindowSize();
    const isMobile = useMediaQuery<Theme>(({ breakpoints }) => breakpoints.down('sm'));

    const realWidth = width || windowWidth || window.innerWidth;
    const realHeight = height || windowHeight || window.innerHeight;

    const amountOfPieces = isMobile ? 500 : 2000;

    return (
        <Confetti
            // TODO - verify if the subtraction is needed
            // In Desktop subtract 15 from width to prevent horizontal scroll bar
            width={isMobile ? realWidth : realWidth - 15}
            height={realHeight}
            numberOfPieces={activate ? amountOfPieces : 0}
            recycle={false}
            onConfettiComplete={(confetti) => {
                confetti?.reset();
                stop();
            }}
            tweenDuration={1500}
            gravity={0.1}
        />
    );
};

export default CustomConfetti;
