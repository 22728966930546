import React from 'react';

import { TASK_STATUS, TASK_STATUS_TO_TASK_STATUS_ID } from '@allie/utils/src/constants/task-records.constants';

import { TaskLoadingState } from '~/types/dailyTasks';
import { TaskCompletionOptions } from '~/types/residents';

import { TaskDetails } from '../TaskDetails';
import { TaskRowContainer } from '../TaskRowContainer';

import { TaskOptions } from './TaskOptions';

type Props = {
    taskName: string;
    taskTime: string;
    taskType: 'Scheduled' | 'Added';
    taskNotes: string;
    taskCompletionOptions?: TaskCompletionOptions;
    completionOptionValue?: number;
    loadingState?: TaskLoadingState;
    userTaskNotes?: string;
    caregiver?: {
        firstName: string;
        lastName: string;
    };
    completedDateTime?: string;
    taskStatusId: number;
    onToggleNotes: () => void;
    onToggleEditAddTaskNotes: () => void;
    onToggleRejectDialog: () => void;
    onCompletionOptionsChange: (number) => void;
};

export const ResidentRowTask = (props: Props) => {
    const {
        taskName,
        taskTime,
        taskType,
        taskNotes,
        taskCompletionOptions,
        completionOptionValue,
        loadingState,
        userTaskNotes,
        taskStatusId,
        caregiver,
        completedDateTime,
        onToggleNotes,
        onToggleEditAddTaskNotes,
        onToggleRejectDialog,
        onCompletionOptionsChange,
    } = props;

    const isPrnTask = taskType === 'Added';
    const isPrnCompletedTask = taskStatusId === TASK_STATUS_TO_TASK_STATUS_ID[TASK_STATUS.PRN_COMPLETED];

    const isDisabledTask = isPrnTask || isPrnCompletedTask;

    return (
        <TaskRowContainer isPrn={isPrnTask}>
            <TaskDetails
                taskName={isPrnTask ? `[PRN] ${taskName}` : taskName}
                taskTime={taskTime}
                taskNotes={taskNotes}
                userTaskNotes={userTaskNotes}
                taskCompletionOptions={taskCompletionOptions}
                caregiver={caregiver}
                completedDateTime={completedDateTime}
                onToggleNotes={onToggleNotes}
                onToggleEditAddTaskNotes={isDisabledTask ? undefined : onToggleEditAddTaskNotes}
                onCompletionOptionsChange={onCompletionOptionsChange}
                completionOptionValue={completionOptionValue}
            />
            <TaskOptions
                taskStatusId={taskStatusId}
                onToggleRejectDialog={onToggleRejectDialog}
                loadingState={loadingState}
                isDisabledTask={isDisabledTask}
            />
        </TaskRowContainer>
    );
};
