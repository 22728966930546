import { ComponentOverride } from '.';
import { alpha, buttonClasses } from '@mui/material';

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        grey: true;
    }
}

export const MuiButton: ComponentOverride<'MuiButton'> = {
    defaultProps: {
        variant: 'contained',
        disableElevation: true,
    },
    styleOverrides: {
        root: {
            flexShrink: 0,
            gap: '4px',

            fontWeight: 700,
            letterSpacing: 0,
            textTransform: 'none',

            // TODO: Uncomment when browser support is improved: https://caniuse.com/?search=text-box
            // textBoxTrim: 'trim-both',
            // textBoxEdge: 'cap alphabetic',

            '& svg *': {
                stroke: 'currentColor',
            },
        },

        // 'Selected' on Figma
        containedPrimary: ({ theme: { palette } }) => ({
            color: 'white',
            backgroundColor: palette.primary[500],

            [`&.${buttonClasses.disabled}`]: {
                color: palette.grey[500],
                backgroundColor: alpha(palette.grey[900], 0.2),

                fontWeight: 500,
            },
        }),

        // 'Deselected' on Figma
        containedSecondary: ({ theme: { palette } }) => ({
            color: palette.grey[900],
            backgroundColor: palette.secondary[100],

            fontWeight: 700,

            '&:hover': {
                backgroundColor: alpha(palette.grey[900], 0.1),
            },

            [`&.${buttonClasses.disabled}`]: {
                color: palette.grey[500],
                backgroundColor: alpha(palette.grey[900], 0.1),

                fontWeight: 500,
            },
        }),

        // 'Selected' on Figma
        outlinedPrimary: ({ theme: { palette } }) => ({
            color: palette.primary[500],
            borderColor: palette.primary[500],
            borderWidth: 2,

            fontWeight: 700,

            '&:hover': {
                backgroundColor: alpha(palette.primary[500] as string, 0.1),
                borderWidth: 2,
            },

            [`&.${buttonClasses.disabled}`]: {
                color: palette.grey[900],
                borderColor: palette.grey[100],
                borderWidth: 2,

                fontWeight: 500,
            },
        }),

        // 'Deselected' on Figma
        outlinedSecondary: ({ theme: { palette } }) => ({
            color: palette.grey[900],
            borderColor: palette.grey[900],
            borderWidth: 2,

            fontWeight: 700,

            '&:hover': {
                backgroundColor: alpha(palette.grey[900], 0.1),
                borderColor: palette.grey[900],
                borderWidth: 2,
            },

            [`&.${buttonClasses.disabled}`]: {
                color: alpha(palette.grey[900], 0.2),
                borderColor: alpha(palette.grey[900], 0.2),
                borderWidth: 2,

                fontWeight: 500,
            },
        }),

        // 'Small' on Figma
        sizeMedium: {
            minWidth: '40px',
            height: '40px',
            padding: '0 8px',
            borderRadius: 20,

            fontSize: 14,
            lineHeight: '16px',

            '& svg': {
                fontSize: '20px !important',
            },
        },

        // 'Large' on Figma
        sizeLarge: {
            minWidth: '48px',
            height: '48px',
            padding: '0 12px',
            borderRadius: 24,

            fontSize: 16,
            lineHeight: '24px',

            '& svg': {
                fontSize: '24px !important',
            },
        },

        startIcon: { margin: '0 !important' },
        endIcon: { margin: '0 !important' },
    },
    variants: [
        {
            props: { variant: 'contained', color: 'grey' },
            style: ({ theme: { palette } }) => ({
                color: 'white',
                backgroundColor: palette.grey[900],

                fontWeight: 700,

                '&:hover': {
                    backgroundColor: alpha(palette.grey[900], 0.8),
                },

                [`&.${buttonClasses.disabled}`]: {
                    color: palette.grey[500],
                    backgroundColor: alpha(palette.grey[900], 0.1),

                    fontWeight: 500,
                },
            }),
        },
        {
            props: { variant: 'outlined', color: 'grey' },
            style: ({ theme: { palette } }) => ({
                color: palette.grey[900],
                borderColor: palette.grey[900],
                borderWidth: 2,

                fontWeight: 700,

                '&:hover': {
                    backgroundColor: alpha(palette.grey[900], 0.1),
                    borderColor: palette.grey[900],
                    borderWidth: 2,
                },

                [`&.${buttonClasses.disabled}`]: {
                    color: alpha(palette.grey[900], 0.2),
                    borderColor: alpha(palette.grey[900], 0.2),
                    borderWidth: 2,

                    fontWeight: 500,
                },
            }),
        },
    ],
};
