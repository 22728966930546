import { ComponentOverride } from '.';

export const MuiMenu: ComponentOverride<'MuiMenu'> = {
    styleOverrides: {
        paper: {
            // borderRadius: '0 0 16px 16px',
            borderRadius: 0,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        list: {
            padding: 0,
        },
    },
};
