import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from '~/api';
import { useErrorHandledMutation } from '~/api/common';
import { SessionData } from '~/types/session';
import { BaseResponse } from '~/types/shared';

type Params = {
    firstName: string;
    lastName: string;
    username: string;
    phoneNumber: string;
    isPinPhoneNumber: boolean;
    email?: string;
};

type SignUpMutationResult = {
    sessionData: SessionData;
    phoneNumber: string;
    isPinPhoneNumber: boolean;
};

export const useSignupMutation = () => {
    const navigate = useNavigate();

    const mutationFn = useCallback(async (rawParams: Params): Promise<SignUpMutationResult> => {
        const { isPinPhoneNumber, ...params } = rawParams;

        const { data } = await api.post<BaseResponse<SessionData>>(`/users`, params);

        return { phoneNumber: params.phoneNumber, isPinPhoneNumber, sessionData: data.response };
    }, []);

    const onSuccess = useCallback(
        ({ phoneNumber, isPinPhoneNumber }: SignUpMutationResult) => {
            if (!isPinPhoneNumber) {
                const encodedPhoneNumber = encodeURIComponent(phoneNumber);
                navigate(`/signup/verify?phoneNumber=${encodedPhoneNumber}&optional=true`, { replace: true });
            }
        },
        [navigate]
    );

    return useErrorHandledMutation({ mutationFn, onSuccess });
};
