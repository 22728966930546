import { Box, styled } from '@mui/material';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';

import { SectionDataType } from '@allie/operations-common/src/types/module';

import { selectedDateAtom, selectedPeriodAtom } from '~/pages/OperationsV2/atoms';
import DataContainer from '~/pages/OperationsV2/components/Module/Section/DataContainer';
import DataLegend from '~/pages/OperationsV2/components/Module/Section/DataLegend';
import DataBarChart from '~/pages/OperationsV2/components/Module/Section/recharts/DataBarChart';
import { formatDateXAxisLabel } from '~/pages/OperationsV2/components/Module/Section/recharts/shared';
import { formatPercentage } from '~/pages/OperationsV2/utils/format';

type Data = SectionDataType<'BRANCH', 'CALL_BY_RESIDENT', 'TOTAL_CALLS'>;
type Item = Data['items'][number];

const ChartContainer = styled(Box)(({ theme: { palette } }) => ({
    backgroundColor: palette.primary[100] as string,
    width: '100%',
    padding: '24px 0 12px',
    borderRadius: '16px',
}));

const Data = ({ data }: { data: Data }) => {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

    const selectedPeriod = useAtomValue(selectedPeriodAtom);
    const selectedDate = useAtomValue(selectedDateAtom);

    const labelByDataKey = useMemo(
        () =>
            ({
                top10Percentage: 'top 10',
                otherPercentage: 'other',
            }) satisfies { [key in keyof Item]?: string },
        []
    );

    const renderTooltipLabel = useCallback(
        (item: Item, dataKey: keyof Item) =>
            // e.g. '20% top 10 residents' when hovering
            item[dataKey] ? `${formatPercentage(+item[dataKey])} from ${labelByDataKey[dataKey]} residents` : null,
        []
    );

    const isSelected = useCallback(
        (item: Item) => DateTime.fromISO(item.date).hasSame(selectedDate, 'day'),
        [selectedDate]
    );

    const renderXAxisLabel = useCallback(
        (item: Item) => {
            const date = DateTime.fromISO(item.date) as DateTime<true>;
            return formatDateXAxisLabel(selectedPeriod, date, isSelected(item));
        },
        [isSelected]
    );

    return (
        <DataContainer>
            <ChartContainer ref={setContainerRef}>
                <DataBarChart
                    width={containerRef?.clientWidth ?? 0}
                    height={200}
                    items={data.items}
                    xAxisDataKey="date"
                    yAxisDataKeys={['top10Percentage', 'otherPercentage']}
                    renderXAxisLabel={renderXAxisLabel}
                    renderTooltipLabel={renderTooltipLabel}
                    isSelected={isSelected}
                />
            </ChartContainer>
            <DataLegend
                rows={[
                    { label: 'Top 10 Residents', value: formatPercentage(data.top10Percentage) },
                    { label: 'Other Residents', value: formatPercentage(data.otherPercentage) },
                ]}
                containerProps={{ width: '100%' }}
            />
        </DataContainer>
    );
};

export default Data;
