import { DateTime } from 'luxon';
import React from 'react';

import { DetailsGroupingDataType } from '@allie/operations-common/src/types/module';

import DataContainer from '~/pages/OperationsV2/components/Module/Details/DataContainer';
import DataTable from '~/pages/OperationsV2/components/Module/Details/DataTable';
import Notes from '~/pages/OperationsV2/components/Module/Details/Notes';
import AddNotesButton from '~/pages/OperationsV2/components/Module/Details/Notes/AddNotesButton';
import { formatMsToMinutes } from '~/pages/OperationsV2/utils/format';

type Item = DetailsGroupingDataType<'BRANCH', 'CALL_BY_DETAIL', 'ALL', 'EMERGENCY'>[number];

const Data = ({ items }: { items: Item[] }) => (
    <DataContainer>
        <DataTable
            columns={{
                residentName: {
                    label: 'Resident',
                },
                staffName: {
                    label: 'Care Staff',
                },
                timestamp: {
                    label: 'Timestamp',
                    renderCell: (value) => (value ? DateTime.fromISO(value).toFormat('h:mm a, MMM d (EEE)') : null), // e.g. 1:23 PM, Jan 1 (Fri)
                },
                type: {
                    label: 'Type',
                },
                responseTimeMs: {
                    label: 'Response Time',
                    renderCell: (value) => (value ? formatMsToMinutes(value) : null),
                },
                staffNotes: {
                    label: 'Staff Notes',
                    allowSort: false,
                    renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : '—'),
                },
                managerNotes: {
                    label: 'Manager Notes',
                    allowSort: false,
                    renderCell: (value) => (value ? <Notes notes={value} maxWidth="200px" /> : <AddNotesButton />),
                },
            }}
            rows={items}
        />
    </DataContainer>
);

export default Data;
