import { styled } from '@mui/material';
import React from 'react';

import { fontStyle } from '~/pages/OperationsV2/theme/typography';
import { Item, TickProps } from '~/pages/OperationsV2/utils/recharts';
import { ignoreProps } from '~/pages/OperationsV2/utils/styled';

import { SelectableProps } from '../shared';

export const TOOLTIP_HEIGHT_PX = 28;

const TooltipObject = styled('foreignObject')({
    width: '100%',
    height: TOOLTIP_HEIGHT_PX,
    transform: 'translateX(-50%)',
    textAlign: 'center',
});

const TooltipContainer = styled('div')(({ theme: { palette } }) => ({
    color: palette.primary[500] as string,
    background: 'white',
    display: 'inline-block',
    height: `${TOOLTIP_HEIGHT_PX}px`,
    padding: '2px 8px',
    borderRadius: `${TOOLTIP_HEIGHT_PX / 2}px`,
    borderColor: palette.primary[500] as string,
    // Border is set at call site to avoid clipping
}));

const TooltipText = styled('span')({
    ...fontStyle,
    fontSize: '14px',
    fontWeight: 700,
});

const IndicatorLine = styled('line')(({ theme: { palette } }) => ({
    stroke: palette.primary[500] as string,
    strokeWidth: '2px',
}));

const TextContainer = styled(
    'text',
    ignoreProps<SelectableProps>('isSelected')
)<SelectableProps>(({ theme: { palette }, isSelected }) => ({
    ...fontStyle,
    fill: isSelected ? (palette.primary[500] as string) : palette.grey[500],
    fontSize: '12px',
    fontWeight: isSelected ? 700 : 500,
    textAnchor: 'middle',
}));

type DataLineChartXAxisTickProps<T extends Item, TX extends keyof T> = {
    chartHeight: number;
    items: T[];
    dataKey: TX;
    renderLabel: (item: T) => string;
    renderTooltipLabel: (item: T) => string | null;
    isSelected: (item: T) => boolean;
};

const DataLineChartXAxisTick = <T extends Item, TX extends keyof T>({
    x,
    payload: { value },
    chartHeight,
    items,
    dataKey,
    isSelected: isSelectedFn,
    renderLabel,
    renderTooltipLabel,
}: TickProps<T, TX> & DataLineChartXAxisTickProps<T, TX>) => {
    // Recharts only provides the x-axis value here, not the entire item payload
    const item = items.find((item) => item[dataKey] === value);
    if (!item) return null;

    const isSelected = isSelectedFn(item);
    const [label, caption] = renderLabel(item).split('\n');

    return (
        <>
            {isSelected && (
                <>
                    <TooltipObject x={x} y={0}>
                        <TooltipContainer style={{ border: '2px solid' }}>
                            <TooltipText>{renderTooltipLabel(item)}</TooltipText>
                        </TooltipContainer>
                    </TooltipObject>
                    <IndicatorLine
                        x1={x}
                        x2={x}
                        y1={TOOLTIP_HEIGHT_PX} // Skip tooltip
                        y2={chartHeight - 40} // Subtract label/caption height
                    />
                </>
            )}
            <TextContainer isSelected={isSelected}>
                <tspan
                    x={x}
                    y={chartHeight - 14} // Subtract caption font size and padding/spacing
                >
                    {label}
                </tspan>
                {caption && (
                    <tspan x={x} y={chartHeight}>
                        {caption}
                    </tspan>
                )}
            </TextContainer>
        </>
    );
};

export default DataLineChartXAxisTick;
