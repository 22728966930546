import { atom } from 'jotai';

import { atomWithStorage } from '~/state/atomUtils';
import { OperationsPeriod, SelectOptionsId } from '~/types/operationsDashboard';

export const branchAtom = atom<SelectOptionsId>('all');
export const regionAtom = atom<SelectOptionsId>('all');
export const careTypeAtom = atomWithStorage<string>('operationsDashboard_CareType', 'all');
export const operationsPeriodAtom = atomWithStorage<OperationsPeriod>('operationsDashboard_OperationsPeriod', 'week');
export const mobileTabSelectedAtom = atom<0 | 1>(0);
